const { toast } = require("react-toastify");
const { formatErrorForDisplay } = require(".");
import { getProfile } from "../api/AccountApi";
import { setUser } from "../app/slices/userSlice";

async function refreshUserAccount(dispatch) {
  const response = await getProfile();
  if (response?.error) {
    toast.error(formatErrorForDisplay(response.error));
  } else {
    dispatch(setUser(response?.data?.user || false));
  }
  return response;
}

export default refreshUserAccount;
