const { REACT_APP_DOMAIN_ROOT, REACT_APP_INTERCOM_APP_ID } = process.env;

import React from "react";

import { createRoot } from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { MainRoutes } from "./routes"; // where we are going to specify our routes

import store from "./app/store";
import CustomBoundary from "./componentsTS/errorBoundaries/CustomBoundary";

let persistor = persistStore(store);

const queryClient = new QueryClient();

const isSnapping = navigator.userAgent === "ReactSnap";
const originMatches = window.location.origin === REACT_APP_DOMAIN_ROOT;
const url = window.location.href;
const path = window.location.pathname;
const indexFile = "index.html";

// If the origin doesn't match our expected origin, we want to redirect
// the user to the correct origin to ensure everything works correctly.
// Additionally, ReactSnap is used to snapshot the static pages to get
// rid of 404 errors when serving from Cloud Storage. Since it is ran
// locally on CI, we don't want to apply the location redirect on it,
// since it won't be running on the app domain root. That is okay,
// since it is only generating the static pages and doesn't need the
// correct domain root when doing this.
// More details on ReactSnap: https://www.npmjs.com/package/react-snap
if (!isSnapping && !originMatches) {
  // Redirect the user to the correct domain root if they landed on
  // our site with a non-preferred domain, being sure to preserve
  // the path and any query string in the process.
  const path = window.location.pathname + window.location.search;
  window.location.replace(REACT_APP_DOMAIN_ROOT + path);
} else if (path.endsWith(indexFile)) {
  // If you load a url from Cloud Storage, like goodcall.com/pricing/
  // Google will give a nice 200, and send back /pricing/index.html to
  // the user in that case. However, if you don't have the trailing
  // slash (e.g: goodcall.com/pricing), they'll emit a 301, and redirect
  // the user to goodcall.com/pricing/index.html. See the table examples:
  // https://cloud.google.com/storage/docs/static-website#three-object_bucket
  // This seems wrong, but sadly, it is the intended behavior from their
  // perspective... (see: https://issuetracker.google.com/issues/151713401)
  // So, to keep our URLs looking nice, this forces a redirect back
  // to the url without the `index.html`, but keeping the trailing
  // slash, so that Google will politely serve the correct file.
  // Kind of an annoying hack workaround, but not sure we have any
  // good alternatives at the moment.
  window.location.replace(url.replace(indexFile, ""));
}

/* To utilize the useBlocker hook, we need to adopt a router that supports the data API.
 * Reference: https://reactrouter.com/en/6.26.0/routers/picking-a-router
 * This approach is the best practice for implementing an unsaved changes blocker.
 *
 * Q: Why do we need this now, given that our previous implementation was functional?
 * A: Previously, we checked every navigation trigger and compared the current state with the new state.
 *    If there were unsaved changes, we displayed a confirmation dialog. This method is suboptimal because
 *    it cannot cover all navigation triggers, such as navigation via nav items or browser back/forward buttons.
 *    Therefore, adopting a router that supports the data API is essential for effectively using the useBlocker hook.
 *
 *
 */
const router = createBrowserRouter([
  {
    path: "*",
    element: <MainRoutes />,
  },
]);
// Convenience method to render the app.
const App = () => (
  <CustomBoundary tag="globalError">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <IntercomProvider shouldInitialize={!isSnapping} autoBoot={!isSnapping} appId={REACT_APP_INTERCOM_APP_ID}>
          <PersistGate loading={null} persistor={persistor}>
            <RouterProvider router={router} />
          </PersistGate>
        </IntercomProvider>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </CustomBoundary>
);

// Get the root element of the page.
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);
