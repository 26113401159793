import {
  DirectoryContact,
  DirectoryDepartment,
  DirectoryRecordType,
  isFullDirectoryContact,
  isFullDirectoryDepartment,
} from "../api/DirectoryApiNew";
import { Behavior, BehaviorType } from "../api/types";

const behaviorHasContactId = (behavior: Behavior, contactIdToCheck: string): boolean => {
  if (!behavior || !behavior.behaviorType) return false;
  if (behavior.behaviorType === BehaviorType.Connect) {
    return behavior.transferToContactId === contactIdToCheck;
  }
  if (behavior.behaviorType === BehaviorType.TakeMessage) {
    return behavior.contactIdsToNotify?.some((id) => id === contactIdToCheck) || false;
  }
  return false;
};

const contactIsConnectedToContact = (directory: DirectoryContact, directoryIdToCheck: string) => {
  if (isFullDirectoryContact(directory) && directory.id !== directoryIdToCheck) {
    return (
      behaviorHasContactId(directory.available, directoryIdToCheck) ||
      behaviorHasContactId(directory.unavailable, directoryIdToCheck)
    );
  }
  return false;
};

const departmentIsConnected = (directory: DirectoryDepartment, directoryIdToCheck: string) => {
  if (isFullDirectoryDepartment(directory) && directory.id !== directoryIdToCheck) {
    return (
      behaviorHasContactId(directory.available, directoryIdToCheck) ||
      behaviorHasContactId(directory.unavailable, directoryIdToCheck)
    );
  }
  return false;
};

const getAllDirectoriesConnectedToDirectories = (
  contactIdToCheck: string,
  directories: DirectoryRecordType[] | undefined
): string[] => {
  const connectedContacts = new Array<string>();
  if (directories) {
    directories.forEach((directory) => {
      if (directory.type === "department" && departmentIsConnected(directory, contactIdToCheck)) {
        connectedContacts.push(`${directory.name}`);
      } else {
        const contact = directory as DirectoryContact;
        if (contactIsConnectedToContact(contact, contactIdToCheck)) {
          connectedContacts.push(`${contact.firstName} ${contact.lastName} (${contact.title})`);
        }
      }
    });
  }
  return connectedContacts;
};

export default getAllDirectoriesConnectedToDirectories;
