import { createSlice } from "@reduxjs/toolkit";
export const wixUserSlice = createSlice({
  name: "wixUser",
  initialState: {
    activeUser: false,
    configuration: {},
  },
  reducers: {
    setWixUser: (state, { payload }) => {
      state.activeUser = payload;
    },
    setWixConfiguration: (state, { payload }) => {
      state.configuration = payload;
    },
  },
});

export const { setWixUser, setWixConfiguration } = wixUserSlice.actions;

export default wixUserSlice.reducer;
