import React, { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import App from "./App";
import MainLayout from "./components/Layout/MainLayout";

import "react-toastify/dist/ReactToastify.css";
import DelayedRender from "./components/DelayedRender";
import useDetectCampaign from "./hooks/useDetectCampaign";

const StyleViewer = lazy(() => import("./styleTest.tsx"));

const RedirectTo = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
};

export const MainRoutes = () => {
  useDetectCampaign();

  return (
    <Routes>
      <Route
        path="/style-testing"
        element={
          <Suspense fallback={<DelayedRender delay={1000}>Loading...</DelayedRender>}>
            <MainLayout>
              <StyleViewer />
            </MainLayout>
          </Suspense>
        }
      />

      <Route
        exact
        path="/getcalls"
        element={
          <RedirectTo url="https://help.goodcall.com/en/articles/8007514-publishing-the-goodcall-phone-number" />
        }
      />
      <Route
        exact
        path="/privacy"
        element={<RedirectTo url="https://help.goodcall.com/en/articles/8007565-privacy" />}
      />
      <Route
        exact
        path="/terms-of-service"
        element={<RedirectTo url="https://help.goodcall.com/en/articles/8007566-terms-of-service" />}
      />
      <Route
        path="/*"
        element={
          <MainLayout>
            <ToastContainer />
            <App />
          </MainLayout>
        }
      />
    </Routes>
  );
};
