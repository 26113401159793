// Helper that send event to Hotjar

type HotJar = (event: string, eventValue: string) => void;

declare global {
  interface Window {
    hj?: HotJar | unknown;
  }
}

const send = (event: "event" | "identify", value: string) => {
  try {
    if (window.hj && typeof window.hj === "function") {
      window.hj(event, value);
    }
  } catch (error: unknown) {
    // Do nothing.
  }
};

const sendEvent = (action: string) => send("event", action);
const identify = (id: string) => send("identify", id);

export default {
  sendEvent,
  identify,
};
