import _ from "lodash";

import { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { useSelector } from "react-redux";

import { LoggedInUser } from "./useLoggedInUser";

/**
 * Hook to update intercom with the current user.
 * Ensures that any logged in user is properly setup in intercom once they log in.
 * @param user The current logged in user.
 */
const useIntercomUpdate = (user: LoggedInUser): void => {
  // Keep track of the last user we sent to Intercom.
  // This will help us avoid sending duplicate updates.
  const [lastUser, setLastUser] = useState<LoggedInUser>(user);

  // Get whether or not a user is currently being impersonated.
  const isImpersonation = useSelector<{ admin: { isImpersonation: boolean } }, boolean>(
    (state) => state.admin.isImpersonation
  );

  // Get the Intercom instance.
  const intercom = useIntercom();

  // Update Intercom when the user changes.
  useEffect(() => {
    // If we're still loading the user, make no changes.
    if (user === "loading") {
      return;
    }

    // If we have an error, that means the user is not logged in.
    if (_.isError(user)) {
      // In this case, we will do nothing and just leave intercom alone.
      return;
    }

    // Finally, if the logged in user has changed, update the user metadata in intercom.
    if (!_.isEqual(user, lastUser)) {
      // Get the current intercom visitor id to store as metadata.
      const visitorId = intercom.getVisitorId();

      // Update the last user.
      setLastUser(user);

      // If the user is being impersonated, shut down intercom.
      // This allows admins to impersonate users without messing up intercom.
      if (isImpersonation) {
        intercom.shutdown();
        return;
      }

      // Update the user in intercom.
      intercom.update({
        // We prefix the user id with "gc-" to avoid collisions with other intercom ids.
        userId: `gc-user-${user.id}`,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        userHash: user.intercomUserHash,
        phone: user.phoneNumber,
        customAttributes: {
          "Visitor Id": visitorId,
          "Number Of Locations": user.planLocationsUsed,
          "Subscription Status": user.subscriptionStatus,
          "Trial Consumed": user.trialConsumed,
          "Plan Name": user.planName,
        },
      });
    }
  }, [user, intercom, lastUser, isImpersonation]);
};

export default useIntercomUpdate;
