import { capitalize } from "lodash";
import { Behavior, BehaviorType } from "../api/types";
import { Logic, LogicList, LogicType } from "../api/logicApi";

interface SkillPartial {
  behavior?: Behavior;
  available?: Behavior;
  unavailable?: Behavior;
  knowledgeType: string;
  enabled: boolean;
}

type Skills = Record<string, SkillPartial>;

const skillIsConnectedToBehavior = (skill: SkillPartial, behaviorToCheck: BehaviorType): boolean =>
  skill.enabled &&
  (skill.behavior?.behaviorType === behaviorToCheck ||
    skill.available?.behaviorType === behaviorToCheck ||
    skill.unavailable?.behaviorType === behaviorToCheck);

const skillIsConnectedToForm = (skill: SkillPartial, formId: string): boolean => {
  const isNormalSkill = skill.behavior?.behaviorType === BehaviorType.ContactForm && skill.behavior?.formId === formId;
  const available = skill.available?.behaviorType === BehaviorType.ContactForm && skill.available?.formId === formId;
  const unavailable =
    skill.unavailable?.behaviorType === BehaviorType.ContactForm && skill.unavailable?.formId === formId;
  return skill.enabled && (isNormalSkill || available || unavailable);
};

const skillIsConnectedToLogic = (skill: SkillPartial, logicId: string): boolean => {
  const isNormalSkill = skill.behavior?.behaviorType === BehaviorType.Logic && skill.behavior?.logicId === logicId;
  const available = skill.available?.behaviorType === BehaviorType.Logic && skill.available.logicId === logicId;
  const unavailable = skill.unavailable?.behaviorType === BehaviorType.Logic && skill.unavailable?.logicId === logicId;
  return skill.enabled && (isNormalSkill || available || unavailable);
};
/**
 * Checks if the given logic is connected to the specified form.
 *
 * @param logic - The logic object to check.
 * @param formId - The ID of the form to check against.
 * @returns True if the logic is connected to the form, false otherwise.
 */
const logicConnectedToForm = (logic: Logic, formId: string): boolean => {
  // Check if the logic type is CustomerLookup
  if (logic.type === LogicType.CustomerLookup) {
    const isFoundConnected = logic.found.behaviorType === BehaviorType.ContactForm && logic.found.formId === formId;
    const isNotFoundConnected =
      logic.notFound.behaviorType === BehaviorType.ContactForm && logic.notFound.formId === formId;
    return isFoundConnected || isNotFoundConnected;
  }

  // Check if the logic type is YesNo
  if (logic.type === LogicType.YesNo) {
    const isYesConnected =
      logic.yesBehavior.behaviorType === BehaviorType.ContactForm && logic.yesBehavior.formId === formId;
    const isNoConnected =
      logic.noBehavior.behaviorType === BehaviorType.ContactForm && logic.noBehavior.formId === formId;
    return isYesConnected || isNoConnected;
  }

  // Check if the logic type is MultipleChoice
  if (logic.type === LogicType.MultipleChoice) {
    return logic.choices.some(
      (choice) => choice.behavior.behaviorType === BehaviorType.ContactForm && choice.behavior.formId === formId
    );
  }

  // Return false if none of the conditions are met
  return false;
};

const getAllSkillsWithBehavior = (behaviorType: BehaviorType, skills: Skills = {}) => {
  const connectedSkills = new Array<string>();
  Object.keys(skills).forEach((key) => {
    if (skillIsConnectedToBehavior(skills[key], behaviorType)) {
      connectedSkills.push(capitalize(key.replace("-", " ")));
    }
  });
  return connectedSkills;
};

// Get number of skills connected to a form
export const getAllSkillsWithFormId = (formId: string, skills: Skills = {}, logics: LogicList = []) => {
  const connectedSkills = new Array<string>();
  Object.keys(skills).forEach((key) => {
    if (skillIsConnectedToForm(skills[key], formId)) {
      connectedSkills.push(capitalize(key.replace("-", " ")));
    }
  });

  logics.forEach(({ logic }) => {
    if (logicConnectedToForm(logic, formId)) {
      connectedSkills.push(capitalize(logic.name));
    }
  });
  return connectedSkills;
};

// Get number of skills connected to a logic
export const getAllSkillsWithLogicId = (logicId: string, skills: Skills = {}) => {
  const connectedSkills = new Array<string>();
  Object.keys(skills).forEach((key) => {
    if (skillIsConnectedToLogic(skills[key], logicId)) {
      connectedSkills.push(capitalize(key.replace("-", " ")));
    }
  });
  return connectedSkills;
};

export default getAllSkillsWithBehavior;
