import { combineReducers, configureStore } from "@reduxjs/toolkit";
import agentReducer from "./slices/agentSlice";
import businessReducer from "./slices/businessSlice";
import userReducer from "./slices/userSlice";
import wixUser from "./slices/wixUserSlice";
import adminReducer from "./slices/adminSlice";
import skillWizardReducer from "./slices/skillWizardSlice";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

// We'll use redux-logger just as an example of adding another middleware
import logger from "redux-logger";

const reducers = combineReducers({
  agent: agentReducer,
  business: businessReducer,
  user: userReducer,
  admin: adminReducer,
  wixUser: wixUser,
  skillWizard: skillWizardReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: process.env.NODE_ENV !== "production",
});
