import React from "react";
import "./listGroup.scss";
import { Padding } from "../types";

type Props = {
  children: React.ReactNode;
  padding?: Padding;
  useMaxHeight?: boolean;
};

const ListGroup: React.FC<Props> = ({ children, useMaxHeight, padding }: Props) => (
  <div
    className={`conversight list-group use-shadow list-group-padding-${padding || ""} ${
      useMaxHeight ? "max-height" : ""
    }`}
  >
    {children}
  </div>
);

ListGroup.defaultProps = {
  useMaxHeight: false,
  padding: "none" as Padding,
};

export default ListGroup;
