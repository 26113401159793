import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import "./ZapierAuth.scss";

import { StoreTypes } from "../../app/StoreTypes";
import { CharacterId } from "../../atoms/agents/types";
import Frame from "../../atoms/frame/Frame";
import Connect from "./Connect";
import SelectAgent from "./SelecteAgent";
import Flex from "../../atoms/flex/Flex";

const Flow: React.FC = () => {
  // Get the selected agent from the store using the useSelector hook.
  const selectedAgent = useSelector<StoreTypes>((state) => state.agent.selectedAgent) as CharacterId;

  return (
    <Flex justify="center" padding="medium" alignItems="stretch">
      <Frame backgroundColor="white" shadow="medium" radius="medium">
        <div className="zapier-auth-modal">
          <Routes>
            <Route index element={<Connect selectedAgent={selectedAgent} />} />
            <Route path="agent" element={<SelectAgent selectedAgent={selectedAgent} />} />
          </Routes>
          <Outlet />
        </div>
      </Frame>
    </Flex>
  );
};

export default Flow;
