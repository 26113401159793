module.exports = {
  formatErrorForDisplay: require("./formatErrorForDisplay").default,
  formatPhoneNumber: require("./formatPhoneNumber").default,
  formatDuration: require("./formatDuration"),
  setEndOfContentEditable: require("./setEndOfContentEditable"),
  handleKnowledgeState: require("./handleKnowledgeState").default,
  confirmDisabledKnowledge: require("./confirmDisabledKnowledge"),
  pasteAsPlainText: require("./pasteAsPlainText"),
  doesKnowledgeNeedsSetup: require("./doesKnowledgeNeedsSetup"),
  sendGAEvent: require("./sendGAEvent").default,
  sanitizeText: require("./sanitizeText"),
  refreshAvailableAgents: require("./refreshAvailableAgents").default,
  refreshUserAccount: require("./refreshUserAccount").default,
  focusOnEditableContent: require("./focusOnEditableContent"),
  formatConnectStatus: require("./formatConnectStatus"),
  emailFormatCheck: require("./emailFormatCheck"),
  getAllKnowledgeForMessageTaken: require("./getAllKnowledgeForMessageTaken"),
  getAllKnowledgeForConnect: require("./getAllKnowledgeForConnect"),
  getBehaviorWithDefaultLink: require("./getBehaviorWithDefaultLink"),
  refreshAgentOnHostNameChange: require("./refreshAgentOnHostNameChange").default,
  getAllDirectoriesConnectedToDirectories: require("./getAllDirectoriesConnectedToDirectories").default,
  getAllSkillsConnectedToContact: require("./getAllSkillsConnectedToContact").default,
  getAllContactsWithBehavior: require("./getAllContactsWithBehavior").default,
  getAllContactsWithConnectToBusinessBehavior: require("./getAllContactsWithConnectToBusinessBehavior").default,
  getAllSkillsWithBehavior: require("./getAllSkillsWithBehavior").default,
  isUUID: require("./isUUID").default,
  isEmail: require("./isEmail").default,
  isPhoneNumber: require("./isPhoneNumber").default,
};
