import { useContext } from "react";

import { ActiveUserEnhanced } from "./useLoggedInUser.js";
import { UserContext } from "../authentication/ProtectedRoute";

/**
 * Hook to let you use the current active user.
 *
 * **WARNING:** This should only ever be used in a component that is
 * a descendant of the <ProtectedRoute> component, which provides
 * the UserContext that guarantees a user is active before rendering.
 * @returns The current ActiveUser.
 */
const useUser = (): ActiveUserEnhanced => useContext(UserContext);

export default useUser;
