import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getProfile } from "../api/AccountApiNew";

import { ActiveUser } from "../app/StoreTypes";
import { formatErrorForDisplay } from "../helpers";
import { APIResponse } from "../api/APIUtilsNew.js";

// Enhanced version of the ActiveUser type that includes a refetch function.
export type ActiveUserEnhanced = ActiveUser & {
  refetch: () => Promise<
    QueryObserverResult<
      APIResponse<{
        user: ActiveUser;
      }>
    >
  >;
};

export type LoggedInUser = "loading" | ActiveUserEnhanced | Error;

/**
 * Hook to get the currently logged in user.
 * If the user is not logged in, it returns an error.
 * If the user is logged in, the ActiveUser object is returned.
 * If we are in the process of fetching to figure this out, "loading" is returned.
 * Provides an optional parameter to skip retries, if we want to fail fast.
 */
const useLoggedInUser = (options?: { retry?: boolean }): LoggedInUser => {
  // Whether or not we want to retry in the case of failure.
  const queryOptions = { retry: options?.retry };

  // Current logged in user, if we have one.
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser>("loading");

  // Use a query to get the current logged in user's profile.
  const {
    data: response,
    isLoading,
    error,
    refetch,
  } = useQuery(["currentUser"], () => getProfile(false), queryOptions);

  // Handle changes from the query.
  useEffect(() => {
    // If we're currently loading, make no changes.
    // The initial state will be loading, but subsequent loads
    // won't cause thrash here until the loading finishes by switching to a loading state.
    if (isLoading) {
      return;
    }

    // If there was an error, update our state with an error.
    if (error) {
      setLoggedInUser(new Error(formatErrorForDisplay(error)));
      return;
    }

    // If we received no response, update the state with an error.
    if (!response) {
      setLoggedInUser(new Error("No response when getting user profile"));
      return;
    }

    // If the response contained an error, update the state with an error.
    if (response.error) {
      setLoggedInUser(response.error);
      return;
    }

    // If the response had no data attached to it, update the state with an error.
    if (!response.data) {
      setLoggedInUser(new Error("No data on response when getting user profile"));
      return;
    }

    // Otherwise, update with our user object, attaching the refetch function.
    setLoggedInUser({
      ...response.data.user,
      refetch,
    });
  }, [isLoading, response, error, refetch]);

  // Return the logged in user.
  return loggedInUser;
};

export default useLoggedInUser;
