import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Agent } from "../app/StoreTypes";

// Assuming we have the following types for the Redux store and agent

interface RootState {
  agent: {
    availableAgents: Agent[];
  };
}

/**
 * `useValidateAgentId` hook assure that you have access to this agentId and return current agent data.
 * @param redirect Whether to redirect if the agentId is not found. Defaults to true.
 * @returns dashboardActiveAgent - agent data, agentId, and isDemoMode flag
 */
const useValidateAgentId = (redirect = true) => {
  // Get the agentId from the URL
  const { agentId } = useParams<{ agentId: string }>();
  // Get the available agents from the Redux store
  const navigate = useNavigate();
  const { availableAgents } = useSelector((state: RootState) => state.agent);
  const [dashboardActiveAgent, setDashboardActiveAgent] = useState<Agent | undefined>();
  // Check if the agentId is valid
  const isDemoMode = dashboardActiveAgent?.phoneNumber === false;

  // Get the agent's plan name
  const agentPlan = dashboardActiveAgent?.planName;

  useEffect(() => {
    const matchedAgent = availableAgents.find((x) => x.id === agentId);
    if (!matchedAgent && availableAgents.length > 0 && redirect) {
      navigate("/dashboard");
    } else if (matchedAgent) {
      setDashboardActiveAgent(matchedAgent);
    }
  }, [availableAgents, agentId, redirect, navigate]);

  return {
    dashboardActiveAgent,
    agentId,
    isDemoMode,
    agentPlan,
  };
};

export default useValidateAgentId;
