import React from "react";
import { Spinner } from "react-bootstrap";
import { Abc } from "@mui/icons-material";

import "./button.scss";

import { Type, Size } from "./types";
import { handleEventPromise, EventHandler } from "../../utils";

import WithTooltip from "../tooltips/WithTooltip";
import Text from "../typography/Text";

type Weight = "bold" | "light";

type Icon = React.ReactElement<typeof Abc>;
// Default props for that button
const defaultProps = {
  weight: "bold" as Weight,
  outlined: false,
  disabled: false,
  loading: false,
  submit: false,
  tooltip: "",
  pulseAnimation: false,
  customPulseAnimation: "",
};

export type Props = {
  /** Buttons accept either a string, an icon and a string, or a string and an icon. */
  children: string | [Icon, string] | [string, Icon] | Icon;
  /** The type of button to display */
  type: Type;
  /** The size of the button */
  size: Size;
  /** is this button outlined? */
  outlined?: boolean;
  /** The weight of the button */
  weight?: Weight;
  /** The callback function to be executed when the button is clicked */
  onClick: EventHandler;
  /** Whether the button should be disabled */
  disabled?: boolean;
  /** Whether the button should show a loading state */
  loading?: boolean;
  /** Tooltip to display on hover */
  tooltip?: string;
  /** Whether the button is currently submitting */
  submit?: boolean;
  /** Whether the button is currently animating */
  pulseAnimation?: boolean;
  /** add custom pulse animation to the button */
  customPulseAnimation?: string;
} & typeof defaultProps;

const Button = ({
  children,
  type,
  size,
  outlined,
  weight,
  onClick,
  disabled,
  loading,
  tooltip,
  submit,
  pulseAnimation,
  customPulseAnimation,
}: Props): JSX.Element => {
  // This convert space into name to kebab case.
  const buttonClass = `conversight-button ${type.replace(" ", "-")}`;
  const outlinedClass = outlined ? `${buttonClass}-outlined` : "";
  const loadingClass = loading ? "loading" : "";
  const pulseAnimationClass = pulseAnimation ? "pulse-animation" : "";

  let iconClass = "";

  if (Array.isArray(children)) {
    if (typeof children[0] === "object") {
      iconClass = "icon-left";
    } else if (typeof children[1] === "object") {
      iconClass = "icon-right";
    }
  }

  return (
    <WithTooltip tooltip={tooltip}>
      {/* Sadly, we can't use a disabled Button with OverlayTrigger
    to display a Tooltip directly. We have to wrap it in a div.
    See: https://github.com/react-bootstrap/react-bootstrap/issues/2428 */}
      <div className="button-wrapper">
        <Text truncate nowrap>
          <button
            className={`conversight ${size}-${weight} ${buttonClass} ${loadingClass} ${outlinedClass} ${iconClass} ${pulseAnimationClass} ${customPulseAnimation}`}
            style={disabled ? { pointerEvents: "none" } : {}}
            disabled={disabled || loading}
            onClick={handleEventPromise(onClick)}
            type={submit ? "submit" : "button"}
          >
            {loading && <Spinner animation="border" size={size === "small" ? "sm" : undefined} />}
            <div className="button-content">{children}</div>
          </button>
        </Text>
      </div>
    </WithTooltip>
  );
};

Button.defaultProps = defaultProps;
export default Button;
