module.exports = function (connectStatus) {
  switch (connectStatus) {
    case "CONNECT_STATUS_ANSWERED":
      return "Connected";
    case "CONNECT_STATUS_BUSY":
      return "Busy";
    case "CONNECT_STATUS_CANCELED":
      return "Canceled";
    case "CONNECT_STATUS_CLOSED":
      return "Closed";
    case "CONNECT_STATUS_COMPLETED":
      return "Successful";
    case "CONNECT_STATUS_FAILED":
      return "Failed";
    case "CONNECT_STATUS_NO_ANSWER":
      return "No Answer";
    case "CONNECT_STATUS_UNKNOWN":
      return "Unknown";
    default:
      return connectStatus;
  }
};
