import React from "react";

import "./flex.scss";

import { Align, AlignItems, Direction, Gap, Grow, Justify, Margin, Padding, Shrink, TextAlign } from "../types";

type FlexBasis = "content";

const defaultProps = {
  direction: "column",
  gap: "medium",
  grow: false as Grow | false,
  shrink: false as Shrink | false,
  align: false as Align | false,
  alignSelf: false as Align | false,
  alignItems: false as AlignItems | false,
  fillChildren: false as boolean,
  textAlign: false as TextAlign | false,
  margin: false as Margin | false,
  padding: false as Padding | false,
  justify: false as Justify | false,
  basis: false as FlexBasis | false,
  wrap: false as boolean,
  inline: false as boolean,
};

export type Props = {
  /** The children elements. */
  children: React.ReactNode;
  /** The direction of the flex box children. */
  direction?: Direction;
  /** The gap between each child in the flex box. */
  gap?: Gap;
  /** The amount to set flex grow to for this box. */
  grow?: Grow;
  /** The amount to set flex shrink to for this box. */
  shrink?: Shrink;
  /** The way items are aligned in the flex box. */
  align?: Align | false;
  /** The way this is aligned in the parent box. */
  alignSelf?: Align | false;
  /** The way the items are aligned in the flex box */
  alignItems?: AlignItems | false;
  /** The way text is aligned in the flex box. */
  textAlign?: TextAlign | false;
  /** The margin to be used around the flex box. */
  margin?: Margin | false;
  /** The padding to be used inside the flex box. */
  padding?: Padding | false;
  /** The way items are justified in the flex box. */
  justify?: Justify | false;
  /** Flex basis for the children */
  basis?: FlexBasis | false;
  /** Stretch each child element to fill the available width */
  fillChildren?: boolean;
  /** Whether or not to wrap the flex box. */
  wrap?: boolean;
  /** Whether or not the flex box should be displayed inline. */
  inline?: boolean;
} & typeof defaultProps;

const Flex = ({
  children,
  direction,
  gap,
  grow,
  shrink,
  align,
  alignSelf,
  alignItems,
  fillChildren,
  textAlign,
  margin,
  padding,
  justify,
  basis,
  wrap,
  inline,
}: Props): JSX.Element => {
  const classes = [
    "conversight",
    "conversight-flex",
    `conversight-flex-${direction}`,
    `conversight-flex-gap-${gap}`,
    align ? `conversight-flex-align-${align}` : "",
    alignSelf ? `conversight-flex-align-self-${alignSelf}` : "",
    alignItems ? `conversight-flex-align-items-${alignItems}` : "",
    textAlign ? `conversight-flex-text-align-${textAlign}` : "",
    justify ? `conversight-flex-justify-${justify}` : "",
    margin ? `conversight-flex-margin-${margin}` : "",
    padding ? `conversight-flex-padding-${padding}` : "",
    grow ? `conversight-flex-grow-${grow}` : "",
    shrink ? `conversight-flex-shrink-${shrink}` : "",
    wrap ? "conversight-flex-wrap" : "",
    basis ? `conversight-flex-basis-${basis}` : "",
    fillChildren ? "conversight-flex-fill-children" : "",
    inline ? "conversight-flex-inline" : "",
  ].filter((className) => className.length > 0);
  return <div className={classes.join(" ")}>{children}</div>;
};

Flex.defaultProps = defaultProps;

export default Flex;
