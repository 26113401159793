import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./addNewLocation.scss";

import { ActiveUser, StoreTypes } from "../../app/StoreTypes";
import { defaultCharacter } from "../../atoms/agents/types";
import useValidateAgentId from "../../hooks/useValidateAgentId";

import AgentGuideModal from "../agentGuide/AgentGuideModal";
import SubscriptionChangeSummary from "./SubscriptionChangeSummary";
import SuccessfullySubscribed from "./SuccessfullySubscribed";
import RequireTrialToPaidConversion from "./RequireTrialToPaidConversion";
import RequireSubscription from "./RequireSubscription";

const NoActiveUser = (): JSX.Element => <p>No active user.</p>;

type Props = {
  onNext: () => void;
};

const AddNewLocation = ({ onNext }: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine if the user is returning to this path after just having subscribed.
  const [searchParams] = useSearchParams();
  const [subscribed, setSubscribed] = useState<boolean>(searchParams.get("subscribed") !== null);

  const { activeUser } = useSelector<StoreTypes>((state) => state.user) as { activeUser: ActiveUser | false };
  const { dashboardActiveAgent: agent } = useValidateAgentId();
  // Get metadata from the agent, or fallback to some reasonable defaults.
  const defaultMetadata = { characterId: defaultCharacter.id, name: defaultCharacter.name };
  const { characterId } = agent ?? defaultMetadata;

  const hasUsedTrial = activeUser && activeUser.trialConsumed;

  const elementsToRender = useMemo(() => {
    if (!activeUser) {
      return {
        title: "Oops, not sure what happened",
        agentHint: "Looks like we encountered an error. Try contacting support.",
        Component: NoActiveUser,
      };
    }

    if (activeUser.planName === "solo") {
      if (!subscribed) {
        return {
          title: "To add a location, subscribe to Goodcall",
          agentHint: "To add a location, you’ll need to leave Demo mode and become a subscriber.",
          withParams: {
            Component: RequireSubscription,
            params: { hasUsedTrial, onNext: () => setSubscribed(true) },
          },
        };
      }

      return {
        title: "You successfully subscribed to Goodcall",
        agentHint: "Congratulations! You can now add as many locations as you need!",
        Component: SuccessfullySubscribed,
      };
    }

    const inTrial = activeUser.planName === "premium" && activeUser.subscriptionStatus === "trialing";

    if (inTrial) {
      if (!subscribed) {
        return {
          title: "To add locations, first convert your trial into a subscription",
          agentHint:
            "Once you convert your trial into a paid subscription, you’ll be able to add as many locations as you’d like",
          withParams: {
            Component: RequireTrialToPaidConversion,
            params: { hasUsedTrial, onNext: () => setSubscribed(true) },
          },
        };
      }

      return {
        title: "You successfully subscribed to Goodcall",
        agentHint: "Congratulations! You can now add as many locations as you need!",
        Component: SuccessfullySubscribed,
      };
    }

    return {
      title: "Adding a location will change your subscription details",
      agentHint: "You’ll be able to copy the skills you’ve already set up when you build your new location.",
      withParams: {
        Component: SubscriptionChangeSummary,
        params: { hasUsedTrial, onNext },
      },
    };
  }, [activeUser, onNext, subscribed, hasUsedTrial]);

  const { title, agentHint, withParams, Component } = elementsToRender;

  const onCloseTo = searchParams.get("onCloseTo");

  const onClose = onCloseTo
    ? () => navigate(onCloseTo)
    : () => navigate(location.pathname.replace("add-new-location", ""));

  return (
    <AgentGuideModal characterId={characterId} title={title} agentHint={agentHint} onClose={onClose}>
      {withParams ? <withParams.Component {...withParams.params} /> : <Component />}
    </AgentGuideModal>
  );
};

export default AddNewLocation;
