import { updateKnowledgeData } from "../api/KnowledgeApi";
export default async function (e, agentId, data, refresh) {
  data.enabled = e?.target?.checked;
  if (data.enabled === "undefined") {
    data.enabled = e.checked;
  }

  await updateKnowledgeData(agentId, data.knowledgeType, data);
  await refresh(data.knowledgeType, data.enabled);
}
