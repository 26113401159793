module.exports = (knowledgeData = {}) => {
  // Capitalize the first letter of a given string
  const capitalize = (string) => string && string[0].toUpperCase() + string.slice(1);

  let knowledgeNames = [];

  // Check if open hours is enabled
  const isOpenHoursEnabled = knowledgeData["open-hours"]?.enabled || false;

  // Key to identify take message behavior
  const takeMessageKey = "take-message";

  // Iterate over all the knowledge items
  for (const knowledge in knowledgeData) {
    // Get available and unavailable tabs for the current knowledge item
    const available = knowledgeData[knowledge]?.available;
    const unavailable = knowledgeData[knowledge]?.unavailable;
    // Get the behavior type for the current knowledge item
    const behaviorType = knowledgeData[knowledge]?.behavior?.behaviorType || available?.behaviorType || "";

    // Check if the current knowledge item has the take message behavior type and is enabled
    if ([behaviorType, unavailable?.behaviorType].includes(takeMessageKey) && knowledgeData[knowledge]?.enabled) {
      // Capitalize the name of the current knowledge item
      const capitalizedSkillName = capitalize(knowledge.replace("-", " "));

      // Check if the current knowledge item has available or unavailable tabs
      if (available || unavailable) {
        // Check if the unavailable tab has the take message behavior type and open hours are enabled
        if (unavailable?.behaviorType === takeMessageKey && isOpenHoursEnabled) {
          knowledgeNames.push(capitalizedSkillName);
        }
        // Check if the available tab has the take message behavior type
        else if (available?.behaviorType === takeMessageKey) {
          knowledgeNames.push(capitalizedSkillName);
        }
      } else {
        // If the current knowledge item doesn't have tabs, push its capitalized name to the knowledge names array
        knowledgeNames.push(capitalizedSkillName);
      }
    }
  }

  // Return the array of knowledge names
  return knowledgeNames;
};
