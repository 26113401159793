import React, { createContext, useEffect, useMemo, useContext, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GuideItem, getCurrentAgentGuideStep, markStepAsCompleted } from "../api/AgentApiNew";

// Define the shape of the guide context
interface IAgentGuideContext {
  currentStep: GuideItem["data"] | undefined;
  markAsCompleted: () => Promise<void>;
}

// Create the guide context with default values
const AgentGuideContext = createContext<IAgentGuideContext>({
  currentStep: undefined,
  markAsCompleted: async () => {},
});

// Custom hook to use the AgentGuideContext
export const useAgentGuide = () => useContext(AgentGuideContext);

interface AgentGuideProviderProps {
  children: React.ReactNode;
}

// AgentGuideProvider component
export const AgentGuideProvider: React.FC<AgentGuideProviderProps> = ({ children }) => {
  const { agentId = "" } = useParams<{ agentId: string }>();

  // Extract the pathname from the URL
  const pathName = window.location.pathname.split("/dashboard/").pop() || "";

  // Fetch the current guide step data using react-query
  const { data, refetch } = useQuery(["guide", agentId], () => getCurrentAgentGuideStep(agentId), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  // Function to mark a step as completed and refetch data
  const markAsCompleted = useCallback(async () => {
    if (data?.data?.guideItem?.id) {
      await markStepAsCompleted(agentId, data.data.guideItem.id);
      await refetch();
    }
  }, [agentId, data?.data?.guideItem?.id, refetch]);

  // Effect to handle automatic completion based on URL navigation
  useEffect(() => {
    const navigateUrls = data?.data?.guideItem?.data?.completionCriteria?.navigateUrls || [];
    const excludeUrls = data?.data?.guideItem?.data?.completionCriteria?.excludeUrls || [];
    // Check if the current URL matches the completion criteria
    const urlMatch = navigateUrls.some((url: string) => pathName.match(url));
    // Check if the current URL should be excluded from the completion criteria
    const urlExclude = excludeUrls.some((url: string) => pathName.match(url));

    if (urlMatch && !urlExclude) {
      markAsCompleted().catch(console.error);
    }
  }, [data, markAsCompleted, pathName]);

  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(
    () => ({
      currentStep: data?.data?.guideItem?.data,
      markAsCompleted,
    }),
    [data?.data?.guideItem?.data, markAsCompleted]
  );

  return <AgentGuideContext.Provider value={value}>{children}</AgentGuideContext.Provider>;
};
