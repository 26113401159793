import React, { useState } from "react";
import { ArrowForward, ExitToApp } from "@mui/icons-material";
import Flex from "../../atoms/flex/Flex";
import Pill from "../../atoms/pills/Pill";
import NavTabs from "../../atoms/pills/NavTabs";
import NavItem from "../../atoms/pills/NavItem";
import AgentGuide from "../agentGuide/AgentGuide";
import { CharacterId } from "../../atoms/agents/types";
import ActionArea from "../../molecules/ActionArea";
import Text from "../../atoms/typography/Text";
import Button from "../../atoms/buttons/Button";
import useNavigateWithQuery from "../../hooks/useNavigateWithQuery";
import useResponsive from "../../hooks/useResponsive";
import { EventHandler } from "../../utils";

type Props = {
  selectedAgent: CharacterId;
};

type Tab = "info" | "instructions";

export const TabPill = ({
  active,
  onClick,
  icon,
  label,
}: {
  active: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
}) => (
  <div role="button" tabIndex={0} onClick={onClick}>
    <Pill active={active}>
      <Flex alignItems="center" gap="small" direction="row">
        {icon}
        {label}
      </Flex>
    </Pill>
  </div>
);

export const TabNav = ({ active, onClick, label }: { active: boolean; onClick: () => void; label: string }) => (
  <div role="button" tabIndex={0} onClick={onClick}>
    <NavTabs>
      <NavItem isActive={active}>{label}</NavItem>
    </NavTabs>
  </div>
);

export const ConnectBase = ({ onClick }: { onClick: EventHandler }): JSX.Element => {
  const [activeTab, setActiveTab] = useState<Tab>("info");
  const isMobile = useResponsive("mobile");
  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
  };

  return (
    <Flex>
      {isMobile && <div />}

      <Flex direction="row">
        <TabNav active={activeTab === "info"} onClick={() => handleTabClick("info")} label="Info" />
        <TabNav
          active={activeTab === "instructions"}
          onClick={() => handleTabClick("instructions")}
          label="Instructions"
        />
      </Flex>
      <ActionArea>
        {activeTab === "info" ? (
          <Flex>
            <Flex direction="row" align="center" gap="small">
              <img src="/assets/media/images/zapier-transparent.svg" alt="Zapier integration" />
              <ArrowForward opacity={0.5} />
              <img src="/assets/media/images/zapier-integration-frame.svg" alt="Zapier integration icons" />
              <Text color="base-black" type="body-1">
                +1000&apos;s more
              </Text>
            </Flex>

            <Text color="base-black" type="lead">
              <b>Connect Goodcall </b>with the software you already use. Creating Zaps with Goodcall events as triggers
              opens up unlimited integrations.
            </Text>
          </Flex>
        ) : (
          <Flex gap="small" direction="column">
            <Text color="base-black" type="lead">
              1. Log in or create a Zapier account
            </Text>
            <Text color="base-black" type="lead">
              2. Search for Goodcall
            </Text>
            <Text color="base-black" type="lead">
              3. Sign in using Goodcall credentials
            </Text>
            <Text color="base-black" type="lead">
              4. Click “Make a Zap”
            </Text>
          </Flex>
        )}

        <Button type="secondary" size="large" onClick={onClick}>
          <ExitToApp /> Get started
        </Button>
      </ActionArea>
    </Flex>
  );
};
const Connect = ({ selectedAgent }: Props): JSX.Element => {
  const navigate = useNavigateWithQuery();

  return (
    <AgentGuide
      characterId={selectedAgent}
      title="Connect Zapier"
      agentHint="Zapier is the quick, easy way to connect Goodcall with the tools you already use"
      size="medium"
      customAction
    >
      <ConnectBase onClick={() => navigate("agent")} />
    </AgentGuide>
  );
};

export default Connect;
