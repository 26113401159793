import api, { APIResponse } from "./APIUtilsNew";
import { EmptyObject } from "./AccountApiNew";

interface SetupIntentSecret {
  stripeSecret: string;
}

interface PaymentIntent {
  stripeSecret: string;
}

interface UpcomingInvoice {
  prorationStartDate: number | undefined;
  prorationEndDate: number;
  currentPeriodStart: number;
  prorationAmount: number;
  numberOfLocations: number;
  pricePerLocation: number | null;
  nextPaymentDate: number | null;
  amountDue: number;
  creditBalance: number;
  unusedTimeAmount: number;
  unusedTimeCount: number;
  total: number;
  coupon?: {
    percent_off: number;
  };
}

export type InvoiceStatus = "draft" | "open" | "void" | "paid" | "uncollectible";

interface Invoice {
  id: string;
  status: InvoiceStatus;
  date: Date;
  total: number;
  invoiceUrl: string | null;
}

interface Invoices {
  invoices: Invoice[];
}

type CreditCardBrand = "amex" | "diners" | "discover" | "jcb" | "mastercard" | "unionpay" | "visa" | "unknown" | "link";

export interface DefaultPaymentMethod {
  card: {
    brand: CreditCardBrand;
    expMonth: number;
    expYear: number;
    lastFour: string;
  };
}

interface PaymentMethod {
  paymentMethodId: string;
}

export const getStripeSetupIntentSecret = async (): Promise<APIResponse<SetupIntentSecret>> =>
  api.get("/users/stripe-setup-payment-secret");

export const getPaymentIntentSecret = async (): Promise<APIResponse<PaymentIntent>> => api.get("/users/setup-payment");

export const getUpcomingInvoice = async (action = ""): Promise<APIResponse<UpcomingInvoice>> =>
  api.get(`/users/retrieve-upcoming-invoices?action=${action}`);

export const getDefaultPaymentMethod = async (): Promise<APIResponse<DefaultPaymentMethod>> =>
  api.get(`/users/stripe-default-payment-method`);

export const getInvoices = async (): Promise<APIResponse<Invoices>> => api.get(`/users/retrieve-invoices`);

export const retryInvoice = async (invoiceId: string): Promise<APIResponse<Invoice>> =>
  api.post(`/users/retry-invoice/${invoiceId}`, {});

export const startSubscriptionAnnual = async (): Promise<APIResponse<EmptyObject>> =>
  api.post(`/users/start-subscription-annual`, {});

export const updateDefaultPaymentMethod = async (
  paymentMethod: PaymentMethod
): Promise<APIResponse<DefaultPaymentMethod>> =>
  api.put<DefaultPaymentMethod, PaymentMethod>(`/users/stripe-default-payment-method`, paymentMethod);

export const getUpcomingInvoiceForPlan = async ({
  planId,
  action,
}: {
  planId: string;
  action?: string;
}): Promise<APIResponse<UpcomingInvoice>> => api.get(`/users/upcoming-invoices/${planId}?action=${action || ""}`);

export default {
  getPaymentIntentSecret,
  getStripeSetupIntentSecret,
  getUpcomingInvoice,
  getDefaultPaymentMethod,
  updateDefaultPaymentMethod,
  getInvoices,
  startSubscriptionAnnual,
};
