import React, { useState } from "react";
import { toast } from "react-toastify";
import { AutoAwesome } from "@mui/icons-material";

import "./addNewLocation.scss";

import Button from "../../atoms/buttons/Button";
import Input from "../../atoms/input";
import ListGroup from "../../atoms/listGroup/ListGroup";
import LineItem from "../../atoms/lineItems/LineItem";
import { Loader } from "../../components/Shared/Loader";

import { startSubscription } from "../../api/AccountApiNew";
import sendGTMEvent from "../../helpers/sendGTMEvent";
import { formatErrorForDisplay } from "../../helpers";

import { usePaymentMethodQuery, useUpcomingInvoiceQuery } from "../../queries/user";
import CreditCardIcon from "../../atoms/CreditCardIcon";
import useUser from "../../hooks/useUser";
import { getPriceDetailsWithDiscount, priceIds } from "../../helpers/planHelper";

interface Props {
  onNext: () => void;
}

const RequireTrialToPaidConversion = ({ onNext }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const activeUser = useUser();
  const upcomingInvoice = useUpcomingInvoiceQuery(activeUser.id);
  const invoice = upcomingInvoice.data?.data;
  const { data, isLoading } = usePaymentMethodQuery(activeUser ? activeUser.id : "");
  const defaultPaymentMethod = data?.data?.card;
  const discount = invoice?.coupon?.percent_off || 0;
  const { discountedPriceString, savedAmountString, originalPrice } = getPriceDetailsWithDiscount(
    discount,
    priceIds.newPremiumPriceId
  );

  const onClick = async () => {
    if (activeUser) {
      try {
        setLoading(true);

        // Make an API call to start the subscription trial.
        const response = await startSubscription();

        if (response.error) {
          throw response.error;
        }

        // Send trial started event.
        sendGTMEvent("purchase", { value: "59.00" }, activeUser);

        // Notify the parent we're subscribed.
        onNext();
      } catch (error: unknown) {
        toast.error(formatErrorForDisplay(error));
      }

      setLoading(false);
    }
  };

  return (
    <div className="add-new-location-summary">
      <div className="header">
        <h5 className="h5-bold">Your trial only allows for one location</h5>
        <p className="body-1-regular">
          Each location costs <span className="body-1-bold">$59 monthly</span>. To add locations, start a paid
          subscription today.
        </p>
      </div>
      {isLoading || upcomingInvoice.isLoading ? (
        <Loader />
      ) : (
        <>
          {defaultPaymentMethod ? (
            <Input
              icon={<CreditCardIcon brand={defaultPaymentMethod?.brand ?? "unknown"} />}
              value={`**** **** **** ${defaultPaymentMethod?.lastFour || "****"} \t\t${
                defaultPaymentMethod?.expMonth || "**"
              }/${defaultPaymentMethod?.expYear || "**"}`}
              disabled
            />
          ) : (
            <p>No payment method found.</p>
          )}
          <ListGroup>
            <LineItem item="Order Summary" heading />
            {discount > 0 && (
              <LineItem
                item="Discount 50%"
                description="For first 2 months"
                negativeAmount
                amount={savedAmountString}
              />
            )}
            <LineItem
              item="Due today"
              amount={`${discountedPriceString}`}
              period="mo"
              total
              strickOutAmount={discount > 0 ? `${originalPrice}` : ""}
            />
          </ListGroup>
          <Button loading={loading} disabled={!defaultPaymentMethod} type="primary" size="large" onClick={onClick}>
            <AutoAwesome />
            Start my subscription
          </Button>
        </>
      )}
    </div>
  );
};

export default RequireTrialToPaidConversion;
