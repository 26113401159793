import React, { useId } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import Button from "../../atoms/buttons/Button";
import "./agentTooltip.scss";

interface Props {
  children: React.ReactNode;
  showTooltip: boolean;
  setTooltip: (value: boolean) => void;
  target: React.RefObject<HTMLInputElement>;
}

const AgentAvatarTooltip: React.FC<Props> = ({ children, showTooltip, setTooltip, target }) => {
  // Get the header image element
  const avatarImg = (target.current?.querySelector(".avatar") || target.current) as HTMLElement;

  return (
    <Overlay target={avatarImg} show={showTooltip} placement="bottom">
      {/* Tooltip component to display the content */}
      <Tooltip id={useId()} className="agent-hint-tooltip">
        {/* Container for the tooltip content */}
        <div className="tooltip-content d-flex flex-column">
          {/* Content body */}
          <div className="content-body d-flex">
            {/* Children passed as props to display the content */}
            <div className="body-1-light">{children}</div>
          </div>
          {/* Action button */}
          <div className="action-container d-flex justify-content-between mt-3 align-items-center">
            <Button onClick={() => setTooltip(false)} size="small" outlined type="white">
              Got it
            </Button>
          </div>
        </div>
      </Tooltip>
    </Overlay>
  );
};

export default AgentAvatarTooltip;
