import React from "react";

import "./navTabs.scss";
import useResponsive from "../../hooks/useResponsive";

/**
 * Props for the NavTab component
 */
type Props = {
  /** The nav tab elements as children. */
  children: React.ReactNode;
};

/**
 * A nav component that contains tabs.
 * @param param The props for the NavTabs component.
 * @returns A NavTabs component.
 */
const NavTabs = ({ children }: Props): JSX.Element => {
  const isMobile = useResponsive("mobile");

  return <div className={`conversight cs-nav-tabs ${isMobile ? "no-gap" : "small-gap"}`}>{children}</div>;
};

export default NavTabs;
