import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    activeUser: false,
  },
  reducers: {
    setUser: (state, { payload }) => {
      state.activeUser = payload;
      window.dataLayer.push({
        user_id: payload.id,
        email: payload.email,
        phonenumber: payload.phoneNumber,
        firstname: payload.firstName,
        lastname: payload.lastName,
      });
    },
    setLogout: (state) => {
      state.activeUser = false;
    },
  },
});

export const { setUser, setLogout } = userSlice.actions;

export default userSlice.reducer;
