import React from "react";

import { CreditCard } from "@mui/icons-material";

const supportedBrands = ["visa", "amex", "diners", "discover", "jcb", "mastercard", "link"];

type Props = {
  brand: string;
};

const CreditCardIcon: React.FC<Props> = ({ brand }) => {
  if (supportedBrands.includes(brand)) {
    return <img width="44" alt={`Credit card: ${brand}`} src={`/assets/media/images/cards/${brand}.png`} />;
  }

  return <CreditCard />;
};

export default CreditCardIcon;
