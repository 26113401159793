import React from "react";

import { Color, Weight } from "../types";

type Size = "1" | "2" | "3" | "4" | "5" | "6";

const defaultProps = {
  size: "3" as Size,
  weight: "bold" as Weight,
  color: "inherit" as Color,
};

export type Props = {
  /** The heading to be displayed. */
  children: React.ReactNode;
  /** The size of the heading. */
  size?: Size;
  /** The text weight of the heading. */
  weight?: Weight;
  color?: Color;
};

const Heading = ({ children, size, weight, color }: Props): JSX.Element => {
  const className = `conversight h${size || "3"}-${weight || "bold"} ${color ? `gc-color-${color}` : ""}`;

  if (size === "1") {
    return <h1 className={className}>{children}</h1>;
  }

  if (size === "2") {
    return <h2 className={className}>{children}</h2>;
  }

  if (size === "3") {
    return <h3 className={className}>{children}</h3>;
  }

  if (size === "4") {
    return <h4 className={className}>{children}</h4>;
  }

  if (size === "5") {
    return <h5 className={className}>{children}</h5>;
  }
  if (size === "6") {
    return <h6 className={className}>{children}</h6>;
  }

  // Should never happen, but just in case.
  return <h3 className={className}>{children}</h3>;
};

Heading.defaultProps = defaultProps;

export default Heading;
