import React, { ReactElement, ReactNode } from "react";
import "./listItem.scss";
import ListItemText from "./ListItemText";
import { Padding, Color } from "../types";

interface Props {
  firstLine: ReactNode;
  button?: ReactElement;
  secondLine?: ReactNode;
  padding?: Padding;
  icon?: ReactElement;
  noMargin?: boolean;
  fonSize?: string;
  enableHover?: boolean;
  color?: Color;
}

const ListItem: React.FC<Props> = ({
  firstLine,
  secondLine,
  button,
  icon,
  padding,
  noMargin,
  fonSize,
  enableHover,
  color,
}) => (
  <div
    className={`list-item list-item-padding-${padding || ""} ${noMargin ? "no-margin" : ""} ${
      enableHover ? "enable-hover" : ""
    } ${color ? `gc-color-${color}` : ""}`}
  >
    {icon && icon}
    <ListItemText firstLineText={firstLine} secondLineText={secondLine} fontSize={fonSize} />
    {button && button}
  </div>
);

ListItem.defaultProps = {
  secondLine: undefined,
  button: undefined,
  icon: undefined,
  padding: "none" as Padding,
  noMargin: false,
  fonSize: "body-1-regular",
  enableHover: true,
  color: "inherit",
};

export default ListItem;
