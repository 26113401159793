import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../atoms/modals/Modal";

import { CharacterId } from "../../atoms/agents/types";

import "./agentGuideModal.scss";
import AgentGuide, { Size } from "./AgentGuide";
import { StoreTypes } from "../../app/StoreTypes";
import useResponsive from "../../hooks/useResponsive";

// Default props for that button
const defaultProps = {
  onClose: () => {},
  characterId: "",
  backButton: false,
  sellingPoints: <> </>,
  staticBackdrop: false,
  flourish: <> </>,
  customAction: false,
  onBack: () => {},
  title: "",
  titleIcon: <> </>,
  size: "large" as Size,
  closeButton: true,
};

export type Props = {
  characterId?: CharacterId;
  agentHint: string;
  children: React.ReactNode;
  onClose?: () => void;
  backButton?: boolean;
  sellingPoints?: JSX.Element;
  customAction?: boolean;
  flourish?: JSX.Element;
  staticBackdrop?: boolean;
  onBack?: () => void;
  title?: string;
  titleIcon?: JSX.Element | SVGAElement;
  size?: Size;
  closeButton?: boolean;
} & typeof defaultProps;

const AgentGuideModal = ({
  characterId,
  agentHint,
  children,
  onClose,
  backButton,
  flourish,
  onBack,
  sellingPoints,
  customAction,
  title,
  titleIcon,
  size,
  staticBackdrop,
  closeButton,
}: Props): JSX.Element => {
  // There no reason that we require to pass characterId as prop, we can get it from redux store
  const selectedCharacterId = useSelector<StoreTypes>((state) => state.agent.selectedAgent) as CharacterId;
  const isMedium = useResponsive("medium");

  return (
    <Modal
      className="agent-guide-modal"
      onClose={onClose}
      closeButton={closeButton}
      staticBackdrop={staticBackdrop}
      size={isMedium ? "medium" : size}
      customSpacing
    >
      <AgentGuide
        characterId={characterId || selectedCharacterId}
        agentHint={agentHint}
        flourish={flourish}
        sellingPoints={sellingPoints}
        customAction={customAction}
        title={title}
        titleIcon={titleIcon}
        backButton={backButton}
        onBack={onBack}
        size={size}
      >
        {children}
      </AgentGuide>
    </Modal>
  );
};

AgentGuideModal.defaultProps = defaultProps;

export default AgentGuideModal;
