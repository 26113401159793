import React from "react";

import { PhoneCallbackOutlined, PinDropOutlined, AutoAwesome, BusinessCenterOutlined } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import Button from "../../atoms/buttons/Button";
import BulletItem from "../../atoms/bullets/BulletItem";
import ButtonGroup from "../../atoms/buttons/ButtonGroup";
import BulletGroup from "../../atoms/bullets/BulletGroup";
import Flex from "../../atoms/flex/Flex";
import Heading from "../../atoms/typography/Heading";

import useNavigateWithQuery from "../../hooks/useNavigateWithQuery";

type Props = {
  hasUsedTrial: boolean;
};

const RequireSubscription = ({ hasUsedTrial }: Props): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigateWithQuery();

  const subscribeUrl = `${location.pathname.replace("add-new-location", "knowledge/subscribe")}/choose-number`;
  const state = { returnToOnSuccess: `${location.pathname}?subscribed=1` };

  const onClickSubscribe = () => navigate(`${subscribeUrl}?isTrial=false`, { state });
  const onClickTrial = () => navigate(`${subscribeUrl}`);

  return (
    <Flex direction="column" gap="large">
      <Heading size="5" weight="bold">
        Why subscribe to Goodcall?
      </Heading>

      <BulletGroup>
        <BulletItem icon={<PhoneCallbackOutlined />}>
          <h3 className="lead-bold">Save time</h3> by connecting real-world customers with your agent
        </BulletItem>
        <BulletItem icon={<PinDropOutlined />}>
          <h3 className="lead-bold">Keep your local identity</h3> as your business grows with a local area code
        </BulletItem>
        <BulletItem icon={<BusinessCenterOutlined />}>
          <h3 className="lead-bold">Centrally manage</h3> all locations for just $59 monthly each
        </BulletItem>
      </BulletGroup>

      <ButtonGroup direction="column">
        <Button type="primary" size="large" onClick={onClickSubscribe}>
          <AutoAwesome /> Become a subscriber
        </Button>
        {!hasUsedTrial && (
          <Button type="tertiary dark" size="medium" onClick={onClickTrial}>
            Start a trial with 1 location only
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  );
};

export default RequireSubscription;
