import React, { ReactNode, useEffect, useRef } from "react";
import Flex from "../../atoms/flex/Flex";
import Frame from "../../atoms/frame/Frame";
import "./dropdown.scss";
import { Padding } from "../../atoms/types";
import useResponsive from "../../hooks/useResponsive";

type Props = {
  /**
   * The children to display in the main container
   */
  containerChildren: ReactNode;
  /**
   * The children to display in the dropdown list itself
   */
  dropdownChildren: ReactNode;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  showContainerBackgroundColor?: boolean;
  showContainerShadow?: boolean;
  padding?: Padding;
  onMouseEnterHandler?: () => void;
  onMouseLeaveHandler?: () => void;
};

const Dropdown: React.FC<Props> = ({
  containerChildren,
  dropdownChildren,
  isActive,
  padding,
  setIsActive,
  showContainerBackgroundColor,
  showContainerShadow,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}: Props) => {
  const boxRef = useRef<HTMLDivElement>(null);

  const isTablet = useResponsive("tablet");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Delay the closing of the dropdown on tablet devices to allow the user to click on the dropdown items
      if (isTablet && isActive) {
        setTimeout(() => setIsActive(false), 100);
      }
      // On smaller screens, the dropdown closes when the user clicks outside the dropdown or the dropdown container
      else if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef, isActive, isTablet, setIsActive]);

  const backgroundColor = showContainerBackgroundColor ? "white" : undefined;
  const shadow = showContainerShadow ? "medium" : undefined;

  return (
    <div
      ref={boxRef}
      className="conversight dropdown"
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <Frame shadow={shadow} radius="medium" backgroundColor={backgroundColor}>
        <div onClick={() => setIsActive(!isActive)} className="dropdown-selected-container">
          <Flex direction="row" padding={padding} gap="small" alignItems="center" justify="space-between">
            {containerChildren}
            <Flex>
              <img
                alt="arrow"
                className={`d-flex ${isActive ? "flip-180" : ""}`}
                src="/assets/media/images/arrow-down.svg"
              />
            </Flex>
          </Flex>
        </div>
      </Frame>
      {isActive && (
        <Frame shadow="medium" radius="medium" backgroundColor="white">
          <div className="dropdown-content">{dropdownChildren}</div>
        </Frame>
      )}
    </div>
  );
};

Dropdown.defaultProps = {
  showContainerBackgroundColor: true,
  showContainerShadow: true,
  padding: "small",
  onMouseEnterHandler: undefined,
  onMouseLeaveHandler: undefined,
};

export default Dropdown;
