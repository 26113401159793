import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { UpgradeButton } from "../Shared/UpgradeButton";
import { planBenefitsDisplay, planUpgradePath } from "../../helpers/planHelper";
import { Close } from "@mui/icons-material";

const CustomModal = ({
  modalHeader,
  modalSubHeader,
  imageSource,
  onClose = () => {},
  // isVisible is deprecated, but until we migrate all of the modals
  // to not use it, we have to support it.
  isVisible,
}) => {
  const [show, setShow] = useState(true);

  // This will use the passed in isVisible, if it is provided,
  // until the user clicks to close the modal, then it will use
  // the internal state for it to do the hide animation. Once the
  // modal has fully exited, we'll call `onClose()` to set the
  // `isVisible` property back the old way of doing it.
  const showValueToUse = isVisible !== undefined && show === true ? isVisible : show;

  return (
    <>
      <Modal
        centered
        show={showValueToUse}
        onExited={() => {
          onClose();
          // Hack to reset the modal back to its original state for
          // the deprecated style of modal. The `show` value has to
          // be `true` for the `isVisible` value to kick in.
          if (isVisible !== undefined) {
            setShow(true);
          }
        }}
        onHide={() => setShow(false)}
        className={"upgrade-modal-new"}
      >
        <Modal.Body className="no-padding">
          <div className="d-flex">
            <div className="upgrade-premium w-100">
              <Close className="float-close" role="button" onClick={() => setShow(false)} />
              <div className="justify-content-center align-items-center upgrade-img-container">
                <img src={imageSource} className="img-fluid mx-auto d-block h-100" />
              </div>
              <div className="justify-content-center align-items-center upgrade-content-container">
                <div className="gc-h1 font-weight-500 gc-black-700 text-center">{modalHeader}</div>
                <div className="font-size-18 inter-font text-center mt-2 mx-auto">{modalSubHeader}</div>
                <div className="justify-content-center align-items-center text-center upgrade-btn-container">
                  <UpgradeButton position="skill"></UpgradeButton>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const SkillUpgradeModal = () => {
  // Get the upgrade modal state from history.
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isAddingPremiumSkill = false } = state || {};

  // Setup the close event.
  const { pathname } = useLocation();
  const onClose = () => {
    navigate(pathname.replace("/upgrade", ""));
  };

  const user = useSelector((state) => state.user.activeUser);

  const currentPlan = planBenefitsDisplay[user?.planName] ?? false;
  const premiumBenefits = planBenefitsDisplay["premium"];

  let imageSource = "/assets/media/images/upgrade-modal-person.svg";
  let modalHeader = <>Get unlimited skills with Goodcall</>;
  let modalSubHeader = (
    <>
      Your {currentPlan.displayName} plan includes up to {currentPlan.skills} skills. Delete an existing skill, or
      upgrade to get unlimited access to all Goodcall can offer for{" "}
      <span className="font-weight-700">${premiumBenefits.price}/mo</span>
    </>
  );

  if (isAddingPremiumSkill) {
    imageSource = "/assets/media/images/upgrade-modal-person.svg";
    modalHeader = <>Ready to unlock all that Goodcall can offer?</>;
    modalSubHeader = (
      <>
        Your current plan offers access to basic skills only. Unlock premium skills plus unlimited calls, users, and
        locations for <span className="font-weight-700">${premiumBenefits.price}/mo</span>
      </>
    );
  }

  return CustomModal({ modalHeader, modalSubHeader, imageSource, onClose });
};

export const UsersLocationsUpgradeModal = ({ isVisible, isModalVisible }) => {
  const onClose = () => {
    isModalVisible(false);
  };
  const user = useSelector((state) => state.user.activeUser);

  // get the next plan upgrade for the user
  const nextPlanLevel = planUpgradePath[user?.planName] ?? false;

  const currentPlan = planBenefitsDisplay[user?.planName] ?? false;
  // get the benefits of the next plan upgrade
  const nextLevelBenefits = planBenefitsDisplay[nextPlanLevel] ?? planBenefitsDisplay["premium"];

  const modalHeader = <>Go unlimited with Goodcall Premium</>;
  const modalSubHeader = (
    <>
      Your {currentPlan.displayName} plan is limited to {currentPlan.locations} location
      {currentPlan.name === "solo" ? "" : "s"} and {currentPlan.users} user{currentPlan.name === "solo" ? "" : "s"}. Get{" "}
      <span className="font-weight-700">unlimited</span> access to all Goodcall can offer for just{" "}
      <span className="font-weight-700">${nextLevelBenefits.price}/mo</span>
    </>
  );
  const imageSource = "/assets/media/images/upgrade-modal-person.svg";
  return CustomModal({
    modalHeader,
    modalSubHeader,
    imageSource,
    isVisible,
    onClose,
  });
};

export const CallLimitUpgradeModal = ({ isVisible, isModalVisible, isAboveCallLimit }) => {
  const onClose = () => {
    isModalVisible(false);
  };
  const user = useSelector((state) => state.user.activeUser);
  const currentPlan = planBenefitsDisplay[user?.planName] ?? false;
  let modalHeader = <></>;
  let modalSubHeader = <></>;
  let imageSource = "";
  if (isAboveCallLimit) {
    modalHeader = <>You&apos;ve reached your {currentPlan.calls} call limit for the month</>;
    modalSubHeader = (
      <>
        Your agent is answering the phone, but is not connecting callers to your business number. Limits reset when you
        make your monthly payment. Or, you can upgrade now.
      </>
    );
    imageSource = "/assets/media/images/upgrade-modal-exclamation.svg";
  } else {
    modalHeader = <>You&apos;re near your {currentPlan.calls} call limit for the month</>;
    modalSubHeader = (
      <>
        If you hit your limit, your agent can still answer the phone, but won&apos;t be able to connect callers to your
        business number. Limits reset when you make your monthly payment. Or, you can upgrade now.
      </>
    );
    imageSource = "/assets/media/images/upgrade-modal-person.svg";
  }
  return CustomModal({
    modalHeader,
    modalSubHeader,
    imageSource,
    isVisible,
    onClose,
  });
};
