const _ = require("lodash");
const sanitize = require("sanitize-html");
module.exports = (text) => {
  // Remove all HTML tags from the text this is very usefully as #1 step
  // <br> and similar tags will be removed from the text and
  // the characters "&", "<", ">", '"', "'", and "`" in string will be converted to their corresponding HTML entities.
  // input: Thanks you for calling me & you <br/>
  // output: Thanks you for calling me &amp;you
  const sanitizedText = sanitize(text, {
    allowedTags: [],
    allowedAttributes: {},
  });

  // So we use this method to convert the HTML entities &, <, >, ", ', and ` in string to their corresponding characters after cleanup
  // input: Thanks you for calling me &amp;you
  // output: Thanks you for calling me & you
  return _.unescape(sanitizedText);
};
