import refreshAvailableAgents from "./refreshAvailableAgents";

function refreshAgentOnHostNameChange(knowledge, availableAgents, agentId, dispatch) {
  // Get website hostname per current agent
  const { websiteHostname = "" } = availableAgents.find((x) => x.id === agentId) || {};

  const behaviorLink = knowledge?.behavior?.link || knowledge?.available?.link || knowledge?.unavailable?.link;

  // We do get the link per current behavior (behaviorLink) and compare it to current agent hostname
  // hostname is part of full url, So If the behaviorLink includes websiteHostname this means that hostname did not change
  // if It does not include it, This means that it's a new hostname and we need to refresh agent.
  behaviorLink && !behaviorLink?.includes(websiteHostname) && refreshAvailableAgents(dispatch);
}

export default refreshAgentOnHostNameChange;
