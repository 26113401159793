import { post, get, patch } from "./APIUtils";

export async function onboard({ code, instanceId, source }) {
  return await post(`/wix/onboard`, { code, instanceId, source });
}

export async function login({ instance }) {
  return await post(`/wix/login`, { instance });
}

export async function configuration(instanceId) {
  return await get(`/wix/configuration/${instanceId}`);
}

export async function updateConfiguration(instanceId, configuration) {
  return await patch(`/wix/configuration/${instanceId}`, configuration);
}

export async function getCheckoutDeeplink({ wixInstanceId }) {
  return await get(`/wix/checkout-deeplink/${wixInstanceId}`);
}
