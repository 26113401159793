import { ActiveUser, Agent } from "../app/StoreTypes";

export default class UserRoles {
  user: undefined | ActiveUser;

  agent: undefined | Agent;

  constructor(user: ActiveUser | undefined, agent: Agent | undefined) {
    this.user = user;
    this.agent = agent;
  }

  isOwner = () => {
    const { agent, user } = this;

    if (!user || !agent) {
      return false;
    }

    return !!user.roles.find((x) => x.agentId === agent.id && x.roleId === "role/owner");
  };

  isAdmin = () => {
    const { agent, user } = this;

    if (!user || !agent) {
      return false;
    }

    return !!user.roles.find((x) => x.agentId === agent.id && x.roleId === "role/admin");
  };
}
