import React from "react";
import { Modal } from "react-bootstrap";

export const WixFailedUpgradeModal = ({ isVisible, setIsViable }) => {
  const handleClose = () => {
    setIsViable(false);
  };
  return (
    <Modal centered show={isVisible} onHide={handleClose}>
      <Modal.Body className="no-padding">
        <div className="p-5 d-flex align-items-center justify-content-center flex-column">
          <img src="/assets/media/images/page-not-found.png" width={150} />
          <div className="gc-h3 mt-3  text-bold">
            Oops! It looks like you might have uninstalled the Goodcall Phone Assistant from your Wix website.
          </div>
          <div className="gc-h4 mt-3  w-100">
            1- Please visit your <a href="https://manage.wix.com/account/sites"> Wix Dashboard</a>
          </div>
          <div className="gc-h4 mb-3 mt-2  ">
            2- In the sidebar, select <b>Apps</b> {">"} <b>Manage Apps</b>, and ensure you have {"Goodcall's"} Phone
            Assistant installed.
          </div>

          <div className="gc-h3 mt-2 w-100 text-bold mt-3">Once installed, it should look like this:</div>

          <img src="/assets/media/images/wix-installed-example.png" className="img-responsive w-100 mt-3 mb-2" />
          <div className="gc-b2 mt-2 text-center alert alert-info">
            If the App is installed and the issue persists, please contact us for further assistance:
            support@goodcall.com.
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
