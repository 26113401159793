import { DirectoryContact, isFullDirectoryContact } from "../api/DirectoryApiNew";
import { BehaviorType } from "../api/types";

const contactHasConnectToBusinessBehavior = (contact: DirectoryContact) => {
  if (isFullDirectoryContact(contact)) {
    return (
      (contact.available.behaviorType === BehaviorType.Connect &&
        (contact.available.transferToContactId === undefined || contact.available.transferToContactId === false)) ||
      (contact.unavailable.behaviorType === BehaviorType.Connect &&
        (contact.unavailable.transferToContactId === undefined || contact.unavailable.transferToContactId === false))
    );
  }
  return false;
};

const getAllContactsWithConnectToBusinessBehavior = (contacts: DirectoryContact[] | undefined): string[] => {
  const connectedContacts = new Array<string>();
  if (contacts) {
    contacts.forEach((contact) => {
      if (contactHasConnectToBusinessBehavior(contact)) {
        connectedContacts.push(`${contact.firstName} ${contact.lastName} (${contact.title})`);
      }
    });
  }
  return connectedContacts;
};

export default getAllContactsWithConnectToBusinessBehavior;
