import { createSlice } from "@reduxjs/toolkit";
export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isImpersonation: false,
  },
  reducers: {
    setIsImpersonation: (state, { payload }) => {
      state.isImpersonation = payload;
    },
  },
});

export const { setIsImpersonation } = adminSlice.actions;

export default adminSlice.reducer;
