const moment = require("moment");

module.exports = function (durationInSeconds) {
  if (durationInSeconds === null) {
    return "-- : --";
  }

  const duration = moment.duration(durationInSeconds, "seconds");
  const durationMinutes = Math.floor(duration.asMinutes());
  const durationSeconds = Math.floor(duration.asSeconds() - durationMinutes * 60);
  const paddedDurationSeconds = durationSeconds < 10 ? "0" + durationSeconds : durationSeconds;
  return durationMinutes + ":" + paddedDurationSeconds;
};
