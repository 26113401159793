import React from "react";

import "./frame.scss";
import { Radius, Shadow, Color } from "../types";

const defaultProps = {
  shadow: "none" as Shadow,
  radius: "none" as Radius,
  backgroundColor: "none",
};

export type Props = {
  /** The shadow to be applied to the frame. */
  shadow?: Shadow;
  /** The radius to be applied to the frame. */
  radius?: Radius;
  /** The children to be displayed in the frame. */
  children: React.ReactNode;
  /** The background color of the frame. */
  backgroundColor?: Color;
};

const Frame = ({ shadow, children, radius, backgroundColor }: Props): JSX.Element => {
  const shadowClass = shadow && shadow !== "none" ? `shadow-${shadow}` : "";
  const radiusClass = radius && radius !== "none" ? `gc-radius-${radius}` : "";
  const background = backgroundColor ? `gc-bg-color-${backgroundColor}` : "";
  return <div className={`conversight frame ${shadowClass} ${radiusClass} ${background}`}>{children}</div>;
};

Frame.defaultProps = defaultProps;

export default Frame;
