import React, { ReactNode } from "react";
import "./listItemText.scss";

export type Type = "two-line" | "one-line";

type Props = { firstLineText: ReactNode; secondLineText?: ReactNode | undefined; fontSize?: string };

const ListItemText: React.FC<Props> = ({ firstLineText, secondLineText, fontSize = "body-1-regular" }) => {
  const classToUse = secondLineText !== undefined ? "two-line" : "one-line";
  return (
    <div className={`${classToUse}`}>
      <div className={`top-line ${fontSize} `}>{firstLineText}</div>
      {secondLineText !== undefined && <div className="bottom-line subtext-light-italic">{secondLineText}</div>}
    </div>
  );
};

ListItemText.defaultProps = {
  secondLineText: undefined,
  fontSize: "body-1-regular",
};

export default ListItemText;
