import React, { FC, ReactNode } from "react";
import "./button.scss";

import { Type, Size } from "./types";

import WithTooltip from "../tooltips/WithTooltip";
import { EventHandler, handleEventPromise } from "../../utils";

interface Props {
  /** The text to display on the button */
  children: ReactNode;
  /** The type of button to display */
  type: Type;
  /** The size of the button */
  size: Size;
  /** is this button outlined? */
  outlined?: boolean;
  /** is this button a circle? */
  circle?: boolean;
  /** The callback function to be executed when the button is clicked */
  onClick: EventHandler;
  /** Whether the button should be disabled */
  disabled?: boolean;
  /** Tooltip to display on hover */
  tooltip?: string;
  /** pulseAnimation add animation to the button */
  pulseAnimation?: boolean;
  /** add custom pulse animation to the button */
  customPulseAnimation?: string;
}

const IconButton: FC<Props> = ({
  children,
  type,
  size,
  outlined = false,
  circle = false,
  onClick,
  disabled = false,
  tooltip = "",
  pulseAnimation,
  customPulseAnimation = "",
}) => {
  // This convert space into name to kebab case.
  const buttonClass = `conversight-button ${type.replace(" ", "-")}`;
  const outlinedClass = outlined ? `${buttonClass}-outlined` : "";

  const iconClass = `icon icon-${size}`;
  const circleClass = circle ? `icon-circle icon-circle-${size}` : "";
  const pulseAnimationClass = pulseAnimation ? "pulse-animation" : "";

  return (
    <WithTooltip tooltip={tooltip}>
      {/* Sadly, we can't use a disabled Button with OverlayTrigger
    to display a Tooltip directly. We have to wrap it in a div.
    See: https://github.com/react-bootstrap/react-bootstrap/issues/2428 */}
      <div>
        <button
          className={`conversight ${buttonClass} ${outlinedClass} ${iconClass} ${circleClass} ${pulseAnimationClass} ${customPulseAnimation}`}
          onClick={handleEventPromise(onClick)}
          disabled={disabled}
          type="button"
        >
          {children}
        </button>
      </div>
    </WithTooltip>
  );
};

// Default props for that button
IconButton.defaultProps = {
  disabled: false,
  outlined: false,
  circle: false,
  tooltip: "",
  pulseAnimation: false,
  customPulseAnimation: "",
};

export default IconButton;
