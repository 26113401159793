import React from "react";
import "./bulletItem.scss";

type Props = {
  icon: React.ReactNode;
  children: React.ReactNode;
  marginRight?: string;
};

const defaultProps = {
  marginRight: "none",
};

const BulletItem = ({ icon, children, marginRight }: Props): JSX.Element => {
  const marginClass = marginRight && marginRight !== "none" ? `mr-${marginRight}` : "label-margin";
  return (
    <div className="conversight bullet-item">
      <div className={marginClass}>{icon}</div>
      <div className="bullet-text-container ">
        <p className="lead-regular">{children}</p>
      </div>
    </div>
  );
};

BulletItem.defaultProps = defaultProps;

export default BulletItem;
