type PriceIds = {
  soloPriceId: string;
  proPriceId: string;
  premiumPriceId: string;
  newPremiumPriceId: string;
  premiumAnnualPriceId: string;
};

type PlanBenefits = {
  locations: number | false;
  calls: number | false;
  users: number | false;
  contacts: number | false;
  skills: number | false;
  price: number | false;
  priceId: string | null;
};

type PlanBenefitsDisplay = {
  name: string;
  displayName: string;
  locations: string;
  calls: string;
  users: string;
  contacts: string;
  skills: string;
  price: string;
  priceId: string | null;
};

type PriceDetailsWithDiscount = {
  originalPrice: number;
  discountedPrice: number;
  discountedPriceString: string;
  savedAmount: number;
  savedAmountString: string;
};
const isProduction: boolean = process.env.REACT_APP_PROJECT_ID === "goodcall-production";

export const priceIds: PriceIds = isProduction
  ? {
      soloPriceId: "price_1JPVvwEfEL5vpc7RhYHsPcnm",
      proPriceId: "price_1JPVwuEfEL5vpc7RONfDqHtj",
      premiumPriceId: "price_1JPVxOEfEL5vpc7RKfKaOrOf",
      newPremiumPriceId: "price_1OCO55EfEL5vpc7Ro0j9Llo6",
      premiumAnnualPriceId: "price_1P96cxEfEL5vpc7RFRYbnui5",
    }
  : {
      soloPriceId: "price_1JCTGVEfEL5vpc7RLD9FpYU1",
      proPriceId: "price_1JCTG1EfEL5vpc7RZfF8R0gk",
      premiumPriceId: "price_1JCTHGEfEL5vpc7RVG1GrUda",
      newPremiumPriceId: "price_1OCO1oEfEL5vpc7RrxUf9wEo",
      premiumAnnualPriceId: "price_1PDYyiEfEL5vpc7RP5XQV3Rb",
    };

export const planBenefits: Record<string, PlanBenefits> = {
  demo: {
    locations: 1,
    calls: false,
    users: 1,
    contacts: 1,
    skills: false,
    price: false,
    priceId: null,
  },
  solo: {
    locations: 1,
    calls: 60,
    users: 1,
    contacts: 1,
    skills: 10,
    price: false,
    priceId: priceIds.soloPriceId,
  },
  pro: {
    locations: 5,
    calls: false,
    users: 5,
    contacts: false,
    skills: 16,
    price: 19,
    priceId: priceIds.proPriceId,
  },
  premium: {
    locations: false,
    calls: false,
    users: false,
    contacts: false,
    skills: false,
    price: 59,
    priceId: priceIds.premiumPriceId,
  },
  newPremium: {
    locations: false,
    calls: false,
    users: false,
    contacts: false,
    skills: false,
    price: 59,
    priceId: priceIds.newPremiumPriceId,
  },
  premiumAnnual: {
    locations: false,
    calls: false,
    users: false,
    contacts: false,
    skills: false,
    price: 492,
    priceId: priceIds.premiumAnnualPriceId,
  },
};

export const planBenefitsDisplay: Record<string, PlanBenefitsDisplay> = {
  demo: {
    name: "demo",
    displayName: "Demo",
    locations: "1",
    calls: "Unlimited",
    users: "1",
    contacts: "1",
    skills: "Unlimited",
    price: "Free",
    priceId: null,
  },
  solo: {
    name: "solo",
    displayName: "free",
    locations: "1",
    calls: "60",
    users: "1",
    contacts: "1",
    skills: "10",
    price: "Free",
    priceId: priceIds.soloPriceId,
  },
  pro: {
    name: "pro",
    displayName: "Pro",
    locations: "5",
    calls: "Unlimited",
    users: "Unlimited",
    contacts: "5",
    skills: "16",
    price: "19",
    priceId: priceIds.proPriceId,
  },
  premium: {
    name: "premium",
    displayName: "Premium",
    locations: "Unlimited",
    calls: "Unlimited",
    users: "Unlimited",
    contacts: "Unlimited",
    skills: "Unlimited",
    price: "59",
    priceId: priceIds.premiumPriceId,
  },
  premiumAnnual: {
    name: "premium",
    displayName: "Premium",
    locations: "Unlimited",
    calls: "Unlimited",
    users: "Unlimited",
    contacts: "Unlimited",
    skills: "Unlimited",
    price: "492",
    priceId: priceIds.premiumAnnualPriceId,
  },
};

export const getPlanByPriceId = (priceId: string): string | undefined =>
  Object.keys(planBenefits).find((key) => planBenefits[key].priceId === priceId);

export const isAnnualPlan = (priceId: string): boolean => priceId === priceIds.premiumAnnualPriceId;

export const isPremiumPlan = (priceId: string): boolean =>
  priceId === priceIds.premiumPriceId || isAnnualPlan(priceId) || priceId === priceIds.newPremiumPriceId;

export const planBenefitsDisplayById = (priceId: string): PlanBenefitsDisplay | undefined =>
  planBenefitsDisplay[getPlanByPriceId(priceId) || ""];

export const planUpgradePath: Record<string, string | false> = {
  demo: "premium",
  solo: "premium",
  pro: "premium",
  premium: false,
};

export const getPriceDetailsWithDiscount = (discount: number, priceId: string): PriceDetailsWithDiscount => {
  const plan = Object.keys(planBenefits).find((key) => planBenefits[key].priceId === priceId);
  const benefits = planBenefits[plan || ""];
  if (benefits?.price) {
    const originalPrice = benefits.price;
    const discountedPrice = benefits.price - (benefits.price * discount) / 100;
    const savedAmount = benefits.price - discountedPrice;
    return {
      originalPrice,
      discountedPrice,
      savedAmount,
      discountedPriceString: discountedPrice.toFixed(2),
      savedAmountString: savedAmount.toFixed(2),
    };
  }

  return {
    originalPrice: 0,
    discountedPrice: 0,
    savedAmount: 0,
    discountedPriceString: "0.00",
    savedAmountString: "0.00",
  };
};
