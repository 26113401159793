import React from "react";

export const Loader = (props) => {
  return (
    <div>
      <p className="gc-headline pt-0" style={{ textAlign: "center" }}>
        {props.text}{" "}
      </p>
      <div className="d-flex justify-content-center">
        <img
          width="300px"
          className="img-loader"
          src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/60f59f92273905.5e46dbb77e744.gif"
        ></img>
      </div>
    </div>
  );
};
