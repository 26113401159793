import React, { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { ActiveUser, Role, roleLevels } from "../app/StoreTypes";

// Function to check if the user has the appropriate role for a given agent
const userHasRolesForAgent = (user: ActiveUser, agentId: string, roleId: Role): boolean => {
  /*
   * Step 1: Get the level of the required role.
   * This is done by looking up the roleLevels object using the roleId.
   */
  const requiredRoleLevel = roleLevels[roleId];

  /*
   * Step 2: Check if the user has a role for the agent that matches or exceeds the required role level.
   * We use the some method to iterate over the user's roles.
   * For each role, we check two conditions:
   *   a) The role's agentId matches the specified agentId.
   *   b) The role's level (obtained from roleLevels) is less than or equal to the required role level.
   * If both conditions are met for any role, the some method returns true.
   */

  /* Example:
   * Suppose the user has the following roles:
   * [
   *   { agentId: "agent-1", roleId: Role.OWNER },
   *   { agentId: "agent-2", roleId: Role.ADMIN },
   *   { agentId: "agent-3", roleId: Role.EDITOR },
   *   { agentId: "agent-4", roleId: Role.PERFORMANCE_VIEWER }
   * ]
   * And we want to check if the user has the role of ADMIN for agent-2.
   * The required role level for ADMIN is 1.
   * The user has the role of ADMIN for agent-2, which matches the required role level.
   * Therefore, the function should return true.
   * If we wanted to check for the role of OWNER for agent-2, the function should return false.
   * The role of OWNER is 0, which is less than the required role level of 1.
   */

  return user.roles.some(
    (userRole) => userRole.agentId === agentId && roleLevels[userRole.roleId] <= requiredRoleLevel
  );
};

// Define the shape of the context value
interface AccessControlContextType {
  isPerformanceViewer: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  isEditor: boolean;
}

// Define the props for the AccessControlProvider component
type AccessControlProviderProps = {
  user: ActiveUser;
  children: React.ReactNode;
};

// Create the context with a default value of null
const AccessControlContext = createContext<AccessControlContextType | null>(null);

const AccessControlProvider = ({ user, children }: AccessControlProviderProps) => {
  const { agentId = "" } = useParams();
  // Memoize the context value to avoid unnecessary re-renders
  const value = useMemo(() => {
    // Check if the user has role of OWNER for the specified agent
    const isOwner = userHasRolesForAgent(user, agentId, Role.OWNER);

    // Check if the user has role of ADMIN for the specified agent
    const isAdmin = userHasRolesForAgent(user, agentId, Role.ADMIN);

    // Check if the user is a editor for the specified agent
    const isEditor = userHasRolesForAgent(user, agentId, Role.EDITOR);

    // Check if the user is a viewer only
    const isPerformanceViewer = userHasRolesForAgent(user, agentId, Role.PERFORMANCE_VIEWER);

    // Return the context value
    return { isPerformanceViewer, isAdmin, isOwner, isEditor };
  }, [agentId, user]);

  // Provide the context value to children components
  return <AccessControlContext.Provider value={value}>{children}</AccessControlContext.Provider>;
};

// A custom hook to use the AccessControlContext
const useAccessControl = (): AccessControlContextType => {
  const context = useContext(AccessControlContext);
  if (!context) {
    throw new Error("useAccessControl must be used within an AccessControlProvider");
  }
  return context;
};

export default AccessControlProvider;
export { AccessControlContext, useAccessControl };
