import { createSlice } from "@reduxjs/toolkit";
import constants from "../../config/constants";

export const agentSlice = createSlice({
  name: "agent",
  initialState: {
    selectedAgent: constants.DEFAULT_AGENT,
    tempAgent: null,
    availableAgents: [],
    isAddingAnewLocation: false,
    callLimitModalTracking: [],
  },
  reducers: {
    setSelectedAgent: (state, { payload }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedAgent = payload;
    },
    setTempAgent: (state, { payload }) => {
      state.tempAgent = payload;
    },
    setAvailableAgents: (state, { payload }) => {
      state.availableAgents = payload;
    },
    setIsAddingAnewLocation: (state, { payload }) => {
      state.isAddingAnewLocation = payload;
      state.selectedAgent = constants.DEFAULT_AGENT;
    },
    addCallLimitModalTracking: (state, { payload }) => {
      state.callLimitModalTracking.push(payload);
    },
    setLogout: (state) => {
      state.isAddingAnewLocation = false;
      state.availableAgents = [];
      state.dashboardActiveAgent = [];
      state.selectedAgent = constants.DEFAULT_AGENT;
      state.callLimitModalTracking = [];
      state.skillWizard = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedAgent,
  setTempAgent,
  setAvailableAgents,
  setIsAddingAnewLocation,
  setLogout,
  addCallLimitModalTracking,
} = agentSlice.actions;

export default agentSlice.reducer;
