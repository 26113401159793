import _ from "lodash";
import React from "react";

import { Navigate } from "react-router-dom";

import useLoggedInUser from "../hooks/useLoggedInUser";

type Props = {
  children: React.ReactNode;
};

/**
 * This component requires that the user not be logged in to
 * render the children components. If the user is logged in,
 * it redirects them to the dashboard.
 */
const LoggedOutRoute = ({ children }: Props) => {
  // Get the logged in user, but don't retry on failure.
  const loggedInUser = useLoggedInUser({ retry: false });

  // Check if the logged in user is trying to get to a wix connected agent.
  const query = new URLSearchParams(window.location.search);
  const wixInstanceId = query.get("instanceId");

  // If we aren't still loading the logged in user, and we
  // didn't get an error, then we successfully found a logged
  // in user and they should be redirected to the dashboard.
  if (loggedInUser !== "loading" && !_.isError(loggedInUser)) {
    // If the user is trying to get to a wix connected agent, drop them
    // on the wix landing page in the dashboard.
    if (wixInstanceId) {
      return <Navigate to={`/dashboard/wix-landing/${wixInstanceId}`} />;
    }

    return <Navigate to="/dashboard" />;
  }

  // Otherwise, either we're still loading the user's state,
  // or the user is not logged in. If we're still loading, we
  // can be optimistic and redirect them once it finishes. If
  // they aren't logged in, then.... Cool.
  return children;
};

export default LoggedOutRoute;
