import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
  name: "business",
  initialState: {
    business: {},
    spamBlockerEnabled: true,
  },
  reducers: {
    setBusiness: (state, { payload }) => {
      state.business = payload;
      state.spamBlockerEnabled = true;
    },
    setLogout: (state) => {
      state.business = {};
    },
    setSpamBlocker: (state, { payload }) => {
      state.spamBlockerEnabled = payload;
    },
  },
});

export const { setBusiness, setLogout, setSpamBlocker } = businessSlice.actions;

export default businessSlice.reducer;
