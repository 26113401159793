import api, { APIResponse } from "./APIUtilsNew";
import { Behavior, LookupSource, MultiChoiceBehaviorChoice } from "./types";

export type LogicItem = {
  id: string;
  logic: Logic;
};

export enum LogicType {
  MultipleChoice = "multiple-choice",
  YesNo = "yes-no",
  CustomerLookup = "customer-lookup",
}

export type LogicList = LogicItem[];

export type MultipleChoiceLogic = {
  type: LogicType.MultipleChoice;
  name: string;
  question: string;
  choices: MultiChoiceBehaviorChoice[];
  noMatchBehavior?: Behavior;
  customOutcomeProcessor?: "ackerman";
};

export type YesNoLogic = {
  type: LogicType.YesNo;
  name: string;
  question: string;
  yesBehavior: Behavior;
  noBehavior: Behavior;
};

export type CustomerLookupLogic = {
  type: LogicType.CustomerLookup;
  name: string;
  lookupSource: LookupSource;
  found: Behavior;
  notFound: Behavior;
};

export type Logic = MultipleChoiceLogic | YesNoLogic | CustomerLookupLogic;

// Get form by id
export const getLogic = async (agentId: string, id: string): Promise<APIResponse<{ logic: Logic }>> =>
  api.get(`/agents/${agentId}/logic/${id}`);

// List all Logic
export const listLogic = async (agentId: string): Promise<APIResponse<LogicList>> =>
  api.get(`/agents/${agentId}/logic`);

// Create Logic
export const createLogic = async ({
  agentId,
  logic,
}: {
  agentId: string;
  logic: Logic;
}): Promise<APIResponse<{ id: string; logic: Logic }>> => api.post(`/agents/${agentId}/logic`, logic);

// Update Logic
export const updateLogic = async ({
  id,
  agentId,
  logic,
}: {
  agentId: string;
  id: string;
  logic: Logic;
}): Promise<APIResponse<{ logic: Logic }>> => api.put(`/agents/${agentId}/logic/${id}`, logic);

// Update Logic
export const deleteLogic = async (agentId: string, id: string): Promise<APIResponse<void>> =>
  api.deleteRequest(`/agents/${agentId}/logic/${id}`);
