import React from "react";

type Size = "1" | "2" | "3" | "4" | "5";

type Weight = "bold" | "regular" | "light";

const defaultProps = {
  size: "3" as Size,
  weight: "bold" as Weight,
};

export type Props = {
  /** The title to be displayed. May include an icon. */
  children: React.ReactNode;
  /** The heading size of the title. */
  size?: Size;
  /** The text weight of the title. */
  weight?: Weight;
} & typeof defaultProps;

// TODO: Deprecate this in favor of `<Heading>`?
const Title = ({ children, size, weight }: Props): JSX.Element => {
  const className = `conversight h${size}-${weight} modal-title`;

  if (size === "1") {
    return <h1 className={className}>{children}</h1>;
  }

  if (size === "2") {
    return <h2 className={className}>{children}</h2>;
  }

  if (size === "3") {
    return <h3 className={className}>{children}</h3>;
  }

  if (size === "4") {
    return <h4 className={className}>{children}</h4>;
  }

  if (size === "5") {
    return <h5 className={className}>{children}</h5>;
  }

  return <h3 className="conversight h3-bold modal-title">{children}</h3>;
};

Title.defaultProps = defaultProps;

export default Title;
