module.exports = function (knowledgeData) {
  var doesItNeedSetup = false;
  switch (knowledgeData?.behavior?.behaviorType) {
    case "agent-says":
    case "connect":
      doesItNeedSetup = !knowledgeData?.behavior?.message ? true : false;
      break;
    case "send-link":
      doesItNeedSetup = !knowledgeData?.behavior?.message || !knowledgeData?.behavior?.link ? true : false;
      break;
  }
  if (knowledgeData.hours && knowledgeData.hours.length === 0) {
    doesItNeedSetup = true;
  }
  return doesItNeedSetup;
};
