import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const fetchVersion = async () => {
  try {
    const res = await fetch("/assets/version.txt");
    return await res.text();
  } catch (error) {
    return null;
  }
};

const useCheckVersion = () => {
  const currentVersion = process.env.REACT_APP_CURRENT_VERSION; // Current version of the UI

  const [newVersion, setNewVersion] = useState<string | null>(null); // Stores the fetched version

  const { data, isFetching } = useQuery(["ui-version-check"], fetchVersion, {
    refetchOnWindowFocus: true,
    refetchInterval: 60 * 1000 * 5, // 5 minutes
  });

  useEffect(() => {
    if (data && data !== currentVersion) {
      // Cleanup the version string
      setNewVersion(data.trim());
    }
  }, [data, currentVersion]);

  return {
    isNewVersionAvailable: currentVersion && newVersion !== currentVersion && newVersion && !isFetching,
  };
};

export default useCheckVersion;
