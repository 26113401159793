export enum PhoneFormat {
  FULL = "FULL",
  AREA_CODE = "AREA_CODE",
  SIMPLE = "SIMPLE,",
}

export const formatPhoneNumber = (
  phoneNumber: string | false | null | undefined,
  format = PhoneFormat.FULL
): string => {
  if (!phoneNumber) {
    return "";
  }

  const cleaned = phoneNumber.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (!match) {
    return phoneNumber;
  }

  const [, intl, area, prfx, numb] = match;

  if (format === PhoneFormat.FULL) {
    return `+${intl} (${area}) ${prfx}-${numb}`;
  }

  if (format === PhoneFormat.AREA_CODE) {
    return `(${area}) ${prfx}-${numb}`;
  }

  if (match && format === PhoneFormat.SIMPLE) {
    return `${area}-${prfx}-${numb}`;
  }

  return phoneNumber;
};

export default formatPhoneNumber;
