import { capitalize } from "lodash";
import { Behavior, BehaviorType } from "../api/types";
import { Provider } from "../api/BusinessApiNew";

export interface SkillPartial {
  dateAdded?: number;
  behavior?: Behavior;
  available?: Behavior;
  unavailable?: Behavior;
  knowledgeType: string;
  triggerExamples?: string[];
  provider?: Provider;
  enabled: boolean;
}

export type Skills = Record<string, SkillPartial>;

const behaviorHasContactId = (behavior: Behavior | undefined, contactId: string): boolean => {
  if (!behavior) {
    return false;
  }
  if (behavior.behaviorType === BehaviorType.Connect) {
    return behavior.transferToContactId === contactId;
  }
  if (behavior.behaviorType === BehaviorType.TakeMessage) {
    return behavior.contactIdsToNotify?.some((id) => id === contactId) || false;
  }
  return false;
};

const skillIsConnectedToContact = (skill: SkillPartial, contactId: string): boolean =>
  skill.enabled &&
  (behaviorHasContactId(skill.behavior, contactId) ||
    behaviorHasContactId(skill.available, contactId) ||
    behaviorHasContactId(skill.unavailable, contactId));

const getAllSkillsConnectedToContact = (contactId: string, skills: Skills = {}) => {
  const connectedSkills = new Array<string>();
  Object.keys(skills).forEach((key) => {
    if (skillIsConnectedToContact(skills[key], contactId)) {
      connectedSkills.push(capitalize(key.replace("-", " ")));
    }
  });
  return connectedSkills;
};

export default getAllSkillsConnectedToContact;
