type DataLayer = [Record<string, string | Record<string, string>>] | undefined;

const sendGAEvent = (eventName: string, eventCategory: string, eventLabel: string) => {
  const currentWindow = window as unknown as { dataLayer: DataLayer };

  if (currentWindow.dataLayer) {
    currentWindow.dataLayer.push({
      event: eventName,
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
};

export default sendGAEvent;
