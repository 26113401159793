import React, { ReactElement, useState } from "react";

import "./input.scss";

import { EventHandler } from "../../utils";

export interface InputValue {
  value: string;
  valid: boolean;
}

export const defaultInputValue: InputValue = {
  value: "",
  valid: false,
};

const defaultProps = {
  disabled: false,
  icon: null as ReactElement | null,
  placeholder: "example",
  inputRef: null as React.MutableRefObject<HTMLInputElement> | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange: (input: InputValue) => {},
  onValidate: (value: string) => value.length > 1,
  onFocus: () => {},
  onBlur: () => {},
  onEnter: (() => {}) as EventHandler,
};

type Props = {
  disabled?: boolean;
  value: string;
  icon?: React.ReactNode;
  placeholder?: string;
  onChange?: (input: InputValue) => void;
  onValidate?: (value: string) => boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: EventHandler;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
} & typeof defaultProps;

/** @deprecated Consider using molecules/TextInput instead */
const Input = ({
  disabled,
  value,
  placeholder,
  icon,
  inputRef,
  onChange,
  onValidate,
  onFocus,
  onBlur,
  onEnter,
}: Props): JSX.Element => {
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  let borderStyle = isValid ? "valid" : "";
  borderStyle = isValid === false ? "invalid" : borderStyle;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    const { value: newValue } = event.target;
    const valid = onValidate(newValue);

    setIsValid(valid);
    onChange({ value: newValue, valid });
  };

  return (
    <div className="conversight input-container">
      {icon}
      <input
        ref={inputRef}
        placeholder={placeholder}
        className={`lead-light ${icon ? "has-icon" : ""} ${borderStyle}`}
        onChange={handleChange}
        disabled={disabled}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onEnter(event)?.catch(() => {});
          }
        }}
      />
    </div>
  );
};

Input.defaultProps = defaultProps;

export default Input;
