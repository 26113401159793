import { v4 as uuidv4 } from "uuid";
import api, { APIResponse } from "./APIUtilsNew";
import { EmptyObject } from "./AccountApiNew";
import { Behavior } from "./types";

type DirectoryNotifications = {
  email: boolean;
  sms: boolean;
};

// represents an existing or saved directory contact
export interface FullDirectoryContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phoneNumber: string;
  available: Behavior;
  unavailable: Behavior;
  notifications: DirectoryNotifications;
  type: "contact";
}

export interface FullDirectoryDepartment {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  available: Behavior;
  unavailable: Behavior;
  notifications: DirectoryNotifications;
  type: "department";
}

// represents a new directory contact, which has not been saved yet
export type NewDirectoryContact = Omit<FullDirectoryContact, "available" | "unavailable">;

// represents a new directory department, which has not been saved yet
export type NewDirectoryDepartment = Omit<FullDirectoryDepartment, "available" | "unavailable">;

// union type of directory contacts
export type DirectoryContact = FullDirectoryContact | NewDirectoryContact;

// union type of directory departments
export type DirectoryDepartment = FullDirectoryDepartment | NewDirectoryDepartment;

// type guard for if the directory contact is new
export const isNewDirectoryContact = (contact: DirectoryContact): contact is NewDirectoryContact =>
  !Object.prototype.hasOwnProperty.call(contact, "available");

export const isFullDirectoryContact = (contact: DirectoryContact): contact is FullDirectoryContact =>
  Object.prototype.hasOwnProperty.call(contact, "available");

export const isFullDirectoryDepartment = (directory: DirectoryDepartment): directory is FullDirectoryDepartment =>
  Object.prototype.hasOwnProperty.call(directory, "available");

// represents the return types
export type DirectoryContacts = DirectoryContact[] | undefined;

export type DirectoryDepartments = DirectoryDepartment[] | undefined;

export type DirectoryRecordType = FullDirectoryContact | FullDirectoryDepartment;

export interface DirectoryContactsListResponse {
  contacts: DirectoryContacts;
}

export interface DirectoryDepartmentsListResponse {
  departments: DirectoryDepartments;
}

export interface DirectoryContactResponse {
  contact: DirectoryContact | undefined;
}
export interface DirectoryDepartmentResponse {
  department: FullDirectoryDepartment | undefined;
}

export const getAllContacts = (agentId: string) => {
  const result = api.get<DirectoryContactsListResponse>(`/agents/${agentId}/directories/contact`);
  return result;
};

export const getAllDirectoryData = (agentId: string) => {
  const result = api.get<DirectoryContactsListResponse>(`/agents/${agentId}/directory`);
  return result;
};
export const getAllDepartments = (agentId: string) => {
  const result = api.get<DirectoryDepartmentsListResponse>(`/agents/${agentId}/directories/department`);
  return result;
};

export const getContactData = ({
  agentId,
  contactId,
}: {
  agentId: string;
  contactId: string;
}): Promise<APIResponse<DirectoryContactResponse>> => {
  if (contactId === "new") {
    const defaultContact: NewDirectoryContact = {
      id: uuidv4(),
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      title: "Manager",
      notifications: {
        sms: true,
        email: true,
      },
      type: "contact",
    };

    return Promise.resolve({ data: { contact: defaultContact }, error: null });
  }
  return api.get<DirectoryContactResponse>(`/agents/${agentId}/directory/${contactId}`);
};

export const getDepartmentData = ({
  agentId,
  departmentId,
}: {
  agentId: string;
  departmentId: string;
}): Promise<APIResponse<DirectoryDepartmentResponse>> =>
  api.get<DirectoryDepartmentResponse>(`/agents/${agentId}/directory/${departmentId}`);

export const updateDirectoryData = ({ agentId, contact }: { agentId: string; contact: DirectoryContact }) =>
  api.put<DirectoryContactResponse, DirectoryContact>(`/agents/${agentId}/directory/${contact.id}`, contact);

export const deleteContact = ({ agentId, contactId }: { agentId: string; contactId: string }) =>
  api.deleteRequest<EmptyObject>(`/agents/${agentId}/directory/${contactId}`);
