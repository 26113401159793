import _ from "lodash";
import axios, { AxiosResponse } from "axios";
import hotjar from "../helpers/hotjar";

const { REACT_APP_API_URL } = process.env;

type APIData = JSON;

export interface APIResponse<T = APIData> {
  data: T | null;
  error: Error | null;
}

type APIError = {
  errors: string[];
};

const authErrors = [
  "The decoded token was missing expected keys",
  "Token has been invalidated",
  "No token was decoded",
  "User not found",
  "No auth token",
  "jwt expired",
];

const isAPIError = (object: unknown): object is APIError => Object.prototype.hasOwnProperty.call(object, "errors");

export const apiUrl = REACT_APP_API_URL || `${window.location.protocol}//api.${window.location.host}/v0`;

export const resolve = async <T = APIData>(
  promise: Promise<AxiosResponse<T>>,
  redirectOnAuthFailure = true
): Promise<APIResponse<T>> => {
  const resolved: APIResponse<T> = {
    data: null,
    error: null,
  };

  try {
    const response = await promise;
    const csrfToken = (response.headers as Record<string, unknown>)["x-csrf-token"] as string;
    axios.defaults.headers.common["x-csrf-token"] = csrfToken;
    resolved.data = response.data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.status === 401) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { data } = error.response;

        if (isAPIError(data) && Array.isArray(data.errors) && data.errors.length > 0) {
          const errorMessage = data.errors[0];
          if (redirectOnAuthFailure && authErrors.includes(errorMessage)) {
            localStorage.setItem("loggedIn", "false");
            window.location.replace(`/login?redirect=${window.location.pathname}`);
          }
        }
      } else if (error.response && error.response.status === 429) {
        hotjar.sendEvent("RATE_LIMIT");
        throw new Error("Too many requests. Please try again later.");
      }

      if (error.response && error.response.data) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { data } = error.response;

        if (isAPIError(data) && Array.isArray(data.errors) && data.errors.length > 0) {
          const errorMessage = data.errors[0];
          throw new Error(errorMessage);
        }
      }
    }

    // If all other handling fails, just throw the error.
    throw error;
  }

  return resolved;
};

const defaultOptions = () => ({
  withCredentials: true,
});

const ensureCSRFToken = async (): Promise<void> => {
  const headers = axios.defaults.headers as unknown as Record<string, unknown>;
  const common = headers.common as Record<string, string>;

  if (common["x-csrf-token"] !== undefined) {
    return Promise.resolve();
  }

  await resolve(axios.get(`${apiUrl}/csrf`, defaultOptions()));
  return Promise.resolve();
};

const get = async <T = APIData>(path: string, options = {}, redirectOnAuthFailure = true): Promise<APIResponse<T>> => {
  await ensureCSRFToken();
  return resolve<T>(
    axios.get(`${apiUrl}${path}`, {
      ...defaultOptions(),
      ...options,
    }),
    redirectOnAuthFailure
  );
};

const patch = async <T = APIData, D = APIData>(path: string, data: D, options = {}): Promise<APIResponse<T>> => {
  await ensureCSRFToken();
  return resolve<T>(
    axios.patch(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
};

const post = async <T = APIData, D = APIData>(path: string, data: D, options = {}): Promise<APIResponse<T>> => {
  await ensureCSRFToken();
  return resolve<T>(
    axios.post(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
};

const put = async <T = APIData, D = APIData>(path: string, data: D, options = {}): Promise<APIResponse<T>> => {
  await ensureCSRFToken();
  return resolve<T>(
    axios.put(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
};

// Delete is a reserved keyword
const deleteRequest = async <T = APIData>(path: string, options = {}): Promise<APIResponse<T>> => {
  await ensureCSRFToken();
  return resolve<T>(
    axios.delete(`${apiUrl}${path}`, {
      ...defaultOptions(),
      ...options,
    })
  );
};

export const formatErrorForDisplay = (error: unknown, fallbackMessage = "Encountered an unknown error"): string => {
  if (isAPIError(error)) {
    return error.errors[0];
  }

  if (axios.isAxiosError(error)) {
    return error.message;
  }

  if (_.isError(error)) {
    return error.message;
  }

  return fallbackMessage;
};

const api = {
  get,
  patch,
  post,
  put,
  deleteRequest,
  formatErrorForDisplay,
};

export default api;
