import { get, put, post } from "./APIUtils";
import formatErrorForDisplay from "../helpers/formatErrorForDisplay";
import { ensureHttpsPrefix } from "../helpers/urlHelper";
import sanitizeSkillConfig from "../helpers/sanitizeSkillConfig";
import { BehaviorType } from "./types";

export async function getKnowledgeData(agentId) {
  return await get(`/agents/${agentId}/knowledge`);
}

export async function getKnowledge({ agentId, skill }) {
  let path = `/agents/${agentId}/knowledge`;

  if (skill) {
    path = `${path}/${skill}`;
  }

  const result = await get(path);

  if (result.error) {
    throw new Error(formatErrorForDisplay(result.error));
  }

  return result.data;
}

export async function updateKnowledge({ agentId, type, data }) {
  let path = `knowledge`;

  if (data?.behavior?.behaviorType === "send-link") {
    data.behavior.link = ensureHttpsPrefix(data.behavior.link);
  } else {
    if (data?.available?.behaviorType === "send-link") {
      data.available.link = ensureHttpsPrefix(data.available.link);
    }
    if (data?.unavailable?.behaviorType === "send-link") {
      data.unavailable.link = ensureHttpsPrefix(data.unavailable.link);
    }
  }

  if (data?.behavior?.message) {
    data.behavior.message = sanitizeSkillConfig(data.behavior.message);
  } else if (data?.available?.message) {
    data.available.message = sanitizeSkillConfig(data.available.message);
    data.unavailable.message = sanitizeSkillConfig(data.unavailable.message);
  }

  if (data?.triggerExamples) {
    path = "user-configured-knowledge";
  }

  if (data?.available?.behaviorType === BehaviorType.Logic) {
    data.available.sayOpenHours = false;
  }
  if (data?.unavailable?.behaviorType === BehaviorType.Logic) {
    data.unavailable.sayOpenHours = false;
  }
  if (data?.behaviorType === "multiple-choice") {
    data.sayOpenHours = false;
  }

  const dataToPut = { ...data, knowledgeType: type };
  const result = await put(`/agents/${agentId}/${path}/${type}`, dataToPut);

  if (result.error) {
    throw new Error(formatErrorForDisplay(result.error));
  }

  return result.data;
}

export async function getKnowledgeDataByAgentIds(agentIds) {
  return await post(`/agents/get-knowledge-by-agents-ids`, { agentIds });
}

export async function getPreviewAudio(agentId, dialogs) {
  return await post(`/agents/preview/${agentId}`, dialogs, {
    responseType: "blob",
  });
}
export async function updateKnowledgeData({ agentId, type, data }) {
  data.knowledgeType = type;
  return await put(`/agents/${agentId}/knowledge/${type}`, data);
}
