import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./NotFound.css";

const NotFound = () => {
  return (
    <>
      <Helmet title="Page Not Found" />
      <div className="not-found">
        <img className="logo" src="/assets/media/images/frowny-logo.png" alt="Sad Goodcall Logo" />
        <div className="content d-flex">
          <div className="d-flex align-items-center">
            <div className="text-container justify-content-center">
              <h3 className="mb-3">Sorry, I couldn’t find the page you’re looking for.</h3>
              <div className="pt-4">
                <button className="btn gc-cta-button gc-c1-red-background" onClick={() => (window.location.href = "/")}>
                  Proceed to Goodcall.com
                </button>
              </div>
            </div>
          </div>
          <img
            src="/assets/media/images/this-is-not-the-page-you-are-looking-for.png"
            alt="This is not the page you are looking for"
            className="img-fluid"
          />
          {/* For react-snap to work, we need to have a link to the pages we wanted a snapshot of here. */}
          {navigator.userAgent === "ReactSnap" && (
            <>
              <Link hidden to="/login/">
                Login
              </Link>
              <Link hidden to="/dashboard/">
                Dashboard
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NotFound;
