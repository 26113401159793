import React, { useState } from "react";
import { Search as SearchIcon, ClearRounded } from "@mui/icons-material";
import "./search.scss";

export type Type = "rounded" | "square";

type Props = {
  type: Type;
  inputType?: React.HTMLInputTypeAttribute | undefined;
  canCollapse?: boolean;
  onClick?: () => void;
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  maxLength?: number;
  setIsSearchLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
};

const Search: React.FC<Props> = ({
  onClick,
  onChange,
  placeholder,
  maxLength,
  type,
  defaultValue,
  canCollapse,
  inputType,
  setIsSearchLoading,
  className = "",
}) => {
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(canCollapse !== true);

  const [inputValue, setInputValue] = useState<string>(defaultValue || "");

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => event.stopPropagation();

  const handleSearchIconClick = () => {
    if (canCollapse) {
      setIsSearchBarVisible(true);
    }
  };

  const handleSearchBarClose = () => {
    if (canCollapse) {
      setIsSearchBarVisible(false);
    }
    setInputValue("");
    onChange("");
  };

  const handleInputChange = (value: string) => {
    if (maxLength && value.length <= maxLength) {
      onChange(value);
      setInputValue(value);
    }
    if (setIsSearchLoading) {
      setIsSearchLoading(true);
    }
  };

  const showClearButton = canCollapse || inputValue.length > 0;
  const roundedBorderClass = type === "rounded" ? "rounded-border" : type;

  return (
    <div
      className={`gc-search ${roundedBorderClass} ${isSearchBarVisible ? "open" : ""} ${className}`}
      onMouseDown={handleClick}
    >
      <SearchIcon className="main-search-icon" onClick={handleSearchIconClick} />
      <input
        type={inputType}
        value={inputValue}
        placeholder={placeholder}
        onClick={onClick}
        onChange={(e) => handleInputChange(e.target.value)}
        maxLength={maxLength}
      />
      {showClearButton && <ClearRounded className="cancel-icon" onClick={handleSearchBarClose} />}
    </div>
  );
};

Search.defaultProps = {
  placeholder: "",
  defaultValue: undefined,
  maxLength: 255,
  canCollapse: true,
  inputType: undefined,
  onClick: undefined,
  className: "",
  setIsSearchLoading: undefined,
};

export default Search;
