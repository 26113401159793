import { get, post, patch, deleteRequest } from "./APIUtils";

export async function createAccount(data) {
  data.campaign = localStorage.getItem("campaign") || "";
  data.toltReferral = window?.tolt_referral || "";
  return await post("/users", data);
}

export async function signIn(data) {
  return await post("/users/login", data);
}

export async function getProfile(redirectOnAuthFailure = true) {
  return await get("/users/me", {}, redirectOnAuthFailure);
}

export async function updateProfile(data) {
  return await patch("/users/me", data);
}

export async function sendResetPasswordLink(data) {
  return await post("/users/reset-password", data);
}
export async function verifyResetPasswordToken(data) {
  return await post("/users/verify-reset-password-token", data);
}
export async function setNewPasswordUsingToken(data) {
  return await post("/users/set-new-password", data);
}
export async function changePassword(currentPassword, newPassword) {
  return await patch("/users/change-password", {
    currentPassword,
    newPassword,
  });
}

export async function deleteAccount() {
  return await deleteRequest("/users");
}
export async function postDeleteAccountFeedback(data) {
  return await post("/users/post-delete-account-feedback", data);
}

export async function logout() {
  return await post("/users/logout", {});
}
