import React from "react";

import { Replay } from "@mui/icons-material";

import Button from "../../atoms/buttons/Button";

import "./errorBoundaryFallback.scss";

import hotjar from "../../helpers/hotjar";
import { formatErrorForDisplay } from "../../helpers";

type Props = {
  error: Error;
  tag: string;
  resetErrorBoundary: () => void;
};
const ErrorBoundaryFallback = ({ error, resetErrorBoundary, tag }: Props): JSX.Element => {
  // Send error to hotjar
  hotjar.sendEvent(tag);

  // ignore loadChunkError
  if (/Loading chunk [\d]+ failed/.test(error.message)) {
    window.location.reload();
    return <> </>;
  }
  return (
    <div className="conversight error-boundary-container ">
      <img src="/assets/media/images/error.svg" alt="Oh Snap!" />
      <div className="h1-regular">Oh Snap!</div>
      <div className="error-body">
        <div className="h3-regular"> Sorry, we encountered an error</div>

        <code>{formatErrorForDisplay(error)}</code>
        <code>
          <b>Date:</b> {new Date().toISOString()}
        </code>
        <code>
          <b>Location:</b> {window.location.href}
        </code>
        <code>
          <b>Version:</b> {process.env.REACT_APP_VERSION || "-"}
        </code>
      </div>

      <div className="footer">
        <Button type="primary" outlined size="large" onClick={resetErrorBoundary}>
          <Replay /> Try Again
        </Button>
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
