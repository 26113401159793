import { SyntheticEvent } from "react";

/// Synchronous handler.
type SyncHandler = () => void;

/// Asynchronous handler.
type AsyncHandler = () => Promise<void>;

/// Asynchronous event handler.
type AsyncEventHandler = (event: SyntheticEvent) => Promise<void>;

/// Synchronous event handler.
type SyncEventHandler = (event: SyntheticEvent) => void;

/// Event handler of every type.
export type EventHandler = SyncHandler | AsyncHandler | AsyncEventHandler | SyncEventHandler;

/** Converts any kind of event handler into a synchronous event handler,
 *  to allow us to easily support asynchronous event handlers on dom nodes.
 * @param handler Any type of handler.
 * @returns A synchronous version of that handler.
 */
export const handleEventPromise = (handler: EventHandler): SyncEventHandler => {
  // Create a sync handler.
  const syncHandler: SyncEventHandler = (event) => {
    // When this handler is called, just call the passed in handler.
    const result = handler(event);

    // If the result is a promise, add a catch to it and log any errors.
    if (result instanceof Promise) {
      result.catch((error) => {
        // eslint-disable-next-line no-console
        console.log("Unexpected error handling event", error);
      });
    }
  };

  // Return the synchronous wrapper for our handler.
  return syncHandler;
};

export default { handleEventPromise };
