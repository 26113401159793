import React from "react";
import Flex from "../flex/Flex";

type Props = {
  children: React.ReactNode;
};

const BulletGroup = ({ children }: Props): JSX.Element => (
  <Flex direction="column" gap="medium-large">
    {children}
  </Flex>
);

export default BulletGroup;
