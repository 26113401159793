import React, { FC, ReactNode } from "react";
import useResponsive from "../../hooks/useResponsive";
import Flex from "../flex/Flex";
import Text from "../typography/Text";
import "./pill.scss";

export type Status = "default" | "success" | "failure" | "attention";

interface Props {
  status?: Status;
  icon?: ReactNode;
  label?: string;
  children?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  showLabelOnMobile?: boolean;
}

const Pill: FC<Props> = ({ status = "default", label = "", icon, active, children, disabled, showLabelOnMobile }) => {
  const isMobile = useResponsive("small");
  const cardSize = isMobile ? "mobile-card" : "";

  let className = `${active ? "active" : ""}`;
  className = `${className} ${disabled ? "disabled" : ""}`;

  return children ? (
    <div className={`conversight pill ${className}`}>
      <div className="content">{children}</div>
    </div>
  ) : (
    <div title={label} className={`conversight call-${status} caller-status-new ${cardSize} `}>
      <Flex direction="row" gap="xsmall" justify="center" alignItems="center">
        {icon && icon}
        {(!isMobile || showLabelOnMobile) && (
          <Text weight="regular" color="base-black" type={isMobile ? "body-2" : "body-1"}>
            {label}
          </Text>
        )}
      </Flex>
    </div>
  );
};

Pill.defaultProps = {
  status: "default",
  icon: undefined,
  children: undefined,
  active: false,
  disabled: false,
  label: "",
  showLabelOnMobile: true,
};

export default Pill;
