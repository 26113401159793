module.exports = {
  URL_REGEX: "^(?!.{255,})(http|https)://[\\w-]+(\\.[\\w-]+)+([\\w.,@?^=%&amp;:/~+#-]*[\\w@?^=%&amp;/~+#-])?$",
  BUSINESS_CATEGORIES: [
    {
      label: "Active Life",
      value: 1,
      wixVerticals: ["fitness"],
    },
    {
      label: "Arts & Entertainment",
      value: 2,
      wixVerticals: ["music", "photography"],
    },
    {
      label: "Automotive",
      value: 3,
    },
    {
      label: "Beauty & Spas",
      wixVerticals: ["health-wellness"],
      value: 4,
    },
    {
      label: "Bicycles",
      value: 5,
    },
    {
      label: "Education",
      value: 6,
    },
    {
      label: "Event Planning & Services",
      value: 7,
      wixVerticals: ["events"],
    },
    {
      label: "Financial Services",
      value: 8,
    },
    {
      label: "Food",
      value: 9,
    },
    {
      label: "Health & Medical",
      value: 10,
    },
    {
      label: "Home Services",
      value: 11,
    },
    {
      label: "Hotels & Travel",
      value: 12,
      wixVerticals: ["accomodation"],
    },
    {
      label: "Local Flavor",
      value: 13,
    },
    {
      label: "Local Services",
      value: 14,
      wixVerticals: ["business"],
    },
    {
      label: "Mass Media",
      value: 15,
      wixVerticals: ["blog"],
    },
    {
      label: "Nightlife",
      value: 16,
    },
    {
      label: "Pets",
      value: 17,
    },
    {
      label: "Professional Services",
      value: 18,
      wixVerticals: ["portfolio-cv", "design"],
    },
    {
      label: "Public Services & Government",
      value: 19,
    },
    {
      label: "Religious Organizations",
      value: 20,
    },
    {
      label: "Restaurants",
      value: 21,
      wixVerticals: ["restaurants-food"],
    },
    {
      label: "Shopping",
      value: 22,
      wixVerticals: ["online-store"],
    },
    {
      label: "Other",
      value: 23,
    },
  ],
  OPEN_HOURS_OPTIONS: [
    {
      value: "open_24_hours",
      text: "Open 24 hours",
    },
    {
      value: "0000",
      text: "12:00 am (midnight)",
    },
    {
      value: "0015",
      text: "12:15 am",
    },
    {
      value: "0030",
      text: "12:30 am",
    },
    {
      value: "0045",
      text: "12:45 am",
    },
    {
      value: "0100",
      text: "1:00 am",
    },
    {
      value: "0115",
      text: "1:15 am",
    },
    {
      value: "0130",
      text: "1:30 am",
    },
    {
      value: "0145",
      text: "1:45 am",
    },
    {
      value: "0200",
      text: "2:00 am",
    },
    {
      value: "0215",
      text: "2:15 am",
    },
    {
      value: "0230",
      text: "2:30 am",
    },
    {
      value: "0245",
      text: "2:45 am",
    },
    {
      value: "0300",
      text: "3:00 am",
    },
    {
      value: "0315",
      text: "3:15 am",
    },
    {
      value: "0330",
      text: "3:30 am",
    },
    {
      value: "0345",
      text: "3:45 am",
    },
    {
      value: "0400",
      text: "4:00 am",
    },
    {
      value: "0415",
      text: "4:15 am",
    },
    {
      value: "0430",
      text: "4:30 am",
    },
    {
      value: "0445",
      text: "4:45 am",
    },
    {
      value: "0500",
      text: "5:00 am",
    },
    {
      value: "0515",
      text: "5:15 am",
    },
    {
      value: "0530",
      text: "5:30 am",
    },
    {
      value: "0545",
      text: "5:45 am",
    },
    {
      value: "0600",
      text: "6:00 am",
    },
    {
      value: "0615",
      text: "6:15 am",
    },
    {
      value: "0630",
      text: "6:30 am",
    },
    {
      value: "0645",
      text: "6:45 am",
    },
    {
      value: "0700",
      text: "7:00 am",
    },
    {
      value: "0715",
      text: "7:15 am",
    },
    {
      value: "0730",
      text: "7:30 am",
    },
    {
      value: "0745",
      text: "7:45 am",
    },
    {
      value: "0800",
      text: "8:00 am",
    },
    {
      value: "0815",
      text: "8:15 am",
    },
    {
      value: "0830",
      text: "8:30 am",
    },
    {
      value: "0845",
      text: "8:45 am",
    },
    {
      value: "0900",
      text: "9:00 am",
    },
    {
      value: "0915",
      text: "9:15 am",
    },
    {
      value: "0930",
      text: "9:30 am",
    },
    {
      value: "0945",
      text: "9:45 am",
    },
    {
      value: "1000",
      text: "10:00 am",
    },
    {
      value: "1015",
      text: "10:15 am",
    },
    {
      value: "1030",
      text: "10:30 am",
    },
    {
      value: "1045",
      text: "10:45 am",
    },
    {
      value: "1100",
      text: "11:00 am",
    },
    {
      value: "1115",
      text: "11:15 am",
    },
    {
      value: "1130",
      text: "11:30 am",
    },
    {
      value: "1145",
      text: "11:45 am",
    },
    {
      value: "1200",
      text: "12:00 pm (noon)",
    },
    {
      value: "1215",
      text: "12:15 pm",
    },
    {
      value: "1230",
      text: "12:30 pm",
    },
    {
      value: "1245",
      text: "12:45 pm",
    },
    {
      value: "1300",
      text: "1:00 pm",
    },
    {
      value: "1315",
      text: "1:15 pm",
    },
    {
      value: "1330",
      text: "1:30 pm",
    },
    {
      value: "1345",
      text: "1:45 pm",
    },
    {
      value: "1400",
      text: "2:00 pm",
    },
    {
      value: "1415",
      text: "2:15 pm",
    },
    {
      value: "1430",
      text: "2:30 pm",
    },
    {
      value: "1445",
      text: "2:45 pm",
    },
    {
      value: "1500",
      text: "3:00 pm",
    },
    {
      value: "1515",
      text: "3:15 pm",
    },
    {
      value: "1530",
      text: "3:30 pm",
    },
    {
      value: "1545",
      text: "3:45 pm",
    },
    {
      value: "1600",
      text: "4:00 pm",
    },
    {
      value: "1615",
      text: "4:15 pm",
    },
    {
      value: "1630",
      text: "4:30 pm",
    },
    {
      value: "1645",
      text: "4:45 pm",
    },
    {
      value: "1700",
      text: "5:00 pm",
    },
    {
      value: "1715",
      text: "5:15 pm",
    },
    {
      value: "1730",
      text: "5:30 pm",
    },
    {
      value: "1745",
      text: "5:45 pm",
    },
    {
      value: "1800",
      text: "6:00 pm",
    },
    {
      value: "1815",
      text: "6:15 pm",
    },
    {
      value: "1830",
      text: "6:30 pm",
    },
    {
      value: "1845",
      text: "6:45 pm",
    },
    {
      value: "1900",
      text: "7:00 pm",
    },
    {
      value: "1915",
      text: "7:15 pm",
    },
    {
      value: "1930",
      text: "7:30 pm",
    },
    {
      value: "1945",
      text: "7:45 pm",
    },
    {
      value: "2000",
      text: "8:00 pm",
    },
    {
      value: "2015",
      text: "8:15 pm",
    },
    {
      value: "2030",
      text: "8:30 pm",
    },
    {
      value: "2045",
      text: "8:45 pm",
    },
    {
      value: "2100",
      text: "9:00 pm",
    },
    {
      value: "2115",
      text: "9:15 pm",
    },
    {
      value: "2130",
      text: "9:30 pm",
    },
    {
      value: "2145",
      text: "9:45 pm",
    },
    {
      value: "2200",
      text: "10:00 pm",
    },
    {
      value: "2215",
      text: "10:15 pm",
    },
    {
      value: "2230",
      text: "10:30 pm",
    },
    {
      value: "2245",
      text: "10:45 pm",
    },
    {
      value: "2300",
      text: "11:00 pm",
    },
    {
      value: "2315",
      text: "11:15 pm",
    },
    {
      value: "2330",
      text: "11:30 pm",
    },
    {
      value: "2345",
      text: "11:45 pm",
    },
  ],
  // Default open hours for a business if they don't have any
  // Monday - Friday 9am - 5pm
  DEFAULT_REGULAR_OPEN_HOURS: [
    {
      day: 1,
      start: "0900",
      end: "1700",
      is_overnight: false,
    },
    {
      day: 2,
      start: "0900",
      end: "1700",
      is_overnight: false,
    },
    {
      day: 3,
      start: "0900",
      end: "1700",
      is_overnight: false,
    },
    {
      day: 4,
      start: "0900",
      end: "1700",
      is_overnight: false,
    },
    {
      day: 5,
      start: "0900",
      end: "1700",
      is_overnight: false,
    },
  ],
  DEFAULT_AGENT: "2",
  AGENTS_ORDER: [2, 4, 6, 1, 5, 3],
  BEHAVIOR_SOURCE: { CONTACT: "contact", SKILL: "skill" },
  INPUT_MAX_LENGTH: 1000,
  AGENT_SETTING_INPUT_SIZE: 256,
  UNSAVED_CHANGES_MESSAGE: "You have unsaved changes, do you want to exit anyways?",
  TAKE_MESSAGE_DISABLED_HINT: "You need to add the message taking skill before you can turn this on.",
  SKILL_MODAL_BEHAVIOR_ORDER: ["agent-says", "send-link", "take-message", "connect", "contact-form", "logic"],
  VERTICAL_OBE_SKILLS: [
    {
      vertical: "Active Life",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Arts & Entertainment",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Automotive",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Beauty & Spas",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Bicycles",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Education",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Event Planning & Services",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Financial Services",
      firstSkill: "Appointment",
      secondSkill: "Parking",
    },
    {
      vertical: "Food",
      firstSkill: "Table Reservation",
      secondSkill: "Food Order",
    },
    {
      vertical: "Health & Medical",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Home Services",
      firstSkill: "Appointment",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Hotels & Travel",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Local Flavor",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Local Services",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Mass Media",
      firstSkill: "Appointment",
      secondSkill: "Parking",
    },
    {
      vertical: "Nightlife",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Pets",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Professional Services",
      firstSkill: "Appointment",
      secondSkill: "Parking",
    },
    {
      vertical: "Public Services & Government",
      firstSkill: "Appointment",
      secondSkill: "Parking",
    },
    {
      vertical: "Religious Organizations",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
    {
      vertical: "Restaurants",
      firstSkill: "Table Reservation",
      secondSkill: "Food Order",
    },
    {
      vertical: "Shopping",
      firstSkill: "Parking",
      secondSkill: "Covid policy",
    },
  ],

  PREMIUM_SKILL_KEYS: ["hold", "apply-for-a-job"],
  EXCLUDED_CALL_EVENTS: ["Agent Disconnected", "Caller Disconnected", "Find Matching Name"],
  EXCLUDED_CALL_UTTERANCES: ["messageEvent"],
  SKILLS_HELP_LINKS: {},
  DEFAULT_SKILL_STORE_ORDER: {
    "Recommended for you": [
      "open-hours",
      "operator",
      "greeting",
      "goodbye",
      "appointment",
      "change-appointment",
      "food-order",
      "pricing",
      "business-description",
      "hold",
      "take-message",
    ],
    "Make more money": [
      "appointment",
      "change-appointment",
      "walk-ins",
      "running-late",
      "pricing",
      "menu",
      "food-order",
      "table-reservation",
      "change-reservation",
      "confirm-reservation",
      "catering",
      "take-message",
    ],
    "Save your time": [
      "open-hours",
      "business-description",
      "business-website",
      "business-email",
      "return-policy",
      "location",
      "parking",
      "walk-in",
      "covid-policy",
      "pet-policy",
      "dining",
      "happy-hour",
      "spam-blocker",
      "delivery-info",
      "hold",
      "apply-for-a-job",
    ],
  },

  SKILLS_ORDER_PER_CATEGORY: [
    {
      categories: [22], // Shopping
      Recommended: [
        "open-hours",
        "operator",
        "greeting",
        "goodbye",
        "food-order",
        "delivery-info",
        "pricing",
        "business-description",
        "return-policy",
        "hold",
        "take-message",
      ],
    },
    {
      categories: [9], // Food
      Recommended: [
        "open-hours",
        "operator",
        "greeting",
        "goodbye",
        "food-order",
        "delivery-info",
        "appointment",
        "pricing",
        "catering",
        "business-description",
        "hold",
        "take-message",
        "walk-ins",
      ],
    },
    {
      categories: [21], // Restaurants
      Recommended: [
        "open-hours",
        "operator",
        "greeting",
        "goodbye",
        "food-order",
        "delivery-info",
        "table-reservation",
        "menu",
        "dining",
        "catering",
        "hold",
        "take-message",
      ],
    },
  ],
  SUBSCRIPTION_PATH: "knowledge/subscribe",
  NOT_ALLOWED_CHARACTERS: ["\\|"],
  PAGES_WITH_STICKY_NAVIGATION: ["/performance"],
  FLOW_LIMIT: 10,
};
