import { ActiveUser } from "../app/StoreTypes";
import api, { APIResponse } from "./APIUtilsNew";

export type EmptyObject = Record<string, never>;

export enum ZapierEvent {
  ACCOUNT_CREATED = "account_created",
  CUSTOMER_FEEDBACK = "customer_feedback",
  TRIAL_STARTED = "trial_started",
  TRIAL_CONVERTED = "trial_converted",
  TRIAL_CANCELLED = "trial_cancelled",
  LOCATION_ADDED = "location_added",
  CREDIT_CARD_ERROR = "credit_card_error",
  SOLO_UPGRADED = "solo_upgraded",
}

export interface OptionalZapierPayloadParams {
  value?: number;
  textResponse?: string;
  form?: string;
  trialDaysRemaining?: number;
  userMobileNumber?: string;
}

export interface EventParams {
  eventName: ZapierEvent;
  agentId: string;
  eventParams?: OptionalZapierPayloadParams;
}

export interface User {
  phoneNumber: string;
  verificationCode: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  keepMeUpdated: boolean;
  wixInstanceId?: string;
}

export interface PlanLimitsResult {
  agentNamesOverFlowLimit: string[];
  agentNamesOverLogicLimit: string[];
  agentNamesOverDirectoryLimit: string[];
  agentNamesOverUserLimit: string[];
}

export type PlanLimitsCheckResult = false | PlanLimitsResult;

export const createAccount = async (user: User): Promise<APIResponse<{ user: ActiveUser }>> => {
  const campaign = localStorage.getItem("campaign") || "";
  const toltReferral = window?.tolt_referral || "";
  return api.post("/users", { ...user, campaign, toltReferral });
};

export const getProfile = async (redirectOnAuthFailure = true): Promise<APIResponse<{ user: ActiveUser }>> =>
  api.get("/users/me", {}, redirectOnAuthFailure);

export const startSubscriptionTrial = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/start-subscription-trial", {});

export const startSubscriptionTrialForPlan = async (planId: string): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>(`/users/start-subscription-trial/${planId}`, {});

export const changeSubscriptionPlan = async (planId: string): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>(`/users/change-plan/${planId}`, {});

export const startSubscriptionFromTrial = async (planId: string): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>(`/users/start-subscription-from-trial/${planId}`, {});

export const startSubscriptionNew = async (planId: string): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>(`/users/start-subscription-new/${planId}`, {});

export const startSubscriptionFromFree = async (planId: string): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>(`/users/start-subscription-from-free/${planId}`, {});

export const startSubscription = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/start-subscription", {});

export const cancelSubscriptionTrial = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/cancel-subscription-trial", {});

export const cancelSubscription = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/cancel-subscription", {});

export const sendEvent = async (event: EventParams): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EventParams>("/users/send-event", event);

export const scheduleSubscriptionCancellation = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/schedule-cancellation", {});

export const restoreSubscription = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/release-subscription-cancellation", {});

export const cancelScheduledDowngrade = async (): Promise<APIResponse<EmptyObject>> =>
  api.post<EmptyObject, EmptyObject>("/users/cancel-scheduled-downgrade", {});

export const uniqueCustomerAverage = async (): Promise<APIResponse<{ uniqueCustomers: number }>> =>
  api.get("/users/unique-customer-average");

export const checkLimitsForPlan = async (planId: string): Promise<APIResponse<PlanLimitsCheckResult>> =>
  api.get(`/users/check-plan-limit/${planId}`);
