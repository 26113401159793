import { DateTime } from "luxon";
import { Call } from "../api/CallsApiNew";
import { AgentCustomer } from "../api/AgentApiNew";

/* Check if the project is in production or development */
const isProduction: boolean = process.env.REACT_APP_PROJECT_ID === "goodcall-production";

// Enum for plan names
export enum PlanName {
  STARTER = "starter",
  GROWTH = "growth",
  SCALE = "scale",
  PREMIUM = "premium",
  PRO = "pro",
}

// Existing legacy plans
export const legacyPlans = [PlanName.PRO, PlanName.PREMIUM];
// New plans
export const newPlans = [PlanName.STARTER, PlanName.GROWTH, PlanName.SCALE];
// Paid Plans
export const paidPlans = [...legacyPlans, ...newPlans];

/**
 * Check if the plan is a legacy plan.
 *
 * @param plan The plan name.
 * @returns Whether the plan is a legacy plan.
 */
export const isOnLegacyPlan = (plan: PlanName): boolean => legacyPlans.includes(plan);

/* Define the price IDs for the different plans */
type PriceId = {
  legacySoloPriceId: string;
  legacyProPriceId: string;
  legacyPremiumPriceId: string;
  legacyNewPremiumPriceId: string;
  legacyPremiumAnnualPriceId: string;
  stripe2024StarterMonthlyPriceId: string;
  stripe2024GrowthMonthlyPriceId: string;
  stripe2024ScaleMonthlyPriceId: string;
  stripe2024StarterAnnualPriceId: string;
  stripe2024GrowthAnnualPriceId: string;
  stripe2024ScaleAnnualPriceId: string;
};

/* Billing cycle */
export enum BillingCycle {
  MONTHLY = "monthly",
  ANNUAL = "annual",
}

/* Define the benefits for the different plans */
export type PlanBenefits = {
  locations: number;
  users: number;
  callsAllowed: number;
  knowledgeItems: number;
  directoryItems: number;
  forms: number;
  logic: number;
  /** False would be "unlimited" */
  performanceDetailMaxDays: number;
  /** False would be "unlimited" */
  uniqueCRMCustomers: number;
  /* Whether the plan is a legacy plan */
  isLegacy?: boolean;

  /* The maximum number of days for performance detail */
  maxDateFilterDays: number;
  /* The maximum number of tools */
  tools: number;
};

/* Define the display for the different plans */

type PlanBenefitsDisplay = {
  displayName: string;
  benefits: PlanBenefits;
  billingCycle: BillingCycle;
  priceId: string;
  price: number;
  purchasePrice: number;
  priceStr: string;
  purchasePriceDollarsCents: string;
  annualDiscountedPrice?: number;
  annualPriceWithoutDiscount?: number;
  annualDiscountedPriceStr?: string;
};

/* Define the price IDs for the different plans */
export const priceIds: Record<keyof PriceId, string> = isProduction
  ? {
      legacySoloPriceId: "price_1JPVvwEfEL5vpc7RhYHsPcnm",
      legacyProPriceId: "price_1JPVwuEfEL5vpc7RONfDqHtj",
      legacyPremiumPriceId: "price_1JPVxOEfEL5vpc7RKfKaOrOf",
      legacyNewPremiumPriceId: "price_1OCO55EfEL5vpc7Ro0j9Llo6",
      legacyPremiumAnnualPriceId: "price_1P96cxEfEL5vpc7RFRYbnui5",
      stripe2024StarterMonthlyPriceId: "price_1Pksr4EfEL5vpc7RmFk3fHIT",
      stripe2024GrowthMonthlyPriceId: "price_1PksrBEfEL5vpc7RzgcXzurp",
      stripe2024ScaleMonthlyPriceId: "price_1PksrEEfEL5vpc7RcU2hTKCJ",
      stripe2024StarterAnnualPriceId: "price_1Pksr4EfEL5vpc7RF3meDkNs",
      stripe2024GrowthAnnualPriceId: "price_1PksrBEfEL5vpc7RfenENBkt",
      stripe2024ScaleAnnualPriceId: "price_1PksrEEfEL5vpc7R1Bm68dij",
    }
  : {
      legacySoloPriceId: "price_1JCTGVEfEL5vpc7RLD9FpYU1",
      legacyProPriceId: "price_1JCTG1EfEL5vpc7RZfF8R0gk",
      legacyPremiumPriceId: "price_1JCTHGEfEL5vpc7RVG1GrUda",
      legacyNewPremiumPriceId: "price_1OCO1oEfEL5vpc7RrxUf9wEo",
      legacyPremiumAnnualPriceId: "price_1PDYyiEfEL5vpc7RP5XQV3Rb",
      stripe2024StarterMonthlyPriceId: "price_1PknR4EfEL5vpc7RXq8anIoE",
      stripe2024GrowthMonthlyPriceId: "price_1PlxozEfEL5vpc7R6yhrarYN",
      stripe2024ScaleMonthlyPriceId: "price_1Pko92EfEL5vpc7RGHeHkuDJ",
      stripe2024StarterAnnualPriceId: "price_1PknR4EfEL5vpc7Rbrf4K9Qw",
      stripe2024GrowthAnnualPriceId: "price_1PknSsEfEL5vpc7RSnq4f4gK",
      stripe2024ScaleAnnualPriceId: "price_1PlxzcEfEL5vpc7RgOW9WXA8",
    };

// Define the price IDs for the different plans
const annualPriceIds = [
  priceIds.legacyPremiumAnnualPriceId,
  priceIds.stripe2024StarterAnnualPriceId,
  priceIds.stripe2024GrowthAnnualPriceId,
  priceIds.stripe2024ScaleAnnualPriceId,
];

export const isAnnualPriceId = (priceId: string): boolean => annualPriceIds.includes(priceId);

/* Define the benefits for the different plans */
export const planBenefits: Record<keyof PriceId, PlanBenefits> = {
  legacySoloPriceId: {
    locations: 1,
    users: 3,
    callsAllowed: 60,
    knowledgeItems: 10,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 1,
    performanceDetailMaxDays: 7,
    uniqueCRMCustomers: 100,
    maxDateFilterDays: 7,
  },
  legacyProPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 16,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 1,
    performanceDetailMaxDays: 7,
    uniqueCRMCustomers: Number.MAX_SAFE_INTEGER,
    isLegacy: true,
    maxDateFilterDays: 7,
  },
  legacyPremiumPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 3,
    performanceDetailMaxDays: 30,
    uniqueCRMCustomers: Number.MAX_SAFE_INTEGER,
    isLegacy: true,
    maxDateFilterDays: 30,
  },
  legacyNewPremiumPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 3,
    performanceDetailMaxDays: 30,
    uniqueCRMCustomers: Number.MAX_SAFE_INTEGER,
    isLegacy: true,
    maxDateFilterDays: 30,
  },
  legacyPremiumAnnualPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 3,
    performanceDetailMaxDays: 30,
    uniqueCRMCustomers: Number.MAX_SAFE_INTEGER,
    isLegacy: true,
    maxDateFilterDays: 30,
  },
  stripe2024StarterMonthlyPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 1,
    performanceDetailMaxDays: 7,
    uniqueCRMCustomers: 100,
    maxDateFilterDays: 7,
  },
  stripe2024GrowthMonthlyPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 9,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 25,
    forms: 3,
    logic: 3,
    tools: 3,
    performanceDetailMaxDays: 30,
    uniqueCRMCustomers: 250,
    maxDateFilterDays: 30,
  },
  stripe2024ScaleMonthlyPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 50,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 500,
    forms: 25,
    logic: 25,
    tools: 25,
    performanceDetailMaxDays: Number.MAX_SAFE_INTEGER,
    uniqueCRMCustomers: 500,
    maxDateFilterDays: Number.MAX_SAFE_INTEGER,
  },
  stripe2024StarterAnnualPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 3,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 3,
    forms: 1,
    logic: 1,
    tools: 1,
    performanceDetailMaxDays: 7,
    uniqueCRMCustomers: 100,
    maxDateFilterDays: 7,
  },
  stripe2024GrowthAnnualPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 9,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 50,
    forms: 3,
    logic: 3,
    tools: 3,
    performanceDetailMaxDays: 30,
    uniqueCRMCustomers: 250,
    maxDateFilterDays: 30,
  },
  stripe2024ScaleAnnualPriceId: {
    locations: Number.MAX_SAFE_INTEGER,
    users: 50,
    callsAllowed: Number.MAX_SAFE_INTEGER,
    knowledgeItems: 50,
    directoryItems: 500,
    forms: 25,
    logic: 25,
    tools: 25,
    performanceDetailMaxDays: Number.MAX_SAFE_INTEGER,
    uniqueCRMCustomers: 500,
    maxDateFilterDays: Number.MAX_SAFE_INTEGER,
  },
};

/* Define the display for the different plans */
export const planBenefitsDisplay: Record<keyof PriceId, PlanBenefitsDisplay> = {
  legacySoloPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Free",
    benefits: planBenefits.legacySoloPriceId,
    priceId: priceIds.legacySoloPriceId,
    price: 0,
    purchasePrice: 0,
    priceStr: "$0",
    purchasePriceDollarsCents: "0.00",
  },
  legacyProPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Pro (monthly)",
    benefits: planBenefits.legacyProPriceId,
    priceId: priceIds.legacyProPriceId,
    price: 0,
    purchasePrice: 0,
    priceStr: "$0",
    purchasePriceDollarsCents: "0.00",
  },
  legacyPremiumPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Premium (monthly)",
    benefits: planBenefits.legacyPremiumPriceId,
    priceId: priceIds.legacyPremiumPriceId,
    price: 59,
    purchasePrice: 59,
    priceStr: "$59",
    purchasePriceDollarsCents: "59.00",
  },
  legacyNewPremiumPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Premium (monthly)",
    benefits: planBenefits.legacyNewPremiumPriceId,
    priceId: priceIds.legacyNewPremiumPriceId,
    price: 59,
    purchasePrice: 59,
    priceStr: "$59",
    purchasePriceDollarsCents: "59.00",
  },
  legacyPremiumAnnualPriceId: {
    billingCycle: BillingCycle.ANNUAL,
    displayName: "Premium (annual)",
    benefits: planBenefits.legacyPremiumAnnualPriceId,
    priceId: priceIds.legacyPremiumAnnualPriceId,
    price: 59,
    purchasePrice: 492,
    priceStr: "$59",
    annualDiscountedPrice: 41,
    annualDiscountedPriceStr: "$41",
    purchasePriceDollarsCents: "492.00",
  },
  stripe2024StarterMonthlyPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Starter (monthly)",
    benefits: planBenefits.stripe2024StarterMonthlyPriceId,
    priceId: priceIds.stripe2024StarterMonthlyPriceId,
    price: 59,
    purchasePrice: 59,
    priceStr: "$59",
    purchasePriceDollarsCents: "59.00",
  },
  stripe2024GrowthMonthlyPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Growth (monthly)",
    benefits: planBenefits.stripe2024GrowthMonthlyPriceId,
    priceId: priceIds.stripe2024GrowthMonthlyPriceId,
    price: 99,
    purchasePrice: 99,
    priceStr: "$99",
    purchasePriceDollarsCents: "99.00",
  },
  stripe2024ScaleMonthlyPriceId: {
    billingCycle: BillingCycle.MONTHLY,
    displayName: "Scale (monthly)",
    benefits: planBenefits.stripe2024ScaleMonthlyPriceId,
    priceId: priceIds.stripe2024ScaleMonthlyPriceId,
    price: 199,
    purchasePrice: 199,
    priceStr: "$199",
    purchasePriceDollarsCents: "199.00",
  },
  stripe2024StarterAnnualPriceId: {
    billingCycle: BillingCycle.ANNUAL,
    displayName: "Starter (annual)",
    benefits: planBenefits.stripe2024StarterAnnualPriceId,
    priceId: priceIds.stripe2024StarterAnnualPriceId,
    price: 59,
    purchasePrice: 492,
    priceStr: "$59",
    annualDiscountedPrice: 41,
    annualDiscountedPriceStr: "$41",
    purchasePriceDollarsCents: "492",
    annualPriceWithoutDiscount: 708,
  },
  stripe2024GrowthAnnualPriceId: {
    billingCycle: BillingCycle.ANNUAL,
    displayName: "Growth (annual)",
    benefits: planBenefits.stripe2024GrowthAnnualPriceId,
    priceId: priceIds.stripe2024GrowthAnnualPriceId,
    price: 99,
    purchasePrice: 828,
    priceStr: "$99",
    annualDiscountedPrice: 69,
    annualDiscountedPriceStr: "$69",
    purchasePriceDollarsCents: "828",
    annualPriceWithoutDiscount: 1188,
  },
  stripe2024ScaleAnnualPriceId: {
    billingCycle: BillingCycle.ANNUAL,
    displayName: "Scale (annual)",
    benefits: planBenefits.stripe2024ScaleAnnualPriceId,
    priceId: priceIds.stripe2024ScaleAnnualPriceId,
    price: 199,
    purchasePrice: 1668,
    priceStr: "$199",
    annualDiscountedPrice: 139,
    annualDiscountedPriceStr: "$139",
    purchasePriceDollarsCents: "1668",
    annualPriceWithoutDiscount: 2388,
  },
};

/* Get the plan benefits by name and billing cycle */
export const getPlanBenefitByName = (planName: PlanName, billingCycle: BillingCycle): PlanBenefitsDisplay => {
  switch (planName) {
    // Return Starter plan object based on the billing cycle
    case PlanName.STARTER:
      return billingCycle === BillingCycle.MONTHLY
        ? planBenefitsDisplay.stripe2024StarterMonthlyPriceId
        : planBenefitsDisplay.stripe2024StarterAnnualPriceId;

    // Return Growth plan object based on the billing cycle
    case PlanName.GROWTH:
      return billingCycle === BillingCycle.MONTHLY
        ? planBenefitsDisplay.stripe2024GrowthMonthlyPriceId
        : planBenefitsDisplay.stripe2024GrowthAnnualPriceId;

    // Return Scale plan object based on the billing cycle
    case PlanName.SCALE:
      return billingCycle === BillingCycle.MONTHLY
        ? planBenefitsDisplay.stripe2024ScaleMonthlyPriceId
        : planBenefitsDisplay.stripe2024ScaleAnnualPriceId;

    case PlanName.PREMIUM:
      return billingCycle === BillingCycle.MONTHLY
        ? planBenefitsDisplay.legacyNewPremiumPriceId
        : planBenefitsDisplay.legacyPremiumAnnualPriceId;

    // Return Solo plan object based on the billing cycle
    default:
      return planBenefitsDisplay.legacySoloPriceId;
  }
};

export const getPlanBenefitByPriceId = (priceId: string): PlanBenefitsDisplay => {
  switch (priceId) {
    case priceIds.stripe2024StarterMonthlyPriceId:
      return planBenefitsDisplay.stripe2024StarterMonthlyPriceId;
    case priceIds.stripe2024GrowthMonthlyPriceId:
      return planBenefitsDisplay.stripe2024GrowthMonthlyPriceId;
    case priceIds.stripe2024ScaleMonthlyPriceId:
      return planBenefitsDisplay.stripe2024ScaleMonthlyPriceId;
    case priceIds.stripe2024StarterAnnualPriceId:
      return planBenefitsDisplay.stripe2024StarterAnnualPriceId;
    case priceIds.stripe2024GrowthAnnualPriceId:
      return planBenefitsDisplay.stripe2024GrowthAnnualPriceId;
    case priceIds.stripe2024ScaleAnnualPriceId:
      return planBenefitsDisplay.stripe2024ScaleAnnualPriceId;
    case priceIds.legacySoloPriceId:
      return planBenefitsDisplay.legacySoloPriceId;
    case priceIds.legacyProPriceId:
      return planBenefitsDisplay.legacyProPriceId;
    case priceIds.legacyPremiumPriceId:
      return planBenefitsDisplay.legacyPremiumPriceId;
    case priceIds.legacyNewPremiumPriceId:
      return planBenefitsDisplay.legacyNewPremiumPriceId;
    case priceIds.legacyPremiumAnnualPriceId:
      return planBenefitsDisplay.legacyPremiumAnnualPriceId;
    default:
      return planBenefitsDisplay.legacySoloPriceId;
  }
};

export const getPlanNameAndCycleByPriceId = (priceId: string): { planName: PlanName; billingCycle: BillingCycle } => {
  switch (priceId) {
    case priceIds.stripe2024StarterMonthlyPriceId:
    case priceIds.stripe2024StarterAnnualPriceId:
      return {
        planName: PlanName.STARTER,
        billingCycle: priceId === priceIds.stripe2024StarterMonthlyPriceId ? BillingCycle.MONTHLY : BillingCycle.ANNUAL,
      };
    case priceIds.stripe2024GrowthMonthlyPriceId:
    case priceIds.stripe2024GrowthAnnualPriceId:
      return {
        planName: PlanName.GROWTH,
        billingCycle: priceId === priceIds.stripe2024GrowthMonthlyPriceId ? BillingCycle.MONTHLY : BillingCycle.ANNUAL,
      };
    case priceIds.stripe2024ScaleMonthlyPriceId:
    case priceIds.stripe2024ScaleAnnualPriceId:
      return {
        planName: PlanName.SCALE,
        billingCycle: priceId === priceIds.stripe2024ScaleMonthlyPriceId ? BillingCycle.MONTHLY : BillingCycle.ANNUAL,
      };
    case priceIds.legacyNewPremiumPriceId:
    case priceIds.legacyPremiumAnnualPriceId:
      return {
        planName: PlanName.PREMIUM,
        billingCycle: priceId === priceIds.legacyNewPremiumPriceId ? BillingCycle.MONTHLY : BillingCycle.ANNUAL,
      };
    default:
      return { planName: PlanName.STARTER, billingCycle: BillingCycle.MONTHLY };
  }
};

export const getPriceIdByPlanNameAndCycle = (planName: PlanName, billingCycle: BillingCycle): string => {
  switch (planName) {
    case PlanName.STARTER:
      return billingCycle === BillingCycle.MONTHLY
        ? priceIds.stripe2024StarterMonthlyPriceId
        : priceIds.stripe2024StarterAnnualPriceId;
    case PlanName.GROWTH:
      return billingCycle === BillingCycle.MONTHLY
        ? priceIds.stripe2024GrowthMonthlyPriceId
        : priceIds.stripe2024GrowthAnnualPriceId;
    case PlanName.SCALE:
      return billingCycle === BillingCycle.MONTHLY
        ? priceIds.stripe2024ScaleMonthlyPriceId
        : priceIds.stripe2024ScaleAnnualPriceId;
    case PlanName.PREMIUM:
      return billingCycle === BillingCycle.MONTHLY
        ? priceIds.legacyNewPremiumPriceId
        : priceIds.legacyPremiumAnnualPriceId;
    default:
      return priceIds.stripe2024StarterMonthlyPriceId;
  }
};

type PriceDetailsWithDiscount = {
  originalPrice: number;
  discountedPrice: number;
  discountedPriceStr: string;
  savedAmount: number;
  savedAmountString: string;
  period: string;
};

type PriceDetailsParams = { discount: number } & ({ plan: PlanName; cycle: BillingCycle } | { priceId: string });

export const getPriceDetailsWithDiscount = (params: PriceDetailsParams): PriceDetailsWithDiscount => {
  const { discount } = params;
  const planBenefit =
    "priceId" in params ? getPlanBenefitByPriceId(params.priceId) : getPlanBenefitByName(params.plan, params.cycle);
  const cycle = "cycle" in params ? params.cycle : getPlanNameAndCycleByPriceId(params.priceId).billingCycle;
  if (planBenefit?.price) {
    const multiplier = cycle === BillingCycle.MONTHLY ? 1 : 12;
    const priceToUse = cycle === BillingCycle.MONTHLY ? planBenefit.price : planBenefit.annualDiscountedPrice || 0;
    const originalPrice = priceToUse * multiplier;
    const discountedPrice = originalPrice - (originalPrice * discount) / 100;
    const savedAmount = originalPrice - discountedPrice;

    return {
      originalPrice,
      savedAmount,
      discountedPrice,
      discountedPriceStr: discountedPrice.toLocaleString("en-US"),
      savedAmountString: discountedPrice.toLocaleString("en-US"),
      period: cycle === BillingCycle.MONTHLY ? "mo" : "yr",
    };
  }

  return {
    originalPrice: 0,
    discountedPrice: 0,
    savedAmount: 0,
    discountedPriceStr: "0.00",
    savedAmountString: "0.00",
    period: "",
  };
};

export const enabledChangePlans: Record<PlanName, Record<BillingCycle, PlanName[]>> = {
  [PlanName.STARTER]: {
    [BillingCycle.MONTHLY]: [PlanName.GROWTH, PlanName.SCALE],
    [BillingCycle.ANNUAL]: [PlanName.STARTER, PlanName.GROWTH, PlanName.SCALE],
  },
  [PlanName.GROWTH]: {
    [BillingCycle.MONTHLY]: [PlanName.SCALE],
    [BillingCycle.ANNUAL]: [PlanName.GROWTH, PlanName.SCALE],
  },
  [PlanName.SCALE]: {
    [BillingCycle.MONTHLY]: [],
    [BillingCycle.ANNUAL]: [PlanName.SCALE],
  },
  [PlanName.PREMIUM]: {
    [BillingCycle.MONTHLY]: [PlanName.GROWTH, PlanName.SCALE],
    [BillingCycle.ANNUAL]: [PlanName.STARTER, PlanName.GROWTH, PlanName.SCALE],
  },
  [PlanName.PRO]: {
    [BillingCycle.MONTHLY]: [PlanName.GROWTH, PlanName.SCALE],
    [BillingCycle.ANNUAL]: [PlanName.STARTER, PlanName.GROWTH, PlanName.SCALE],
  },
};

/**
 * Get the label for the plan, properly capitalized.
 *
 * @param plan The plan name attached to the user.
 * @param isDemoMode Whether the user is in demo mode.
 * @returns The label for the plan, properly capitalized.
 */
const getPlanLabel = (plan: "solo" | PlanName, isDemoMode: boolean): string => {
  let label = plan;

  if (isDemoMode) {
    label = PlanName.STARTER;
  }

  return label.charAt(0).toUpperCase() + label.slice(1);
};

export const isWithinPlanLimit = (data: Call | AgentCustomer, priceId: string): boolean => {
  const { performanceDetailMaxDays } = getPlanBenefitByPriceId(priceId).benefits;

  if (performanceDetailMaxDays === Number.MAX_SAFE_INTEGER) {
    return true;
  }
  /* Get the last available date */
  const lastDay = DateTime.now().minus({ days: performanceDetailMaxDays });

  if ("callTimestamp" in data) {
    return new Date(data.callTimestamp) > lastDay.toJSDate();
  }
  if ("lastCallDate" in data && data?.lastCallDate) {
    return new Date(data.lastCallDate) > lastDay.toJSDate();
  }

  return true;
};

export const dateFormatByCycle = (cycle: BillingCycle): string =>
  cycle === BillingCycle.MONTHLY ? "LLL dd" : "LLL dd yyyy";
export default {
  getPlanLabel,
  isOnLegacyPlan,
  getPlanBenefitByName,
  getPlanBenefitByPriceId,
  getPriceDetailsWithDiscount,
};
