import React, { ReactNode } from "react";
import "./sellingPointGroup.scss";

export type IconColor = "base-cyan" | "base-dark-blue";

type Props = {
  children: ReactNode;
  iconColor?: IconColor;
};

const SellingPointGroup = ({ children, iconColor = "base-cyan" }: Props): JSX.Element => (
  <div className={`conversight selling-point-group-${iconColor}`}>{children}</div>
);

SellingPointGroup.defaultProps = { iconColor: "base-cyan" };

export default SellingPointGroup;
