import {
  MetadataParam,
  PaymentIntentResult,
  PaymentMethodCreateParams,
  SetupIntentResult,
  Stripe,
  StripeElements,
  StripeCardElement,
} from "@stripe/stripe-js";

import { ActiveUser } from "../../app/StoreTypes";

/**
 * The type of intent.
 */
export enum IntentType {
  Setup = "setup",
  Payment = "payment",
}

/**
 * The source of the payment information.
 */
export enum Source {
  Card = "card",
  Payment = "payment",
}

/**
 * The payload being sent to Stripe when confirming a card setup or payment.
 */
type ConfirmCardPayload = {
  secret: string;
  stripe: Stripe;
  data: {
    payment_method: {
      card: StripeCardElement;
      billing_details: PaymentMethodCreateParams.BillingDetails;
      metadata: MetadataParam;
    };
    return_url: string;
  };
};

// Convenience objects.
const setupIntent = { type: IntentType.Setup } as const;
const paymentIntent = { type: IntentType.Payment } as const;

/**
 * Result of a stripe confirm function call, with a discriminator.
 */
type ConfirmResult = (typeof setupIntent & SetupIntentResult) | (typeof paymentIntent & PaymentIntentResult);

/**
 * Confirm a card setup intent.
 */
const confirmCardSetup = async ({ secret, stripe, data }: ConfirmCardPayload): Promise<ConfirmResult> => {
  const result = await stripe.confirmCardSetup(secret, data);
  return { ...setupIntent, ...result };
};

/**
 * Confirm a card payment intent.
 */
const confirmCardPayment = async ({ secret, stripe, data }: ConfirmCardPayload): Promise<ConfirmResult> => {
  const result = await stripe.confirmCardPayment(secret, data);
  return { ...paymentIntent, ...result };
};

/**
 * The arguments for the confirmCard method.
 */
type ConfirmCard = {
  type: IntentType;
  secret: string;
  stripe: Stripe;
  elements: StripeElements;
  returnUrl: string;
  metadata: MetadataParam;
  billingDetails: PaymentMethodCreateParams.BillingDetails;
};

// Confirm a card, whether setup or payment.
const confirmCard = async ({
  type,
  secret,
  stripe,
  elements,
  returnUrl,
  metadata,
  billingDetails,
}: ConfirmCard): Promise<ConfirmResult> => {
  // Attempt to confirm the payment method.
  // Grab the card from the card element.
  const card = elements.getElement("card");

  // If we can't get a card, we can't submit it.
  if (!card) {
    throw new Error("Unable to get card information");
  }

  // Construct the confirmation payload.
  const payload = {
    secret,
    stripe,
    data: {
      return_url: returnUrl,
      payment_method: {
        card,
        metadata,
        billing_details: billingDetails,
      },
    },
  };

  if (type === IntentType.Setup) {
    return confirmCardSetup(payload);
  }

  return confirmCardPayment(payload);
};

/**
 * A function for confirming a given intent.
 * Returns the payment method id, if successful.
 */
type ConfirmIntentFunction = (args: {
  type: IntentType;
  source: Source;
  stripe: Stripe | null;
  elements: StripeElements | null;
  activeUser: false | ActiveUser;
  returnUrl: string;
  secret: string | undefined;
}) => Promise<string>;

/**
 * Helper method for finishing the setup or payment.
 * Returns the payment method id, if successful.
 */
export const confirmIntent: ConfirmIntentFunction = async ({
  type,
  source,
  stripe,
  elements,
  activeUser,
  returnUrl,
  secret,
}) => {
  // Stripe.js has not yet loaded.
  if (!stripe || !elements || !secret) {
    throw new Error("Unable to setup payment method");
  }

  // If we can't find the user, we can't submit it.
  if (!activeUser) {
    throw new Error("Unable to get user information");
  }

  // Get the user's additional billing details.
  const billingDetails: PaymentMethodCreateParams.BillingDetails = {
    name: `${activeUser.firstName} ${activeUser.lastName}`,
    email: activeUser.email,
    phone: activeUser.phoneNumber,
    address: {
      country: "US",
    },
  };

  // Setup the card metadata.
  const metadata = { userId: activeUser.id, environment: process.env.REACT_APP_PROJECT_ID ?? "" };

  // Attempt to confirm the payment method.
  // Grab the card from the card element.
  const card = source === Source.Card ? elements.getElement("card") : elements.getElement("payment");

  // If we can't get a card, we can't submit it.
  if (!card) {
    throw new Error("Unable to get card information");
  }

  // Construct the params for the card.
  const cardParams = {
    type,
    secret,
    stripe,
    elements,
    returnUrl,
    metadata,
    billingDetails,
  };

  // Construct the params for the payment.
  const paymentParams = {
    elements,
    confirmParams: {
      return_url: returnUrl,
      payment_method_data: {
        billing_details: billingDetails,
      },
    },
  };

  // Try to confirm the card setup or payment.
  const result = await (source === Source.Card ? confirmCard(cardParams) : stripe.confirmPayment(paymentParams));

  // Check if we encountered any errors.
  if (result.error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    // Just bubble the error up.
    throw new Error(result.error.message);
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.

    // Extract the payment method from the intent result.
    const intent = result.type === IntentType.Setup ? result.setupIntent : result.paymentIntent;
    const paymentMethod = intent.payment_method;

    // If we didn't get a payment method back, something probably went wrong.
    if (paymentMethod === null) {
      throw new Error("Unable to find payment method");
    }

    // Return the payment method id to the caller, in case they want it.
    return typeof paymentMethod === "string" ? paymentMethod : paymentMethod.id;
  }
};

export default {
  confirmIntent,
};
