import React from "react";

// This is a horrible hack to force the css to load in the correct order.
// The history here makes me sad. As it turns out, somehow, in the course
// of a ragingly fast development schedule of the initial app, we set out
// with a structure that loaded _our_ main css _before_ any of the other
// styles. This is terrible. It means that they overwrite our styles when
// in conflict. Through a weird quirk of how webpack works, a recent change
// to integrate the marketing pages led to an even worse problem, which was
// the order being different when running it locally in dev mode (via
// `npm start`), than the order when a production build is made (via
// `npm run build`). This leads to all sorts of peculiar artifacts, like
// buttons with just mysteriously different sizes in production that we
// can't reproduce locally. Fun stuff, really. This forces the correct
// order in both places, as far as I can tell.
//
// At some point, we should undo this, and preferably, use the _correct_
// order for loading these styles. Which is to do bootstrap first, and
// _then_ our styles. We'll need some time to make sure this doesn't break
// things though and fix the issues that do crop up. We are a few days
// from launch, and certainly don't have time to fix it now. Dear future
// developer, I do hope you are able to delete this late night rambling
// nonsense in the near future when the world is a better place.
//
// Best of luck.

(async function () {
  await import("./styles/App.scss");

  await import("bootstrap/dist/css/bootstrap.min.css");
  await import("bootstrap/dist/js/bootstrap.bundle.min");

  await import("react-phone-input-2/lib/bootstrap.css");
  await import("react-toggle/style.css"); // for ES6 modules
})();

const PageStyles = () => {
  // Doesn't do anything, just loads the styles for us.
  return <></>;
};

export default PageStyles;
