import React, { useRef, useState } from "react";
import "./agentWithHint.scss";
import Avatar from "../../atoms/agents/Avatar";
import { CharacterId, Size } from "../../atoms/agents/types";
import AgentTooltip from "../AgentTooltip/AgentTooltip";

// Define the Props type for the component
type Props = {
  /*  Flag to indicate whether the Tooltip should be visible or not */
  onTapTooltip?: boolean;
  /*  Id for the Avatar component */
  characterId: CharacterId;
  /* Size for the Avatar component */
  size: Size;
  /* Children elements to be displayed in the Tooltip */
  children: React.ReactNode;
  /*  Flag to indicate whether the Avatar should be circular or not */
  circle?: boolean;
};

// Component to render the Avatar with a Tooltip
const AgentWithHint: React.FC<Props> = ({ characterId, size, circle = false, children, onTapTooltip }) => {
  // Use a reference for the parent container element
  const ref = useRef<HTMLInputElement>(null);

  // Use state to track the visibility of the Tooltip
  const [tooltip, showTooltip] = useState<boolean>(false);

  // Detect if user clicked anywhere outside the parent container
  // and hide the Tooltip if so
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      showTooltip(false);
    }
  };

  // Remove event listener when component unmounts
  React.useEffect(() => {
    // Add event listener to detect clicks outside the parent container
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="conversight agent-with-hint-container" ref={ref}>
      {/* Render the Tooltip component and pass the reference to the parent container and the visibility state */}
      {onTapTooltip && (
        <AgentTooltip target={ref} setTooltip={showTooltip} showTooltip={tooltip}>
          {children}
        </AgentTooltip>
      )}
      {/* Render the Avatar component and pass the required props */}
      <Avatar characterId={characterId} onClick={() => showTooltip(!tooltip)} size={size} circle={circle}>
        <div className="callout-container">
          <div className="body-1-light">{children}</div>
          <div className="speak-arrow" />
        </div>
      </Avatar>
    </div>
  );
};

// Set default props for the component
AgentWithHint.defaultProps = {
  circle: false,
  onTapTooltip: false,
};

// Export the component
export default AgentWithHint;
