import React from "react";

import { Close } from "@mui/icons-material";

import IconButton from "./IconButton";

export type Props = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: Props): JSX.Element => (
  <IconButton type="tertiary dark" size="medium" onClick={onClick}>
    <Close />
  </IconButton>
);

export default CloseButton;
