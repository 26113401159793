module.exports = (knowledgeData = []) => {
  const capitalize = (string) => string && string[0].toUpperCase() + string.slice(1);

  const behaviorIsConfiguredForConnect = (behavior) =>
    behavior?.behaviorType === "connect" && typeof behavior?.transferToContactId !== "string";

  let knowledgeNames = [];
  for (const knowledge in knowledgeData) {
    const data = knowledgeData[knowledge];

    let connectEnabled = false;

    if (data?.behavior && behaviorIsConfiguredForConnect(data.behavior)) {
      connectEnabled = true;
    }

    if (data?.available && behaviorIsConfiguredForConnect(data.available)) {
      connectEnabled = true;
    }

    if (data?.unavailable && behaviorIsConfiguredForConnect(data.unavailable)) {
      connectEnabled = true;
    }

    const greetingRingsBusiness =
      knowledge === "greeting" &&
      ((data?.alwaysAnswer !== undefined && data?.alwaysAnswer !== true) ||
        (data?.ringTime !== undefined && data?.ringTime !== 0));

    if (connectEnabled || greetingRingsBusiness) {
      knowledgeNames.push(capitalize(knowledge.replace("-", " ")));
    }
  }

  return knowledgeNames;
};
