import React from "react";
import "./actionArea.scss";
import { Padding } from "../../atoms/types";

type BackgroundColor = "grey" | "white";

interface Props {
  /** The children to display in the action area */
  children: React.ReactNode;

  /** The background color of the action area */
  backgroundColor?: BackgroundColor;

  padding?: Padding;

  noMinWidth?: boolean;
}

const ActionArea: React.FC<Props> = ({ children, backgroundColor, padding, noMinWidth }) => (
  <div
    className={`action-area action-area-padding-${padding || "medium-large"} ${
      noMinWidth ? "" : "action-area-min-width"
    } bg-${backgroundColor || ""}`}
  >
    {children}
  </div>
);

// Default props for that button
ActionArea.defaultProps = {
  backgroundColor: "grey",
  padding: "medium-large",
  noMinWidth: false,
};

export default ActionArea;
