export const characters = [
  { id: "1", name: "hero" },
  { id: "2", name: "rosie" },
  { id: "3", name: "bella" },
  { id: "4", name: "alex" },
  { id: "5", name: "johnny" },
  { id: "6", name: "graham" },
] as const;

export const defaultCharacter = characters[2];

export type Size = "xxx-small" | "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large";

export type CharacterId = (typeof characters)[number]["id"];
export type Name = (typeof characters)[number]["name"];
