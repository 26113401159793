import React from "react";

import { ArrowBack } from "@mui/icons-material";

import IconButton from "./IconButton";

export type Props = {
  onClick: () => void;
};

const BackButton = ({ onClick }: Props): JSX.Element => (
  <IconButton type="tertiary dark" size="medium" onClick={onClick}>
    <ArrowBack />
  </IconButton>
);

export default BackButton;
