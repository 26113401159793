import { NOT_ALLOWED_CHARACTERS } from "../config/constants";

const sanitizeSkillConfig = (message: string): string => {
  if (message) {
    // NOT_ALLOWED_CHARACTERS includes chars that are not allowed in the skill config like |
    const sanitized = message.replace(new RegExp(NOT_ALLOWED_CHARACTERS.join("|"), "g"), "").replace(/\s+/g, " ");

    return sanitized;
  }
  return "";
};

export default sanitizeSkillConfig;
