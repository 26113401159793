import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback";

type Props = {
  tag: string;
  children: React.ReactNode;
};

const CustomBoundary: React.FC<Props> = ({ children, tag }) => (
  // const routeError = useRouteError();
  <ErrorBoundary
    // eslint-disable-next-line react/no-unstable-nested-components
    fallbackRender={({ error, resetErrorBoundary }) => (
      <ErrorBoundaryFallback error={error as Error} resetErrorBoundary={resetErrorBoundary} tag={tag} />
    )}
  >
    {children}
  </ErrorBoundary>
);
export default CustomBoundary;
