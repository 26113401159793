import React from "react";

import CloseButton from "../buttons/CloseButton";

import "./closeButton.scss";

export type Props = {
  onClick: () => void;
};

const ModalCloseButton = ({ onClick }: Props): JSX.Element => (
  <div className="conversight modal-close-button">
    <CloseButton onClick={onClick} />
  </div>
);

export default ModalCloseButton;
