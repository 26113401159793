const { toast } = require("react-toastify");
const { formatErrorForDisplay } = require(".");
const { getAgents } = require("../api/AgentApi");
const { setAvailableAgents } = require("../app/slices/agentSlice");

async function refreshAvailableAgents(dispatch) {
  let agents = await getAgents();
  if (agents.error) {
    toast.error(formatErrorForDisplay(agents.error));
  } else {
    dispatch(setAvailableAgents(agents?.data?.agents || []));
  }
  return agents;
}

export default refreshAvailableAgents;
