// The types of possible errors we know of.
type UnknownError =
  | unknown
  | Error
  | string
  | { error?: string | unknown; errors?: string[] | unknown; message?: string | unknown; name?: string | unknown };

/**
 * Attempt to format an error for display.
 * @param error The error to try to format.
 * @returns A string described the error.
 */
const formatErrorForDisplay = (error: UnknownError, fallbackMessage = "An unknown error has ocurred."): string => {
  // Simple string check.
  if (typeof error === "string") {
    return error;
  }

  // Handle if this error is an error.
  if (error instanceof Error) {
    return error.message;
  }

  // Check if the error is an object.
  if (error && typeof error === "object") {
    // If the error has an array of errors that are strings, just return the first one.
    if ("errors" in error && Array.isArray(error.errors) && error.errors.length > 0) {
      if (typeof error.errors[0] === "string") {
        return error.errors[0];
      }
    }

    // If the error has an error string on it, return that.
    if ("error" in error && typeof error.error === "string") {
      return error.error;
    }

    // If it has a message on it, return that.
    if ("message" in error && typeof error.message === "string") {
      return error.message;
    }

    // Or return the name if it has one.
    if ("name" in error && typeof error.name === "string") {
      return error.name;
    }
  }

  // If all else fails, return unknown error and log that it happened.
  // eslint-disable-next-line no-console
  console.warn("Unable to identify error", error);
  return fallbackMessage;
};

export default formatErrorForDisplay;
