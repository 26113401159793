import { useMediaQuery } from "react-responsive";

type Breakpoint = "mobile" | "tablet" | "desktop" | "xsmall" | "small" | "large" | "medium" | "xMobile";

const queries: Record<Breakpoint, Parameters<typeof useMediaQuery>[0]> = {
  mobile: { maxWidth: "576px" },
  medium: { maxWidth: "990px" },
  xMobile: { minWidth: "991px" },
  tablet: { maxWidth: "1199px" },
  desktop: { minWidth: "1200px" },
  xsmall: { maxWidth: "390px" },
  small: { maxWidth: "901px" },
  large: { minWidth: "901px" },
};

const useResponsive = (breakpoint: Breakpoint) => {
  let query;
  query = queries[breakpoint];

  if (!query) {
    query = { maxWidth: breakpoint };
  }

  return useMediaQuery({ type: "screen", ...query });
};

export default useResponsive;
