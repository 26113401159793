import React from "react";
import Text from "../typography/Text";

/**
 * Props for the NavItem component
 */
type Props = {
  /** The label to display on the tab. */
  children: string;

  /** Whether the tab should be hidden. */
  hidden?: boolean;

  isActive: boolean;
};

/**
 * A tab for the NavTabs component.
 * @param param The props for the NavItem component.
 * @returns A NavItem component.
 */

const defaultProps = {
  hidden: false,
};
const NavItem = ({ children, hidden, isActive }: Props): JSX.Element => {
  if (hidden) {
    return <> </>;
  }
  return (
    <div className="conversight cs-nav-item">
      <div className={isActive ? "active" : ""}>
        <Text type="lead" color={isActive ? "base-blue" : "base-black"} weight={isActive ? "bold" : "regular"}>
          {children}
        </Text>
      </div>
    </div>
  );
};

NavItem.defaultProps = defaultProps;

export default NavItem;
