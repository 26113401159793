import React from "react";
import "./Spinner.scss";

type Props = {
  size?: "auto" | "large";
};
const Spinner: React.FC<Props> = ({ size }) => (
  <div className={`conversight spinner-container ${size || ""}`}>
    <div className="spinner" />
  </div>
);

Spinner.defaultProps = {
  size: "large",
};

export default Spinner;
