import { capitalize } from "lodash";
import React from "react";
import "./avatar.scss";
import { characters, defaultCharacter, CharacterId, Name, Size } from "./types";

interface CommonProps {
  /** The id of the character tou use for this avatar */
  characterId?: CharacterId;
  /** The name of the character to use for this avatar */
  name?: Name;
  /** The size of the avatar to display */
  size: Size;
  /** If the avatar should have a circle background */
  circle?: boolean;
  /** React element that we want to render inside */
  children?: React.ReactNode;
  /** Should show the name under the agent */
  showName?: boolean;
  /** Weather this avatar is selected or not */
  selected?: boolean;
  /** Use animated avatar */
  animated?: boolean;
  /** If avatar is clicked */
  onClick?: (characterId: CharacterId) => void;
}

type PropsWithName = CommonProps & { name: Name };
type PropsWithId = CommonProps & { characterId: CharacterId };
type Props = PropsWithId | PropsWithName;

const Avatar: React.FC<Props> = ({
  characterId,
  name,
  size,
  circle,
  children,
  onClick,
  showName,
  selected,
  animated,
}) => {
  // Find the character using either the id or name in our list.
  // With the way the ids and name types are constructed, we should
  // never _not_ find a character, but just to be safe we provide a
  // default fallback of the character in the list which is our default.
  const character = characters.find((c) => c.id === characterId || c.name === name) ?? defaultCharacter;

  // For performance sake, We'll generate diffrent size for each image. In order not to load the full image.
  const pathUrl = `/assets/media/images/agents/`;
  const sizeImageToUse = size === "xx-small" || size === "xxx-small" ? "x-small" : size;
  let circlePath = `${pathUrl}${character.id}-${sizeImageToUse}.png`;
  if (animated) {
    circlePath = `${pathUrl}${character.id}-${sizeImageToUse}`;
  }
  const avatarClass = `avatar ${circle ? "circle" : ""} agent-${character.id} ${selected ? "selected" : ""} ${size}`;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`agent-container ${showName ? "show-name" : ""}`}
      role="button"
      tabIndex={0}
      onClick={() => onClick && onClick(character.id)}
    >
      {animated ? (
        <video
          className={avatarClass}
          controls={false}
          autoPlay
          loop
          muted
          playsInline
          disablePictureInPicture
          disableRemotePlayback
        >
          <source src={`${circlePath}.webm`} type="video/webm" />
          <source src={`${circlePath}.mp4`} type="video/mp4" />
        </video>
      ) : (
        <img src={circlePath} className={avatarClass} alt={character.name} />
      )}
      {showName && <div className="h5-regular agent-name">{capitalize(character.name)}</div>}
      {children}
    </div>
  );
};

export default Avatar;
