import formatErrorForDisplay from "../helpers/formatErrorForDisplay";
import { get, patch, post, deleteRequest } from "./APIUtils";

export async function getAgents() {
  return await get("/agents");
}
export async function createAgent(data) {
  return await post("/agents", data);
}

export async function getAgent(agentId) {
  return await get(`/agents/${agentId}`);
}

export async function getAgentInvitationCode(agentId) {
  return await get(`/agents/${agentId}/invite`);
}
export async function sendAgentPhoneNumber(agentId) {
  return await post(`/agents/${agentId}/send-agent-phone-number`, {});
}

export async function acceptAgentInvitation(invitationCode) {
  return await post(`/agents/invite/accept`, { invitationCode });
}

export async function updateAgent({ data, agentId }) {
  const agent = await patch(`/agents/${agentId}`, data);
  if (agent.error) {
    throw new Error(formatErrorForDisplay(agent.error));
  }
  return agent.data;
}

export async function revokeUserAccess(userId, agentId) {
  return await deleteRequest(`/agents/${agentId}/${userId}`);
}

export async function deleteAgent(agentId) {
  return await deleteRequest(`/agents/${agentId}`);
}
