import React from "react";

import ActionArea from "../../molecules/ActionArea";
import AgentWithHint from "../../molecules/AgentWithHint/AgentWithHint";

import { CharacterId } from "../../atoms/agents/types";

import "./agentGuide.scss";
import ModalBackButton from "../../atoms/modals/ModalBackButton";
import Title from "../../atoms/modals/Title";
import ProgressBar, { ProgressBarProps } from "../../atoms/progress/ProgressBar";
import SellingPointGroup, { IconColor } from "../../atoms/SellingPointGroup";
import useResponsive from "../../hooks/useResponsive";
import Flex from "../../atoms/flex/Flex";

export type Size = "small" | "medium" | "large" | "x-large";

// Default props for that button
const defaultProps = {
  backButton: false,
  sellingPoints: <> </>,
  sellingPointIconColor: "base-cyan",
  progress: false as false | ProgressBarProps,
  flourish: <> </>,
  customAction: false,
  title: "",
  titleIcon: <> </>,
  size: "large",
  titleIconPosition: "right",
  onBack: () => {},
};

export type Props = {
  characterId: CharacterId;
  agentHint: string;
  children: React.ReactNode;
  /** Setting to true will not wrap children in an ActionArea - instead, children will render without any wrapper */
  customAction?: boolean;
  backButton?: boolean;
  progress?: ProgressBarProps;
  flourish?: JSX.Element;
  sellingPoints?: JSX.Element;
  sellingPointIconColor?: IconColor;
  title?: string;
  titleIcon?: JSX.Element;
  titleIconPosition?: "left" | "right";
  size?: Size;
  onBack?: () => void;
} & typeof defaultProps;

const AgentGuide = ({
  characterId,
  agentHint,
  children,
  backButton,
  sellingPoints,
  sellingPointIconColor,
  customAction,
  progress,
  flourish,
  title,
  titleIcon,
  titleIconPosition,
  onBack,
  size,
}: Props): JSX.Element => {
  const isMedium = useResponsive("medium");

  return (
    <div className={`conversight agent-guide ${size}`}>
      {progress && <ProgressBar {...progress} abbreviated={isMedium} />}
      <div className="agent-guide-container">
        <div className="agent-guide-meta">
          <div className="agent-guide-heading">
            {backButton && <ModalBackButton onClick={onBack} />}
            {title && (
              <Title size={isMedium ? "5" : "3"}>
                <Flex direction="row" align="center" gap="small">
                  {titleIcon && titleIconPosition === "left" && titleIcon}
                  {title}
                  {titleIcon && titleIconPosition === "right" && titleIcon}
                </Flex>{" "}
              </Title>
            )}
            {flourish.type !== React.Fragment ? <div className="flourish">{flourish}</div> : null}
            {sellingPoints && (
              <div className="selling-points">
                <SellingPointGroup iconColor={sellingPointIconColor}>
                  {sellingPoints && sellingPoints}{" "}
                </SellingPointGroup>
              </div>
            )}
          </div>
          {agentHint && (
            <AgentWithHint characterId={characterId} size={isMedium ? "x-small" : "x-large"} circle={isMedium}>
              {agentHint}
            </AgentWithHint>
          )}
        </div>
        <div className={`conversight agent-guide-content ${customAction ? "custom-action" : ""}`}>
          {customAction ? children : <ActionArea>{children}</ActionArea>}
        </div>
      </div>
    </div>
  );
};

AgentGuide.defaultProps = defaultProps;

export default AgentGuide;
