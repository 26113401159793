import axios from "axios";
import hotjar from "../helpers/hotjar";
const { REACT_APP_API_URL } = process.env;

export const apiUrl = REACT_APP_API_URL
  ? REACT_APP_API_URL
  : `${window.location.protocol}//api.${window.location.host}/v0`;

const authErrors = [
  "The decoded token was missing expected keys",
  "Token has been invalidated",
  "No token was decoded",
  "User not found",
  "No auth token",
  "jwt expired",
];

export async function resolve(promise, redirectOnAuthFailure = true) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    const response = await promise;
    const csrfToken = response.headers["x-csrf-token"];
    axios.defaults.headers.common["x-csrf-token"] = csrfToken;
    resolved.data = response.data;
  } catch (e) {
    if (e.response && e.response.status === 401) {
      const data = e.response.data;

      if (typeof data === "object" && Array.isArray(data.errors) && data.errors.length > 0) {
        const error = data.errors[0];
        if (redirectOnAuthFailure && authErrors.includes(error)) {
          localStorage.setItem("loggedIn", false);
          window.location.replace(`/login?redirect=${window.location.pathname}`);
        }
      }
    } else if (e.response && e.response.status === 429) {
      hotjar.sendEvent("RATE_LIMIT");
      resolved.error = new Error("Too many requests. Please try again later.");
      return resolved;
    }

    if (e.response && e.response.data) {
      resolved.error = e.response.data;
    } else {
      resolved.error = e;
    }
  }

  return resolved;
}

function defaultOptions() {
  return {
    withCredentials: true,
  };
}

async function ensureCSRFToken() {
  if (axios.defaults.headers.common["x-csrf-token"] !== undefined) {
    return Promise.resolve();
  }

  await resolve(axios.get(`${apiUrl}/csrf`, defaultOptions()));
}

export async function get(path, options = {}, redirectOnAuthFailure = true) {
  await ensureCSRFToken();
  return await resolve(
    axios.get(`${apiUrl}${path}`, {
      ...defaultOptions(),
      ...options,
    }),
    redirectOnAuthFailure
  );
}

export async function patch(path, data, options = {}) {
  await ensureCSRFToken();
  return await resolve(
    axios.patch(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
}

export async function post(path, data, options = {}) {
  await ensureCSRFToken();
  return await resolve(
    axios.post(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
}

export async function put(path, data, options = {}) {
  await ensureCSRFToken();
  return await resolve(
    axios.put(`${apiUrl}${path}`, data, {
      ...defaultOptions(),
      ...options,
    })
  );
}
// Delete is a reserved keyword
export async function deleteRequest(path, options = {}) {
  await ensureCSRFToken();
  return await resolve(
    axios.delete(`${apiUrl}${path}`, {
      ...defaultOptions(),
      ...options,
    })
  );
}
