import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Add } from "@mui/icons-material";

import useValidateAgentId from "../../hooks/useValidateAgentId";
import NavDropdown from "./Dropdown";
import Flex from "../../atoms/flex/Flex";
import Avatar from "../../atoms/agents/Avatar";
import { CharacterId } from "../../atoms/agents/types";
import { formatPhoneNumber } from "../../helpers";
import { ActiveUser, Agent, AgentStore, StoreTypes, UserStore } from "../../app/StoreTypes";
import Text from "../../atoms/typography/Text";
import ListGroup from "../../atoms/listGroup/ListGroup";
import ListItem from "../../atoms/listItems/ListItem";
import IconButton from "../../atoms/buttons/IconButton";
import { UsersLocationsUpgradeModal } from "../../components/Modal/UpgradeModal";
import Search from "../../atoms/inputs/Search";

import "./agentDropdown.scss";
import useAddLocationRedirect from "../../hooks/useAddLocationRedirect";

type ContainerChildrenComponentProps = {
  agentCharacterId: CharacterId;
  businessName: string;
  agentPhoneNumber: string | false | undefined;
};

export const ContainerChildrenComponent = ({
  agentCharacterId,
  businessName,
  agentPhoneNumber,
}: ContainerChildrenComponentProps) => (
  <Flex direction="column" gap="large">
    <Flex direction="row" justify="start" alignItems="center" gap="small">
      <Avatar characterId={agentCharacterId} size="xx-small" circle />
      <Flex direction="column" gap="none">
        <Flex>
          <Text weight="bold" type="lead" color="base-black" truncate>
            {businessName}
          </Text>
        </Flex>
        <Flex>
          <Text type="body-1" color="base-black" truncate>
            {agentPhoneNumber ? formatPhoneNumber(agentPhoneNumber) : "Get Goodcall #"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Flex>
);

const addButton = (handleAddNewLocation: () => void) => (
  <Flex align="center" alignItems="center" alignSelf="center" padding="none">
    <IconButton type="primary" size="small" circle onClick={handleAddNewLocation}>
      <Add />
    </IconButton>
  </Flex>
);

type DropdownChildrenComponentProps = {
  activeUser: ActiveUser;
  agents: Agent[];
  isSearchEnabled?: boolean;
  handleActiveAgentClick: (agentId: string) => void;
  handleAddNewLocation: () => void;
  hideAddButton?: boolean;
  searchBar?: React.ReactNode;
};

export const DropdownChildrenComponent = ({
  activeUser,
  agents,
  isSearchEnabled,
  hideAddButton,
  handleActiveAgentClick,
  handleAddNewLocation,
  searchBar,
}: DropdownChildrenComponentProps) => (
  <Flex padding="medium" gap="none">
    {searchBar && searchBar}
    {agents.length > 0 ? (
      <>
        <ListGroup>
          {agents.map((agent) => (
            <div key={agent.id} style={{ cursor: "pointer" }} onClick={() => handleActiveAgentClick(agent.id)}>
              <ListItem
                noMargin
                key={agent.id}
                firstLine={
                  <Text weight="bold" color="base-black" truncate>
                    {agent.businessName}
                  </Text>
                }
                padding="xsmall"
                secondLine={
                  <Text type="body-2" color="base-black" truncate>
                    {formatPhoneNumber(agent.phoneNumber)}
                  </Text>
                }
              />
            </div>
          ))}
        </ListGroup>
        {!activeUser.wixLegacyUser && !hideAddButton && <Flex padding="small">{addButton(handleAddNewLocation)}</Flex>}
      </>
    ) : (
      <Flex padding="small" direction="column" gap="small" alignItems="center" textAlign="center">
        {isSearchEnabled && (
          <>
            <div />
            <div />
            <img
              className="conversight no-results-img"
              src="/assets/media/images/search-no-results.svg"
              alt="No agents matching your search"
            />
            <Text weight="bold" type="lead" color="base-black">
              No agent found
            </Text>
            <Text type="body-1" color="true-black">
              We didn’t find that agent. Try searching with a different keyword.{" "}
            </Text>
            <div />
            <div />
            <div />
          </>
        )}
        {!activeUser.wixLegacyUser && addButton(handleAddNewLocation)}
      </Flex>
    )}
  </Flex>
);

export const AgentDropdown = () => {
  const { activeUser } = useSelector<StoreTypes>((state) => state.user) as UserStore;
  const { dashboardActiveAgent: activeAgent } = useValidateAgentId();
  const [dropdownActive, setDropdownActive] = useState(false);
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
  const addLocation = useAddLocationRedirect();

  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (value: string) => {
    setSearchQuery(value.trim());
  };

  const navigate = useNavigate();

  const handleActiveAgentClick = (agentId: string) => {
    if (activeAgent) {
      navigate(window.location.pathname.replace(activeAgent.id, agentId));
      setDropdownActive(false);
    }
  };

  const {
    agent: { availableAgents },
  } = useSelector((state) => state) as { agent: AgentStore };

  const [searchResults, setSearchResults] = useState<Agent[]>(
    availableAgents.filter((agent) => agent.id !== activeAgent?.id)
  );

  useEffect(() => {
    const filteredAgents = availableAgents.filter(
      (agent) => agent.businessName.toLowerCase().includes(searchQuery.toLowerCase()) && agent.id !== activeAgent?.id
    );
    setSearchResults(filteredAgents);
  }, [searchQuery, availableAgents, activeAgent?.id]);

  useEffect(() => {
    if (!dropdownActive) {
      setSearchQuery("");
    }
  }, [dropdownActive]);

  return (
    <>
      <UsersLocationsUpgradeModal isModalVisible={setIsUpgradeModalVisible} isVisible={isUpgradeModalVisible} />
      {activeAgent && activeUser ? (
        <NavDropdown
          setIsActive={setDropdownActive}
          isActive={dropdownActive}
          containerChildren={ContainerChildrenComponent({
            agentCharacterId: activeAgent.characterId,
            businessName: activeAgent.businessName,
            agentPhoneNumber: activeAgent.phoneNumber,
          })}
          dropdownChildren={DropdownChildrenComponent({
            activeUser,
            agents: searchResults,
            handleActiveAgentClick,
            handleAddNewLocation: addLocation,
            isSearchEnabled: availableAgents.length >= 5,
            searchBar:
              dropdownActive && availableAgents.length >= 5 ? (
                <Text type="body-1" weight="light">
                  <Search
                    placeholder="Search your agents"
                    type="rounded"
                    inputType="string"
                    onChange={handleInputChange}
                    canCollapse={false}
                    className="conversight agent-dropdown-search"
                  />
                </Text>
              ) : null,
          })}
        />
      ) : null}
    </>
  );
};

DropdownChildrenComponent.defaultProps = {
  hideAddButton: false,
  searchBar: undefined,
  isSearchEnabled: false,
};

export default AgentDropdown;
