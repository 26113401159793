import { useNavigate, useLocation, NavigateOptions } from "react-router-dom";

function useNavigateWithQuery(): (to: string | number, options?: NavigateOptions) => void {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithQuery = (to: string | number, options?: NavigateOptions) => {
    if (typeof to === "number") {
      navigate(to);
    } else {
      // Append current query string to the new path
      const queryString = location.search;
      // Check if the new path already has a query string
      if (to.includes("?")) {
        // If it does, remove the duplicate ? character from the query string
        const newQueryString = queryString.replace("?", "&");
        navigate(`${to}${newQueryString}`, options);
        return;
      }

      navigate(`${to}${queryString}`, options);
    }
  };

  return navigateWithQuery;
}

export default useNavigateWithQuery;
