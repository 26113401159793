/**
 * A type to describe a v4 UUID.
 * The type is a string with a special property (`__uuidv4`)
 * to prevent it from being assignable to a regular string.
 */
type UUIDv4 = string & { __uuidv4: never };

/** A regular expression to check if a string is a valid UUID v4. */
const uuidV4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 * A function to check if a value is a valid UUID v4.
 * @param value The value to check if it is a valid UUID v4.
 * @returns True if the value is a valid UUID v4, false otherwise.
 */
const isUUID = (value: unknown): value is UUIDv4 => {
  // If the value is not a string, it is not a valid UUID.
  if (typeof value !== "string") {
    return false;
  }

  // Test the string against the regular expression.
  return uuidV4Regex.test(value);
};

export default isUUID;
