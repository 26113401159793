// React Component that display that new version of UI is available and force the user to refresh the page

import React from "react";
import "./newVersionBanner.scss";
import Button from "../../atoms/buttons/Button";
import Text from "../../atoms/typography/Text";
import Frame from "../../atoms/frame/Frame";
import Heading from "../../atoms/typography/Heading";

const NewVersionBanner = (): JSX.Element => (
  <Frame shadow="medium">
    <div className="conversight new-version">
      <Heading size="5" weight="bold">
        New version available
      </Heading>
      <Text type="body-1" weight="regular">
        Please refresh your browser to get the latest updates
      </Text>
      <Button type="primary" size="small" onClick={() => window.location.reload()}>
        Refresh
      </Button>
    </div>
  </Frame>
);

export default NewVersionBanner;
