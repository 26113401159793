import React from "react";

import { Weight, Color, TextType } from "../types";

import "./text.scss";

const defaultProps = {
  type: "body-1" as TextType,
  weight: "regular" as Weight,
  uppercase: false,
  italic: false,
  color: "inherit",
  truncate: false,
  nowrap: false,
  fixedWidth: false,
};

export type Props = {
  /** The text to be displayed. */
  children: React.ReactNode;
  /** The type of text. */
  type?: TextType;
  /** The weight of the text. */
  weight?: Weight;
  /** Whether or not the text is transformed to upper case. */
  uppercase?: boolean;
  /** Whether or not the text is italicized. */
  italic?: boolean;
  /** Color of the text. */
  color?: Color;
  /** Whether or not the text is truncated. */
  truncate?: boolean;
  /** Whetner to add nowrap text wrap css */
  nowrap?: boolean;
  /** Whether or not the text should have a fixed width. */
  fixedWidth?: boolean;
} & typeof defaultProps;

const Text = ({
  children,
  type,
  weight,
  uppercase,
  italic,
  color,
  truncate,
  nowrap,
  fixedWidth,
}: Props): JSX.Element => {
  const classes = ["conversight", `${type}-${weight}`];

  if (color) {
    classes.push(`gc-color-${color}`);
  }

  if (italic) {
    classes.push("italic");
  }

  if (uppercase) {
    classes.push("uppercase");
  }

  if (nowrap) {
    classes.push("text-nowrap");
  }

  if (fixedWidth) {
    classes.push("text-fixed-width");
  }

  const className = classes.join(" ");

  return truncate ? (
    <div className="conversight text-truncate">
      <div className={className}>{children}</div>
    </div>
  ) : (
    <span className={className}>{children}</span>
  );
};

Text.defaultProps = defaultProps;

export default Text;
