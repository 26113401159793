export const ensureHttpsPrefix = (value: string): string => {
  if (value) {
    return value.startsWith("http://") || value.startsWith("https://") ? value : `https://${value}`;
  }
  return "";
};

export const appendToSearch = (value: Record<string, string>) => {
  const search = new URLSearchParams(window.location.search);

  // Append the value to the search query
  Object.keys(value).forEach((key) => {
    // If search query already has the key, remove it
    if (search.has(key)) {
      search.delete(key);
    }
    search.append(key, value[key]);
  });

  return search.toString();
};

export default ensureHttpsPrefix;
