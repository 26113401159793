import _ from "lodash";
import React, { createContext, useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIntercom } from "react-use-intercom";

import { setUser } from "../app/slices/userSlice";

import useLoggedInUser, { ActiveUserEnhanced } from "../hooks/useLoggedInUser";
import useIntercomUpdate from "../hooks/useIntercomUpdate";
import hotjar from "../helpers/hotjar";
import { AgentGuideProvider } from "../contexts/AgentGuide";
import AccessControlProvider from "./AccessControl";

type Props = {
  children: React.ReactNode;
};

// This context should *never* be used unless the component using it is
// rendered as a descendant of the <ProtectedRoute> component, which guarantees
// we have an active user for this context before rendering.
// This ignoring null assertion allows us to avoid type checking for a non-existant
// user every time we try to get a user, but can only be safely used in a ProtectedRoute.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const UserContext = createContext<ActiveUserEnhanced>(null!);

/**
 * This component requires that the user be logged in to render
 * the children components. If the user is not logged in,
 * it redirects them to the login page.
 */
const ProtectedRoute = ({ children }: Props) => {
  const dispatch = useDispatch();
  const intercom = useIntercom();

  // State for keeping track of our current user.
  const [currentUser, setCurrentUser] = useState<ReturnType<typeof useLoggedInUser>>("loading");

  // Get the logged in user, but only retry on failure if we already have an existing user.
  // This will prevent the user who is already logged in from being kicked out if there is
  // one minor hiccup, but send them to the login page right away if they aren't logged in yet.
  const loggedInUser = useLoggedInUser({ retry: currentUser !== "loading" });

  // Update intercom with the logged in user.
  useIntercomUpdate(loggedInUser);

  // Respond to changes to the logged in user.
  useEffect(() => {
    // If the logged in user is still loading, do nothing.
    if (loggedInUser === "loading") {
      return;
    }

    // If the logged in user is an error, update state and
    // set local storage showing the user as no longer logged in.
    if (_.isError(loggedInUser)) {
      setCurrentUser(loggedInUser);
      localStorage.setItem("loggedIn", "false");
      return;
    }

    // If the logged in user isn't the same as the current user,
    // go ahead and update everything based on the new user info.
    if (!_.isEqual(loggedInUser, currentUser)) {
      // Let hotjar know who this user is.
      hotjar.identify(loggedInUser.id);

      // Update the current user.
      setCurrentUser(loggedInUser);

      // Send the current user to redux.
      dispatch(setUser(loggedInUser));

      // Flag the user as logged in.
      localStorage.setItem("loggedIn", "true");
    }
  }, [loggedInUser, currentUser, dispatch]);

  // If the logged in user is still loading, show a spinner.
  if (currentUser === "loading") {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
          height: "100vh",
        }}
      >
        <Spinner animation="border" />
        {/* For react-snap to work, we need to have a link to the pages we wanted a snapshot of here. */}
        {navigator.userAgent === "ReactSnap" && (
          <>
            <Link hidden to="/login/">
              Login
            </Link>
            <Link hidden to="/dashboard/">
              Dashboard
            </Link>
          </>
        )}
      </div>
    );
  }

  // If there was an error getting the logged in user, redirect to login.
  if (_.isError(currentUser)) {
    // Shut down intercom and boot it back up to make sure we
    // don't leave any lingering data from the previous user.
    intercom.shutdown();
    intercom.boot();
    // Get the redirect path from the URL.
    const params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");
    // Navigate to the login page, passing the current path as a redirect.
    return <Navigate to={`/login/?redirect=${redirect || window.location.pathname}`} />;
  }

  // Otherwise, render the children like normal.
  return (
    <UserContext.Provider value={currentUser}>
      <AccessControlProvider user={currentUser}>
        <AgentGuideProvider>{children}</AgentGuideProvider>
      </AccessControlProvider>
    </UserContext.Provider>
  );
};

export default ProtectedRoute;
