import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ExitToApp } from "@mui/icons-material";
import Flex from "../../atoms/flex/Flex";
import AgentGuide from "../agentGuide/AgentGuide";
import { CharacterId } from "../../atoms/agents/types";
import Button from "../../atoms/buttons/Button";
import { ContainerChildrenComponent, DropdownChildrenComponent } from "../nav/AgentDropdown";
import { Agent, AgentStore, StoreTypes, UserStore } from "../../app/StoreTypes";
import ActionArea from "../../molecules/ActionArea";
import Dropdown from "../nav/Dropdown";
import UserRoles from "../../helpers/UserRoles";
import Text from "../../atoms/typography/Text";
import useResponsive from "../../hooks/useResponsive";

type Props = {
  selectedAgent: CharacterId;
};

const SelectAgent = ({ selectedAgent }: Props): JSX.Element => {
  // Use the useNavigate hook to navigate to the next page.
  const navigate = useNavigate();

  // Get the active user from the store using the useSelector hook.
  const { activeUser } = useSelector<StoreTypes>((state) => state.user) as UserStore;

  // Define loading state
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useResponsive("mobile");
  // Get the available agents from the store using the useSelector hook.
  const {
    agent: { availableAgents },
  } = useSelector((state) => state) as { agent: AgentStore };

  // Filter the available agents to only show the agents that the active user owns.
  const ownedAgents = availableAgents.filter((agent) => {
    const userRole = new UserRoles(activeUser || undefined, agent);
    return userRole.isOwner();
  });

  // Get the active agent from the store using the useSelector hook.
  const [chosenAgent, setChosenAgent] = useState<Agent | undefined>(ownedAgents[0]);

  // Expand the Dropdown on click
  const [dropdownActive, setDropdownActive] = useState(false);

  // Handle the connect to Zapier button click
  const handleConnectToZapier = () => {
    setIsLoading(true);

    // Redirect to the API URL with all the necessary parameters
    // to connect the agent to Zapier.
    const apiURL = process.env.REACT_APP_API_URL;
    if (!apiURL || !chosenAgent) {
      toast.error("Error connecting to Zapier. Please try again later.");
      setIsLoading(false);
      return;
    }

    const searchParams = window.location.search;
    const url = `${apiURL}/agents/${chosenAgent.id}/zapier/authorize${searchParams}`;

    window.location.href = url;
  };

  return (
    <AgentGuide
      characterId={selectedAgent}
      title="Select an agent to connect"
      backButton
      agentHint="Zapier is the quick, easy way to connect Goodcall with the tools you already use"
      size="medium"
      onBack={() => navigate("..")}
      customAction
    >
      <Flex>
        {isMobile && <div />}
        <ActionArea>
          <Flex>
            <Flex direction="row" align="center" gap="small">
              {activeUser && chosenAgent && (
                <Dropdown
                  containerChildren={ContainerChildrenComponent({
                    agentCharacterId: chosenAgent.characterId,
                    businessName: chosenAgent.businessName,
                    agentPhoneNumber: chosenAgent.phoneNumber || "No phone # ",
                  })}
                  dropdownChildren={DropdownChildrenComponent({
                    hideAddButton: true,
                    activeUser,
                    agents: ownedAgents.filter((agent) => agent.id !== chosenAgent.id),
                    handleActiveAgentClick: (agent) => {
                      setChosenAgent(availableAgents.find((a) => a.id === agent));
                    },
                    handleAddNewLocation() {},
                  })}
                  isActive={dropdownActive}
                  setIsActive={setDropdownActive}
                />
              )}

              {!ownedAgents.length && <Text type="lead">No agents available</Text>}
            </Flex>
          </Flex>

          {ownedAgents.length && (
            <Button type="secondary" size="large" loading={isLoading} onClick={handleConnectToZapier}>
              <ExitToApp /> Connect to Zapier
            </Button>
          )}
        </ActionArea>
      </Flex>
    </AgentGuide>
  );
};

export default SelectAgent;
