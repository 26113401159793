import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  BillingCycle,
  getPlanBenefitByName,
  getPlanBenefitByPriceId,
  getPlanNameAndCycleByPriceId,
  getPriceIdByPlanNameAndCycle,
  PlanName,
} from "../helpers/newPlanHelper";
import useUser from "./useUser";

export enum SubscribeFlowError {
  /* Trial started without a phone number */
  SUB_STARTED_WITHOUT_PHONE_NUMBER = "Subscription started without a phone number",
}

export enum SubscribeFlowSource {
  /* Add location in demo */
  DEMO_TRIAL_ADD_LOCATION = "demo-trial-add-location",
}

/*
 * Hook to mange various states in the subscribe flow.
 * We can extend this hook to manage more states in the future.
 */
const useSubscribeFlow = () => {
  // User price id
  const { priceId: userPriceId } = useUser();
  // Search parameters
  const [searchParams] = useSearchParams();

  const { agentId = "" } = useParams();

  // selected Plan
  const selectedPlanParam = searchParams.get("plan") as PlanName;

  // Get cycle from search params
  const cycleParam = searchParams.get("cycle") as BillingCycle;

  const navigate = useNavigate();
  const onClose = () => navigate(searchParams.get("returnTo") || `/dashboard/${agentId}`);

  // Get the selected phone number
  const selectedPhoneNumber = searchParams.get("selectedPhoneNumber") || "";

  // use userPriceId for plan benefit
  const currentPlanBenefit = getPlanBenefitByPriceId(userPriceId);
  const currentPlan = getPlanNameAndCycleByPriceId(userPriceId);

  const priceId = selectedPlanParam ? getPriceIdByPlanNameAndCycle(selectedPlanParam, cycleParam) : userPriceId;
  const benefit = selectedPlanParam ? getPlanBenefitByName(selectedPlanParam, cycleParam) : currentPlanBenefit;

  // Error that occurred
  const flowError = searchParams.get("error") as SubscribeFlowError;

  // Get the source from search params
  const source = searchParams.get("source") as SubscribeFlowSource;

  const isSubStartedWithoutPhoneNumber = flowError === SubscribeFlowError.SUB_STARTED_WITHOUT_PHONE_NUMBER;

  return {
    current: {
      priceId: userPriceId,
      planBenefit: currentPlanBenefit,
      billingCycle: currentPlan.billingCycle,
      planName: currentPlan.planName,
    },
    selected: {
      priceId,
      planBenefit: benefit,
      billingCycle: cycleParam || BillingCycle.MONTHLY,
      planName: selectedPlanParam || PlanName.GROWTH,
    },
    priceId,
    selectedPhoneNumber,
    agentId,
    isSubStartedWithoutPhoneNumber,
    source,
    onClose,
  };
};

export default useSubscribeFlow;
