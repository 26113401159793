import { ActiveUser } from "../app/StoreTypes";

// Helper that sends event to GTM dataLayer
type DataLayer = [Record<string, string | Record<string, string>>] | undefined;

const sendGTMEvent = (event: string, params: Record<string, string>, activeUser?: ActiveUser) => {
  const currentWindow = window as unknown as { dataLayer: DataLayer };

  // If GTM is active in the window.
  if (currentWindow.dataLayer) {
    // Grab the event paramaters.
    const eventParams = { ...params };

    // If we have an active user, attach their metadata.
    if (activeUser) {
      eventParams.email = activeUser.email;
      eventParams.firstname = activeUser.firstName;
      eventParams.lastname = activeUser.lastName;
      eventParams.phonenumber = activeUser.phoneNumber;
    }

    // Push the event and paramaters to GTM.
    currentWindow.dataLayer.push({
      event,
      ...eventParams,
    });
  }
};

export default sendGTMEvent;
