import React, { useState } from "react";
import { Modal as BModal } from "react-bootstrap";

import ModalBackButton from "./ModalBackButton";
import Title from "./Title";
import ModalCloseButton from "./ModalCloseButton";

import "./modal.scss";

// Modal size constants
export type Size = "x-small" | "small" | "medium" | "large" | "x-large";

// Default props for that button
const defaultProps = {
  closeButton: false,
  onClose: () => {},
  backButton: false,
  onBack: () => {},
  title: "",
  titleIcon: <> </>,
  size: "large" as Size,
  className: "",
  staticBackdrop: true,
  customSpacing: false,
  noMaxContentWidth: false,
};

export type Props = {
  children: React.ReactNode;
  closeButton?: boolean;
  onClose?: () => void;
  backButton?: boolean;
  onBack?: () => void;
  title?: string;
  titleIcon?: JSX.Element;
  className?: string;
  size?: Size;
  staticBackdrop?: boolean;
  customSpacing?: boolean;
  noMaxContentWidth?: boolean;
} & typeof defaultProps;

const Modal = ({
  children,
  closeButton,
  onClose,
  backButton,
  onBack,
  title,
  titleIcon,
  className,
  size,
  staticBackdrop,
  customSpacing,
  noMaxContentWidth,
}: Props): JSX.Element => {
  const [show, setShow] = useState(true);

  const customSpacingClass = customSpacing ? "custom-spacing" : "";
  const noMaxContentWidthClass = noMaxContentWidth ? "no-max-content-width" : "";

  return (
    <BModal
      centered
      show={show}
      onHide={() => setShow(false)}
      onExited={onClose}
      backdrop={staticBackdrop ? "static" : undefined}
      className={`conversight ${customSpacingClass} ${className} ${size} ${noMaxContentWidthClass}`}
    >
      {backButton && <ModalBackButton onClick={onBack} />}
      {title && (
        <Title size="4">
          {title}
          {titleIcon}
        </Title>
      )}
      {closeButton && <ModalCloseButton onClick={() => setShow(false)} />}
      {children}
    </BModal>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
