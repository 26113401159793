import React from "react";

import BackButton from "../buttons/BackButton";

export type Props = {
  onClick: () => void;
};

const ModalBackButton = ({ onClick }: Props): JSX.Element => (
  <div className="conversight modal-back-button">
    <BackButton onClick={onClick} />
  </div>
);

export default ModalBackButton;
