import { DirectoryContact, isFullDirectoryContact } from "../api/DirectoryApiNew";
import { BehaviorType } from "../api/types";

const contactHasBehavior = (contact: DirectoryContact, behaviorToCheck: BehaviorType) => {
  if (isFullDirectoryContact(contact)) {
    return contact.available.behaviorType === behaviorToCheck || contact.unavailable.behaviorType === behaviorToCheck;
  }
  return false;
};

const getAllContactsWithBehavior = (behaviorType: BehaviorType, contacts: DirectoryContact[] | undefined): string[] => {
  const connectedContacts = new Array<string>();
  if (contacts) {
    contacts.forEach((contact) => {
      if (contactHasBehavior(contact, behaviorType)) {
        connectedContacts.push(`${contact.firstName} ${contact.lastName} (${contact.title})`);
      }
    });
  }
  return connectedContacts;
};

export default getAllContactsWithBehavior;
