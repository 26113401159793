import { createSlice } from "@reduxjs/toolkit";

export const skillWizardSlice = createSlice({
  name: "skillWizard",
  initialState: {
    businessCategory: "",
    configuredSkillsQuestions: [],
    isCallDetected: false,
  },
  reducers: {
    setBusinessCategory: (state, { payload }) => {
      state.businessCategory = payload;
    },

    setConfiguredSkillsQuestions: (state, { payload }) => {
      if (!state.configuredSkillsQuestions) {
        state.configuredSkillsQuestions = [payload];
      } else if (!state.configuredSkillsQuestions.includes(payload)) {
        state.configuredSkillsQuestions.push(payload);
      }
    },
    setIsCallDetected: (state, { payload }) => {
      state.isCallDetected = payload;
    },
  },
});

export const { setBusinessCategory, setConfiguredSkillsQuestions, setIsCallDetected } = skillWizardSlice.actions;

export default skillWizardSlice.reducer;
