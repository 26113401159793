import React, { useState } from "react";
import Lottie from "lottie-react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import "./addNewLocation.scss";
import success from "../../lottie/success.json";

import Button from "../../atoms/buttons/Button";
import { formatErrorForDisplay, refreshAvailableAgents, refreshUserAccount } from "../../helpers";

const SuccessfullySubscribed = (): JSX.Element => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = async () => {
    setLoading(true);
    await Promise.all([refreshUserAccount(dispatch), refreshAvailableAgents(dispatch)]).catch((error) => {
      toast.error(formatErrorForDisplay(error));
    });
    setLoading(false);
  };

  return (
    <div className="add-new-location-summary">
      <div className="header">
        <Lottie animationData={success} alt="Agent is ready" className="lottie-gif" />
        <h5 className="h5-bold">Welcome to Goodcall!</h5>
        <p className="body-1-regular sub-header">
          You can now connect existing agents to real customers, or add locations.
        </p>
      </div>
      <Button loading={loading} type="primary" size="large" onClick={onClick}>
        Continue
      </Button>
    </div>
  );
};

export default SuccessfullySubscribed;
