import React from "react";
import "./lineItem.scss";

const defaultProps = {
  amount: "",
  strickOutAmount: "",
  negativeAmount: false,
  description: "",
  period: "",
  heading: false,
  total: false,
  name: "",
};

type Props = {
  item: string;
  amount?: string;
  strickOutAmount?: string;
  negativeAmount?: boolean;
  period?: string;
  description?: string;
  heading?: boolean;
  total?: boolean;
  name?: string;
} & typeof defaultProps;

const LineItem = ({
  amount,
  period,
  item,
  description,
  heading,
  strickOutAmount,
  total,
  negativeAmount,
  name,
}: Props): JSX.Element => (
  <div className={`conversight line-item body-1-regular ${total ? "total" : ""}`}>
    <div className={`item ${heading ? "lead-bold" : "lead-regular"}`}>
      {item}
      {description && <div className="description body-2-regular">{description}</div>}
    </div>

    {amount && (
      <div className="amount body-1-regular">
        {strickOutAmount && (
          <b>
            <s>${strickOutAmount}</s>
          </b>
        )}{" "}
        {negativeAmount && "-"}${amount}
        {period ? `/${period}` : ""}
      </div>
    )}
    {name && <div className="item">{name}</div>}
  </div>
);

LineItem.defaultProps = defaultProps;

export default LineItem;
