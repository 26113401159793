import React from "react";

import { DateTime } from "luxon";
import { Spinner } from "react-bootstrap";

import "./addNewLocation.scss";

import ListGroup from "../../atoms/listGroup/ListGroup";
import LineItem from "../../atoms/lineItems/LineItem";
import Button from "../../atoms/buttons/Button";
import formatAmount from "../../helpers/formatAmount";
import { useUpcomingInvoiceQuery } from "../../queries/user";
import Flex from "../../atoms/flex/Flex";
import { getPriceDetailsWithDiscount, isAnnualPlan, priceIds } from "../../helpers/planHelper";
import useUser from "../../hooks/useUser";
import Text from "../../atoms/typography/Text";

type Props = {
  onNext: () => void;
};

const SubscriptionChangeSummary = ({ onNext }: Props): JSX.Element => {
  const activeUser = useUser();

  const userId = activeUser ? activeUser.id : "";
  // Fetches the upcoming invoice details from the server.
  const { data, isLoading } = useUpcomingInvoiceQuery(userId, "add-new-location");

  const paymentDetails = data?.data;
  const amountDue = paymentDetails?.amountDue || 0;
  const pricePerLocation = paymentDetails?.pricePerLocation || 0;
  const numberOfLocations = paymentDetails?.numberOfLocations || 0;

  const isAnnul = isAnnualPlan(activeUser.priceId);
  const dateFormat = !isAnnul ? "LLL dd" : "LLL dd, yyyy";
  const prorationStartDate = DateTime.fromSeconds(paymentDetails?.prorationStartDate || 0).toFormat(dateFormat);
  const nextPaymentDate = DateTime.fromSeconds(paymentDetails?.nextPaymentDate || 0).toFormat(dateFormat);
  const paymentCycleUnit = isAnnul ? "yr" : "mo";
  const discount = paymentDetails?.coupon?.percent_off || 0;
  const priceDetails = getPriceDetailsWithDiscount(
    discount,
    isAnnul ? priceIds.premiumAnnualPriceId : priceIds.premiumPriceId
  );

  const pricePerLocationAfterDiscount = discount ? priceDetails.discountedPrice * 100 : pricePerLocation;
  return (
    <div className="add-new-location-summary">
      {isLoading ? (
        <Flex alignSelf="center" alignItems="center">
          <Spinner animation="border" />
        </Flex>
      ) : (
        <>
          <p className="body-1-regular">
            Each new location adds{" "}
            <Text weight="bold">
              ${formatAmount(pricePerLocation, 0)}/{paymentCycleUnit}{" "}
            </Text>
            {isAnnul ? (
              <>
                to your annual subscription after <Text weight="bold">your 30% discount</Text>
              </>
            ) : (
              " to your subscription."
            )}
          </p>
          <ListGroup>
            <LineItem
              heading
              item={`Starting ${nextPaymentDate}`}
              description={`you’ll pay ${formatAmount(
                pricePerLocationAfterDiscount
              )} /${paymentCycleUnit} x ${numberOfLocations} locations`}
              amount={formatAmount(pricePerLocationAfterDiscount * numberOfLocations)}
              strickOutAmount={isAnnul ? formatAmount(5900 * numberOfLocations * 12) : ""}
            />
            {!!discount && (
              <LineItem
                item="Discount applied"
                description="50% off for first 2 months"
                amount={`${priceDetails.discountedPriceString} /mo`}
                strickOutAmount={`${priceDetails.originalPrice.toString()} /mo`}
              />
            )}
            {amountDue !== 0.0 && (
              <LineItem
                heading
                item="Due today"
                description={`New location from ${prorationStartDate} - ${nextPaymentDate}`}
                amount={`${formatAmount(amountDue)}`}
              />
            )}
          </ListGroup>

          <Button type="primary" size="large" onClick={onNext}>
            I Understand
          </Button>
        </>
      )}
    </div>
  );
};

export default SubscriptionChangeSummary;
