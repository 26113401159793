import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCheckoutDeeplink } from "../../api/WixApi";
import { apiUrl } from "../../api/APIUtils";
import UserRoles from "../../helpers/UserRoles";
import useValidateAgentId from "../../hooks/useValidateAgentId";
import { WixFailedUpgradeModal } from "../Modal/WixFailedUpgradeModal";
import { AutoAwesome } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export const UpgradeButton = ({ position }) => {
  const { dashboardActiveAgent: agent, isDemoMode, agentPlan } = useValidateAgentId();
  const { activeUser } = useSelector((state) => state.user);
  const [showFailedUpgradeModal, setShowFailedUpgradeModal] = useState();

  const iswixLegacyUser = activeUser.wixLegacyUser;
  // navigate
  const navigate = useNavigate();

  // is the user on a solo plan?
  const isSoloPlan = !isDemoMode && agentPlan === "solo";

  const [portalLink, setPortalLink] = useState(
    // Default upgrade link to strip for non-wix users.
    !iswixLegacyUser && `${apiUrl}/external/stripe/portal`
  );
  const userRoles = new UserRoles(activeUser, agent);
  let upgradeText = "";

  let upgradeButtonClasses = "btn gc-cta-button gc-c1-red-background ";

  if (position === "modal" || position === "account") {
    upgradeButtonClasses += "text-nowrap d-flex p-1 pl-3 pr-3 font-weight-500";
    upgradeText = "Plan";
  } else if (position === "skill") {
    upgradeText = "Now";
  } else {
    upgradeButtonClasses += "text-nowrap d-flex p-1 pl-3 pr-3 font-weight-500";
  }

  async function getWixDeeplink(wixInstanceId) {
    return await getCheckoutDeeplink({
      wixInstanceId,
    });
  }

  useEffect(() => {
    if (iswixLegacyUser && activeUser.wixInstanceId !== undefined) {
      getWixDeeplink(activeUser.wixInstanceId).then((result) => {
        result?.data.checkoutUrl && setPortalLink(result?.data.checkoutUrl);
      });
    }
  }, [activeUser.wixInstanceId, iswixLegacyUser]);

  const upgradeButton = (
    <button type="submit" className={upgradeButtonClasses}>
      <AutoAwesome className="mr-2 font-size-20" />
      Upgrade {upgradeText}
    </button>
  );

  function handleFormSubmission(e) {
    if (isSoloPlan) {
      e.preventDefault();
      return navigate("/dashboard/knowledge/upgrade");
    }
    if (!portalLink && iswixLegacyUser) {
      setShowFailedUpgradeModal(true);
      e.preventDefault();
      return;
    }
    if (iswixLegacyUser && portalLink?.includes("wix.com")) {
      e.preventDefault();
      window.open(portalLink);
      return false;
    }
  }
  return (
    <>
      <WixFailedUpgradeModal isVisible={showFailedUpgradeModal} setIsViable={setShowFailedUpgradeModal} />
      {userRoles.isOwner() && activeUser?.planName != "premium" && (
        <form method="POST" onSubmit={handleFormSubmission} action={portalLink}>
          {upgradeButton}
        </form>
      )}
    </>
  );
};
