import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDetectCampaign = () => {
  // Get the current location.
  const location = useLocation();

  // Get the search parameters from the location.
  const query = new URLSearchParams(location.search);

  // Grab the utm campaign from query.
  const campaign = query.get("utm_campaign") || false;

  // Synchronize campaign to local storage if it changes.
  useEffect(() => {
    // If a campaign exists, store it.
    if (campaign) {
      localStorage.setItem("campaign", campaign);
    }
  }, [campaign]);
};

export default useDetectCampaign;
