import React, { useState } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  tooltip: React.ReactNode;
  children: React.ReactElement;
};

const WithTooltip = ({ tooltip, children }: Props) => {
  // If the OverlayTrigger has no defined show, it will show the
  // tooltip when the children are hovered over. But then when you
  // move your mouse outside of the trigger element, the tooltip hides.
  // That includes when you mouse over the tooltip element. By flipping
  // show to true when we mouseover the tooltip element, we can keep the
  // tooltip active if the user mouses over it. To reset the behavior,
  // we just set it back to undefined when the mouse leaves the tooltip.
  const [showTooltip, setShowTooltip] = useState<true | undefined>(undefined);

  const ref = React.createRef<HTMLDivElement>();
  const tooltipId = React.useId();

  // If there is no tooltip, just render without it.
  if (!tooltip) {
    return children;
  }

  // Render the overlay trigger, tooltip, and children.
  return (
    <OverlayTrigger
      // Use the show tooltip property, if set.
      show={showTooltip}
      overlay={
        <Tooltip
          hidden={false}
          style={{
            // Again, Tooltip is not showing probably on opened modal because z-index
            zIndex: 2147483005,
          }}
          // See the comment above for why these are true and undefined.
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(undefined)}
          id={tooltipId}
          ref={ref}
        >
          {tooltip}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default WithTooltip;
