import React from "react";

import "./buttonGroup.scss";

type Justify = "fill" | "right" | "between";

type Direction = "row" | "column" | "row-reverse" | "column-reverse";

const defaultProps = {
  justify: "fill" as Justify,
  direction: "default" as Direction,
};

type Props = {
  /** The buttons to display in the button group. */
  children: React.ReactNode;
  /** How the buttons are justified in the button group. */
  justify?: Justify;
  direction?: Direction;
} & typeof defaultProps;

const ButtonGroup = ({ children, justify, direction }: Props): JSX.Element => (
  <div className={`button-group button-group-${direction} justify-${justify}`}>{children}</div>
);

ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
