import React from "react";

import "./progressBar.scss";

const defaultProps = {
  abbreviated: false,
};

export type ProgressBarProps = {
  steps: number;
  currentStep: number;
  abbreviated?: boolean;
};

type Props = ProgressBarProps & typeof defaultProps;

const ProgressBar = ({ steps, currentStep, abbreviated }: Props) => {
  const percentage = steps > 0 ? (currentStep / steps) * 100 : 0;
  const isLastStep = currentStep === steps ? "last-step" : "";

  return (
    <div className="conversight conversight-progress-bar">
      <div
        className={`conversight-progress ${isLastStep}`}
        style={{
          width: `${percentage + (percentage > 0 && percentage < 100 ? 5 : 0)}%`,
        }}
      >
        {currentStep > 0 && (abbreviated ? `${currentStep}/${steps}` : `Step ${currentStep} of ${steps}`)}
      </div>
    </div>
  );
};

ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
